/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Profile = {
    address: {
        address: string;
        blockchain: Profile.blockchain;
    };
    banner: {
        url?: string;
    };
    description?: string;
    discord?: string;
    followers: number;
    following: number;
    icon: {
        url?: string;
    };
    linktree?: string;
    medium?: string;
    name?: string;
    telegram?: string;
    twitter?: string;
    website?: string;
};

export namespace Profile {

    export enum blockchain {
        ETHEREUM = 'ethereum',
        SEPOLIA = 'sepolia',
        GOERLI = 'goerli',
    }


}

