/* eslint-disable no-inner-declarations */
import { generatePath, Link } from 'react-router-dom'

import { PARAMS } from '@/routing/params'
import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'
import { ROUTES } from '@/routing/routes'
import {
  Collection, Nft,
} from '@/api/luckyswap'
import { CollectionModel, NftModel } from '@/api/stubs/models'

import { CollectionCard } from '../cards/CollectionCard'
import { NFTCard } from '../cards/NFTCard'
import style from './style.module.scss'

export enum CardType {
  nft = 'nft',
  collection = 'collection',
  user = 'user',
}

type CardsData =
// TODO explanation: This variety cuz of POOR backend spec
  | { type: CardType.nft, value: NftModel[] }
  | { type: CardType.collection, value: CollectionModel[] }
  | { type: CardType.user, value: void[] }
  | undefined

// TODO remap from CardsData later
type CardData =
  | { type: CardType.nft, value: NftModel }
  | { type: CardType.collection, value: CollectionModel }
  | { type: CardType.user, value: void }

export type CardsGridProps = {
  data: CardsData

  isHorizontalLayout?: boolean;
  onClickCard?: (card: CardData) => void
  userId?: string
}
& ClassProp

export function CardsGrid({
  data,
  onClickCard,
  className,
  isHorizontalLayout,
  userId,
}: CardsGridProps) {


  return (
    <div
      className={cn(
        'w-full container-type-inline-size',
        className,
      )}
    >
      <div
        className={cn(style.grid, isHorizontalLayout && style.horizontalGrid)}
      >
        {data?.value.map(item => {
          if (!item) return null

          if (data?.type === 'nft') {
            const nft = item as Nft

            const path = generatePath(ROUTES.nftPage, {
              [PARAMS.collectionId]: (item as Nft).collection.slug,
              [PARAMS.nftId]: `${(item as Nft).id}`,
            })

            function handleClick() {
              onClickCard?.({ type: CardType.nft, value: nft })
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'nft_card',
                category: 'page_view',
                path, // path to nft card
                collection: (item as Nft).collection.slug, // collection name
                nft: `${(item as Nft).id}`, // nft name
                id: userId, // UserId
                timestamp: new Date().getTime(),
              })
            }

            return (
              <Link
                key={item.name}
                to={path}
                onClick={handleClick}
              >
                <NFTCard className="h-full" data={nft} />
              </Link>
            )
          }

          if (data.type === 'collection') {
            const path = generatePath(ROUTES.collectionPage, {
              [PARAMS.collectionId]: (item as CollectionModel).slug,
            })

            return (
              <Link
                key={item.name}
                to={path}
                onClick={() => {
                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: 'nft_collection',
                    category: 'page_view',
                    path, // path to nft collection
                    collection: item.name, // collection name
                    id: userId, // UserId
                    timestamp: new Date().getTime(),
                  })
                }}
              >
                <CollectionCard isHorizontalLayout={isHorizontalLayout} data={item as Collection} />
              </Link>
            )
          }

          return null
        })}
      </div>
    </div>
  )
}
