import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'

import { Block } from '@/components/common/Block'
import { Modal } from '@/components/common/Modal'
import { ModalLayout, ModalLayoutProps } from '@/components/layout/ModalLayout'
import { ModalLayoutHeader } from '@/components/layout/ModalLayout/Header/ModalLayoutHeader'
import { ReactComponent as LabelSVG } from '@/assets/label_white.svg'
import { ReactComponent as GavelSVG } from '@/assets/gavel_white.svg'
import { useStores } from '@/hooks/useStores'
import { PARAMS } from '@/routing/params'
import { ROUTES } from '@/routing/routes'

import { SellType } from '../util'
import { SellTypeItem } from './SellTypeItem'


type Props = {
  onClick?: (type: SellType) => void
}
& Pick<ModalLayoutProps, 'onClose'>

export function ChooseSellTypeModal({
  onClick,
  onClose,
}: Props) {

  const {
    profileStore,
    nftPageStore,
  } = useStores()

  const intl = useIntl()

  return (
    <Modal>
      <ModalLayout onClose={onClose}>
        <Block lvl={1} className="w-[450px] max-w-[100vw]">
          <ModalLayoutHeader>
            {intl.formatMessage({
              id: 'ChooseSellTypeModal.Choose-type',
              defaultMessage: 'Choose a type',
            })}
          </ModalLayoutHeader>

          <div
            className="contents" onClick={() => {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'fixed_price_1',
                category: 'sale',
                nft: nftPageStore.$currentNft?.name, // name of nft
                collection: nftPageStore.$currentNft?.collection.name, // name of collection
                path: generatePath(ROUTES.nftPage, {
                  [PARAMS.collectionId]: nftPageStore.$currentNft?.collection.slug,
                  [PARAMS.nftId]: nftPageStore.$currentNft?.id,
                }), // path of nft
                id: profileStore.profileData.$value?.address.address, // UserId
                timestamp: new Date().getTime(),
              })
              onClick?.(SellType.fixedPrice)
            }}
          >
            <SellTypeItem
              title={intl.formatMessage({
                id: 'ChooseSellTypeModal.title.Fixed-Price',
                defaultMessage: 'Fixed Price',
              })}
              text={(
                <>
                  {intl.formatMessage({
                    id: 'ChooseSellTypeModal.text.Fixed-Price.1',
                    defaultMessage: 'Enter a price to allow users',
                  })}
                  <br />
                  {intl.formatMessage({
                    id: 'ChooseSellTypeModal.text.Fixed-Price.2',
                    defaultMessage: 'to purchase your NFT instantly.',
                  })}
                </>
              )}
              icon={<LabelSVG />}
            />
          </div>

          <div
            className="contents" onClick={() => {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'auction_1',
                category: 'sale',
                nft: nftPageStore.$currentNft?.name, // name of nft
                collection: nftPageStore.$currentNft?.collection.name, // name of collection
                path: generatePath(ROUTES.nftPage, {
                  [PARAMS.collectionId]: nftPageStore.$currentNft?.collection.slug,
                  [PARAMS.nftId]: nftPageStore.$currentNft?.id,
                }), // path of nft
                id: profileStore.profileData.$value?.address.address, // UserId
                timestamp: new Date().getTime(),
              })
              onClick?.('auction')
            }}
          >
            <SellTypeItem
              title={intl.formatMessage({
                id: 'ChooseSellTypeModal.title.Auction',
                defaultMessage: 'Auction',
              })}
              text={(
                <>
                  {intl.formatMessage({
                    id: 'ChooseSellTypeModal.text.Auction.1',
                    defaultMessage: 'Allow other users to make bids',
                  })}
                  <br />
                  {intl.formatMessage({
                    id: 'ChooseSellTypeModal.text.Auction.2',
                    defaultMessage: 'on your NFT.',
                  })}
                </>
              )}
              icon={<GavelSVG />}
            />
          </div>

        </Block>
      </ModalLayout>
    </Modal>
  )
}
