import {
  action, autorun, makeObservable, observable, reaction,
} from 'mobx'

import { Web3TxStatus } from '@/util/web3'
import { Nft } from '@/api/luckyswap'
import { CollectionPageStore } from '@/stores/CollectionPage.store'
import { DirectSellNftFlowStore } from '@/stores/flows/DirectSellNftFlow.store'
import { Price } from '@/api/stubs/money'

import { DirectBuyNftFlowStore } from './DirectBuyNftFlow.store'
import { NFTPageStore } from '../NFTPage.store'
import { PlaceBidFlowStore } from './PlaceBidFlow.store'
import { MarketplacePageStore } from '../MarketplacePage.store'
import { PutForAuctionFlowStore } from './PutForAuctionFlowStore'
import { MakeOfferFlowStore } from './MakeOfferFlow.store'
import { AcceptOfferFlowStore } from './AcceptOfferFlow.store'
import { DeleteOfferFlowStore } from './DeleteOfferFlow.store'

export class FlowsStore {
  @observable.ref public $directSellNftFlow: DirectSellNftFlowStore | null = null
  @observable.ref public $directBuyNftFlow: DirectBuyNftFlowStore | null = null
  @observable.ref public $placeBidFlow: PlaceBidFlowStore | null = null
  @observable.ref public $putForAuctionFlow: PutForAuctionFlowStore | null = null

  @observable.ref public $makeOfferFlow: MakeOfferFlowStore | null = null
  @observable.ref public $acceptOfferFlow: AcceptOfferFlowStore | null = null
  @observable.ref public $deleteOfferFlow: DeleteOfferFlowStore | null = null

  public currentProcessingNft!: Nft

  constructor(
    private readonly nftPage: NFTPageStore,
    private readonly marketplacePage: MarketplacePageStore,
    private readonly collectionPage: CollectionPageStore,
  ) {
    makeObservable(this)

    reaction(
      () => [
        this.$directSellNftFlow?.$txStatus,
        this.$directSellNftFlow?.$disposed,
        this.$directBuyNftFlow?.$txStatus,
        this.$placeBidFlow?.$txStatus,
        this.$putForAuctionFlow?.$txStatus,
        this.$makeOfferFlow?.$txStatus,
        this.$acceptOfferFlow?.$txStatus,
        this.$deleteOfferFlow?.$txStatus,
      ],
      async statuses => {
        if (statuses.includes(Web3TxStatus.completed)) {
          this.nftPage.nftData.load({ params: this.nftPage.nftData.$lastParams })
        }
      },
    )

    autorun(() => {
      if (
        this.$directSellNftFlow?.$disposed
        || this.$directBuyNftFlow?.$disposed
        || this.$placeBidFlow?.$disposed
        || this.$putForAuctionFlow?.$disposed
        || this.$makeOfferFlow?.$disposed
        || this.$acceptOfferFlow?.$disposed
        || this.$deleteOfferFlow?.$disposed
      ) {
        this.nftPage.nftData.load({ params: this.nftPage.nftData.$lastParams })
      }

      if (this.$directSellNftFlow?.$disposed) this.$directSellNftFlow = null
      if (this.$directBuyNftFlow?.$disposed) this.$directBuyNftFlow = null

      if (this.$placeBidFlow?.$disposed) this.$placeBidFlow = null
      if (this.$putForAuctionFlow?.$disposed) this.$putForAuctionFlow = null

      if (this.$makeOfferFlow?.$disposed) this.$makeOfferFlow = null
      if (this.$acceptOfferFlow?.$disposed) this.$acceptOfferFlow = null
      if (this.$deleteOfferFlow?.$disposed) this.$deleteOfferFlow = null
    })
  }

  @action.bound public startDeleteOfferFlow(selectedOffer: { price: Price, offerId: string }) {
    const nft = this.nftPage.nftData.$value
    if (!nft) return

    this.currentProcessingNft = nft
    this.$deleteOfferFlow = new DeleteOfferFlowStore(nft, selectedOffer)
  }

  @action.bound public startAcceptOfferFlow(selectedOffer: { price: Price, offerId: string }) {
    const nft = this.nftPage.nftData.$value
    if (!nft) return

    this.currentProcessingNft = nft
    this.$acceptOfferFlow = new AcceptOfferFlowStore(nft, selectedOffer)
  }

  @action.bound public startMakeOfferFlow() {
    const nft = this.nftPage.nftData.$value
    if (!nft) return

    this.currentProcessingNft = nft
    this.$makeOfferFlow = new MakeOfferFlowStore(nft)
  }

  @action.bound public startDirectSellNftFlow() {
    const nft = this.nftPage.nftData.$value
    if (!nft) return

    this.currentProcessingNft = nft
    this.$directSellNftFlow = new DirectSellNftFlowStore(nft)
  }

  @action.bound public startDirectBuyNftFlow(nft: Nft) {
    this.currentProcessingNft = nft
    this.$directBuyNftFlow = new DirectBuyNftFlowStore(
      nft,
      this.nftPage,
      this.marketplacePage,
      this.collectionPage,
    )
  }

  @action.bound public startPlaceBidFlow() {
    if (!this.nftPage.$currentNft) return

    this.currentProcessingNft = this.nftPage.$currentNft
    this.$placeBidFlow = new PlaceBidFlowStore(
      this.nftPage.$currentNft,
    )
  }

  @action.bound public startPutOnAuctionFlow() {
    if (!this.nftPage.$currentNft) return

    this.currentProcessingNft = this.nftPage.$currentNft
    this.$putForAuctionFlow = new PutForAuctionFlowStore(
      this.nftPage.$currentNft,
    )
  }
}
