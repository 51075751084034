import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'

import { useStores } from '@/hooks/useStores'
import { ModalLayoutProps } from '@/components/layout/ModalLayout'
import { EnterFixedPriceModal } from '@/components/modules/flows/SellNFTFlow/EnterFixedPriceModal'
import { DirectSellNftStage } from '@/stores/flows/DirectSellNftFlow.store'
import { ROUTES } from '@/routing/routes'
import { PARAMS } from '@/routing/params'
import { CurrencyAndTotal } from '@/api/stubs/money'
import { CurrenciesService } from '@/stores/Currencies.store'

import { FollowStepsModal } from '../../DirectBuyNftFlow/FollowStepsModal'
import { FollowStep } from '../../components/FollowStep'
import { FinalModal } from '../../DirectBuyNftFlow/FinalModal'


type Screen = 'checkout' | 'confirmation' | 'final'

type Props = Pick<ModalLayoutProps, 'onClose'>

const getStepStatus = (
  stage: DirectSellNftStage | undefined,
  targetStage: DirectSellNftStage | undefined,
  stepStatus: DirectSellNftStage[],
) => {
  if (stepStatus.some(value => value === targetStage)) return 'ok'
  if (stage === DirectSellNftStage.error) return 'error'
  return null
}

export const DirectSellFlow = observer(({ onClose }: Props) => {
  const intl = useIntl()

  const { flowsStore, profileStore } = useStores()
  const { defaultCurrency } = CurrenciesService
  const [price, setPrice] = useState<CurrencyAndTotal>({ ...defaultCurrency, total: '0' })
  const [screen, setScreen] = useState<Screen>('checkout')
  const [getCurrentStep, setStep] = useState<number>(0)
  const [stepStatus, setStepStatus] = useState<DirectSellNftStage[]>([])

  const flow = flowsStore.$directSellNftFlow
  const nft = flow?.nft
  const stage = flow?.$stage

  const handleClose = () => {
    onClose?.()
    flow?.dispose()
  }

  const handlePriceClose = (getPrice: CurrencyAndTotal) => {
    setPrice(getPrice)
    setStep(DirectSellNftStage.confirmation)
  }

  useEffect(() => {
    if (stage) setStepStatus([...stepStatus, stage])
  }, [stage])

  useEffect(() => {
    if (stage === DirectSellNftStage.checkout) setScreen('checkout')

    if (stage === DirectSellNftStage.confirmation) setScreen('confirmation')

    if (stage === DirectSellNftStage.confirmed) setTimeout(() => setScreen('final'), 800)

    if (stage === DirectSellNftStage.error) setScreen('confirmation')
  }, [stage])

  if (!nft) return null

  if (screen === 'checkout') {
    return <EnterFixedPriceModal onClose={handleClose} onConfirm={val => handlePriceClose(val)} />
  }

  if (screen === 'confirmation') {
    if (stage === DirectSellNftStage.confirmed && getCurrentStep === DirectSellNftStage.confirmation) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'fixed_price_3',
        category: 'sale',
        nft: nft.name, // name of nft
        collection: nft.collection.name, // name of collection
        path: generatePath(ROUTES.nftPage, {
          [PARAMS.collectionId]: nft.collection.slug,
          [PARAMS.nftId]: nft.id,
        }), // path of nft
        price, // price of sale
        id: profileStore.profileData.$value?.address.address, // UserId
        timestamp: new Date().getTime(),
      })
      setStep(DirectSellNftStage.confirmed)
    }

    return (
      <FollowStepsModal onClose={handleClose}>
        <FollowStep
          title={intl.formatMessage({
            id: 'PutForAuctionFlow.title.Creating-and-Checking',
            defaultMessage: 'Creating and Checking',
          })}
          text={intl.formatMessage({
            id: 'PutForAuctionFlow.text.Creating-and-Checking',
            defaultMessage: 'Confirm the transaction in your wallet. After confirmation, checking usually takes some time.',
          })}
          mode={getStepStatus(stage, DirectSellNftStage.approved, stepStatus)}
        />
        <FollowStep
          title={intl.formatMessage({
            id: 'PutForAuctionFlow.title.Creating-and-Checking',
            defaultMessage: 'Creating and Checking',
          })}
          text={intl.formatMessage({
            id: 'PutForAuctionFlow.text.Creating-and-Checking',
            defaultMessage: 'Confirm the transaction in your wallet. After confirmation, checking usually takes some time.',
          })}
          mode={getStepStatus(stage, DirectSellNftStage.confirmed, stepStatus)}
        />
      </FollowStepsModal>
    )
  }

  if (screen === 'final') {
    return (
      <FinalModal
        onClose={handleClose}
        flowStatus={flow.$txStatus}
        mode="placed-on-sell"
        txHash={flow.$txHash}
        nft={nft}
      />
    )
  }

  return null
})
