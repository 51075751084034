import { useMemo } from 'react'

import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'
import { CurrenciesService } from '@/stores/Currencies.store'
import { CurrencyAndTotal } from '@/api/stubs/money'
import { EMPTY_ADR_40 } from '@/util/web3'

import { Block } from '../Block'
import { Delim } from '../Delim'
import { Input } from '../Input'
import { Select, SelectItem } from '../Select/Select'

type CurrencyInputProps = {
  value: CurrencyAndTotal
  onChange: (money: CurrencyAndTotal) => void
  onlyErc20?: boolean
} & ClassProp

export function CurrencyInput({
  value, onChange, className, onlyErc20,
}: CurrencyInputProps) {
  const { currencies, getByAddress } = CurrenciesService

  const handleSelectCurrency = (id: string) => {
    const currency = getByAddress(id)
    if (currency) onChange({ ...currency, total: '' })
  }
  const handleInputAmount = (inputValue: string) => onChange({ ...value, total: inputValue.slice(0, value.decimals) })

  const currencyItems = useMemo<SelectItem[]>(
    () => currencies
      .filter(cur => (onlyErc20 ? cur.address !== EMPTY_ADR_40 : true))
      .map(cur => ({ id: cur.address, content: cur.symbol })),
    [currencies],
  )

  return (
    <Block
      size="xs"
      focusWithin
      className={cn('flex flex-row flex-nowrap items-center', className)}
    >
      <Input
        variant="flat"
        className="w-full ml-2"
        placeholder="0"
        onChangeText={handleInputAmount}
        value={value.total}
        type="number"
        min={0}
        step={0.05}
      />

      <Delim vertical className="shrink-0" />

      <Select
        items={currencyItems}
        selectId={onlyErc20 ? currencyItems[0].id : value.address}
        onSelect={handleSelectCurrency}
        className="!w-fit !bg-transparent min-h-fit justify-center"
      />
    </Block>
  )
}
