import { action, makeObservable, observable } from 'mobx'

import { EMPTY_ADR_40, Web3TxStatus } from '@/util/web3'
import { NftWeb3Service } from '@/api/web3/services/NftWeb3Service'
import { IDisposableMobx } from '@/interfaces/disposable-mobx.intf'
import { Nft } from '@/api/luckyswap'
import { CurrencyAndTotal } from '@/api/stubs/money'
import ERC20Abi from '@/abi/ERC20.json'
import { ENV } from '@/util/env'
import { ERC20Typo } from '@/abi/types'
import { useEvmWallet } from '@/stores/EvmWalletService'

export enum PlaceBidStage {
  placeBid,
  confirmation,
  confirmed,
  error,
}

export class PlaceBidFlowStore implements IDisposableMobx {
  @observable public $stage = PlaceBidStage.placeBid

  @observable public $txStatus: Web3TxStatus | null = null
  @observable public $txHash = ''

  @observable public $disposed = false

  constructor(
    public readonly nft: Nft,
  ) {
    makeObservable(this)
  }

  @action.bound public async placeBid(bid: CurrencyAndTotal) {
    try {
      this.$stage = PlaceBidStage.confirmation

      const { web3, address } = useEvmWallet()

      if (!web3) return

      const isErc20Token = bid.address !== EMPTY_ADR_40

      if (isErc20Token) {
        const erc20 = new web3.eth.Contract(
          ERC20Abi.abi as any,
          bid.address,
        ) as any as ERC20Typo

        await erc20.methods
          .approve(
            ENV.PROXY_CONTRACT_ADR,
            bid.total,
          )
          .send({ from: address })
      }


      const [prome] = await NftWeb3Service.makeBid(
        this.nft.collection.addresses[0].address,
        this.nft.id,
        bid.total,
        isErc20Token,
      )

      if (!prome) {
        this.$stage = PlaceBidStage.error
        return
      }

      prome
        .on('transactionHash', async hash => {
          this.$txHash = hash

          this.$txStatus = Web3TxStatus.processing

          this.$stage = PlaceBidStage.confirmed
        })

      await prome

      this.$txStatus = Web3TxStatus.completed
    }
    catch (err) {
      console.error(err)

      this.$stage = PlaceBidStage.error
      this.$txStatus = Web3TxStatus.error
    }
  }

  @action.bound public dispose(): void {
    this.$disposed = true
  }
}
