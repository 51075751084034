/* eslint-disable react/button-has-type */
import { PropsWithChildren, useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useIntl } from 'react-intl'

import { cn } from '@/util/exports'
import { ClassProp, DataProp } from '@/util/react-props'
import { useEvmWallet } from '@/stores/EvmWalletService'
import { useCurrentNetwork } from '@/hooks/useCurrentNetwork'

import style from './index.module.scss'

type Props = {
  wide?: boolean
  tall?: boolean
  disabled?: boolean
  isLoginButton?: boolean
  isWalletRequired?: boolean,
  nftBlockchains?: string

  variant?: 'regular' | 'flat' | 'border' | 'secondary',

  toggled?: boolean,
  onToggle?: (toggled: boolean)=>void
}
& ClassProp
& DataProp
& React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button = observer(({
  children,
  wide = false,
  tall = false,
  type = 'button',
  variant = 'regular',
  disabled = false,
  isLoginButton = false,
  isWalletRequired,
  nftBlockchains,

  toggled: toggledProp = false,
  onToggle,

  onClick,

  className,
  ...restProps
}: PropsWithChildren<Props>) => {
  const intl = useIntl()
  const { network, changeNetwork, isConnected } = useEvmWallet()
  const { isCurrentNetwork, newChainId } = useCurrentNetwork(nftBlockchains, network)
  const [toggled, setToggled] = useState(() => toggledProp)

  useEffect(() => setToggled(toggledProp), [toggledProp])

  useEffect(() => onToggle?.(toggled), [toggled, onToggle])

  function handleClick(e:React.MouseEvent<HTMLButtonElement>) {
    onClick?.(e)

    const newToggled = !toggled
    setToggled(newToggled)
    onToggle?.(newToggled)
  }

  const handleChangeNetwork = () => changeNetwork(newChainId)

  if (isWalletRequired && !isCurrentNetwork && isConnected) {
    return (
      <button
        {...restProps}
        onClick={handleChangeNetwork}
        type="button"
        className={cn(
          style.button,
          wide ? 'w-full' : 'w-fit',
          {
            'h-full': tall,
            [style.flat]: variant === 'flat',
            [style.border]: variant === 'border',
            [style.secondary]: variant === 'secondary',
          },
          className,
        )}
      >
        {intl.formatMessage({
          id: 'Buttons.Change-network',
          defaultMessage: 'Change network',
        })}
      </button>
    )
  }

  const renderChildren = () => (isWalletRequired && !isConnected && !isLoginButton
    ? (
      intl.formatMessage({
        id: 'ConnectWallet.Connect-Wallet',
        defaultMessage: 'Connect Wallet',
      })
    )
    : children)

  return (
    <button
      {...restProps}
      onClick={handleClick}
      disabled={(isWalletRequired && !isConnected && !isLoginButton) || disabled}
      type={type}
      className={cn(
        style.button,
        wide ? 'w-full' : 'w-fit',
        {
          'h-full': tall,
          [style.flat]: variant === 'flat',
          [style.border]: variant === 'border',
          [style.secondary]: variant === 'secondary',
        },
        className,
      )}
    >
      {renderChildren()}
    </button>
  )
})
