/* eslint-disable no-async-promise-executor */
import { PromiEvent } from 'web3-core'
import { action } from 'mobx'
import { AbiItem } from 'web3-utils'

import { Chain } from '@/util/chain'
import MarketplaceAbi from '@/abi/Marketplace.json'
import { BasicTokenTypo, MarketplaceTypo } from '@/abi/types'
import { Nft } from '@/api/luckyswap'
import { ENV } from '@/util/env'
import { delay } from '@/util/promise'
import { Price } from '@/api/stubs/money'
import BasicTokenAbi from '@/abi/BasicToken.json'

import { useEvmWallet } from '../EvmWalletService'
import { UniversalFlowStore } from '../util/UniversalFlow.store'

export class AcceptOfferFlowStore extends UniversalFlowStore<void> {
  constructor(
    public readonly nft: Nft,
    public readonly selectedOffer: { price: Price, offerId: string },
  ) {
    const { web3, address } = useEvmWallet()
    if (!web3) throw new Error('no web3 to instantiate flow')

    super(
      new Chain()

        .add(async () => {
          const basicTokenCtt = new web3.eth.Contract(
            BasicTokenAbi.abi as unknown as AbiItem,
            nft.collection.addresses[0].address,
          ) as unknown as BasicTokenTypo

          await basicTokenCtt.methods
            .approve(ENV.PROXY_CONTRACT_ADR, nft.id)
            .send({ from: address })
        })

        .add(() => {
          const market = new web3.eth.Contract(
            MarketplaceAbi.abi as any,
            ENV.MARKETPLACE_CONTRACT_ADR,
          ) as any as MarketplaceTypo

          const prome = market.methods
            .acceptOffer(selectedOffer.offerId)
            .send({ from: address })

          this.trackTx(prome)

          return new Promise<[PromiEvent<any>]>((res, rej) => {
            prome
              .once('transactionHash', () => res([prome]))
              .once('error', rej)
          })
        })

        .add(async ([prome]) => {
          await prome
        })

        .add(() => delay(800)),
    )
  }

  @action.bound public acceptOffer() {
    this.runSteps()
  }
}
