import { Block } from '@/components/common/Block'
import { ReactComponent as CheckSVG } from '@/assets/check_circle.svg'
import { ReactComponent as ProcessSVG } from '@/assets/processing.svg'
import { ReactComponent as ErrorSVG } from '@/assets/close.svg'
import { ClassProp } from '@/util/react-props'
import { cn } from '@/util/exports'
import { nil } from '@/util/nil'

export type StepMode = 'ok' | 'error'

type Props = {
  title: string
  text: string
  mode?: StepMode | nil
}
& ClassProp

export function FollowStep({
  title,
  text,
  mode,
  className,
}: Props) {

  function renderIcon() {
    if (mode === 'error') {
      return <ErrorSVG className="shrink-0 text-[color:var(--error)]" />
    }


    if (mode === 'ok') {
      return <CheckSVG className="shrink-0" />
    }

    return (
      <div className="animate-spin">
        <ProcessSVG className="shrink-0 w-10 h-10 -translate-y-[1.5px]" />
      </div>
    )
  }

  return (
    <Block
      size="s"
      className={cn(
        'flex flex-row flex-nowrap justify-between items-center gap-10',
        className,
      )}
    >
      <div>
        <div>{title}</div>

        <div
          className="text-[color:var(--text-secondary)]"
        >
          {text}
        </div>
      </div>

      {renderIcon()}
    </Block>
  )
}
