
import { action, makeObservable, observable } from 'mobx'
import BigNumber from 'bignumber.js'

import { Nft } from '@/api/luckyswap'
import { IDisposableMobx } from '@/interfaces/disposable-mobx.intf'
import { Web3TxStatus } from '@/util/web3'
import { CurrencyAndTotal } from '@/api/stubs/money'
import { useEvmWallet } from '@/stores/EvmWalletService'
import { NftWeb3Service } from '@/api/web3/services/NftWeb3Service'

type PutForAuctionStage =
  | 'configuration'
  | 'confirmation'
  | 'confirmed'
  | 'error'

export class PutForAuctionFlowStore implements IDisposableMobx {
  @observable public $stage: PutForAuctionStage = 'configuration'

  @observable public $txStatus: Web3TxStatus|null = null
  @observable public $txHash = ''
  @observable public $price = ''
  @observable public $exp = ''
  @observable public $min_step_bid: string|undefined = ''

  @observable public $disposed = false

  constructor(
    public readonly nft: Nft,
  ) {
    makeObservable(this)
  }

  @action.bound public setAnalyticsParams(
    price: CurrencyAndTotal,
    durationHours: string,
    minStep?: string,
  ) {
    this.$exp = durationHours
    this.$price = price.total
    this.$min_step_bid = minStep
  }

  @action.bound public async putForAuction(
    price: CurrencyAndTotal,
    durationSec: number,
    minStep?: string,
  ) {
    const { web3 } = useEvmWallet()
    if (!web3) return

    try {
      this.$stage = 'confirmation'
      price.total = new BigNumber(price.total).shiftedBy(price.decimals).toString()

      const [prome] = await NftWeb3Service.listItemOnAuction(
        this.nft.collection.addresses[0].address,
        this.nft.id,
        price,
        Math.round(Date.now() / 1000),
        durationSec,
        minStep,
      )

      if (!prome) {
        console.error('promi event lost :( ')
        this.$stage = 'error'
        return
      }

      prome
        .on('transactionHash', hash => {
          this.$txHash = hash
          this.$txStatus = Web3TxStatus.processing
          this.$stage = 'confirmed'
        })
        .on('error', () => {
          this.$txStatus = Web3TxStatus.error
        })

      await prome

      this.$txStatus = Web3TxStatus.completed
    }
    catch (err) {
      this.$stage = 'error'
      console.error(err)
    }
  }

  dispose(): void {
    this.$disposed = true
  }
}
