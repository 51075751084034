import {
  forwardRef, PropsWithChildren, useMemo, useState,
} from 'react'

import { ClassProp } from '@/util/react-props'
import { cn } from '@/util/exports'
import { useCtx } from '@/hooks/useContext'

import style from './style.module.scss'
import { BlockCtx } from './Block.ctx'

const COLORS_COUNT = 5
const GRADIENT_STEP = 1
const DEFAULT_COLOR = 1

type Props = {
  size?: 'b' | 'm' | 's' | 'xs'
  lvl?: 1|2|3|4|5
  focusWithin?: boolean
  hover?: boolean
  onClick?: () => void;
}
& ClassProp

export const Block = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  (
    {
      children,
      className,
      size = 'm',
      lvl,
      focusWithin,
      onClick,
      hover = false,
    },
    ref,
  ) => {
    const upperBlockCtx = useCtx(BlockCtx, true)

    const [isHovering, setIsHovering] = useState(false)

    const hoverGradientOffset = Number(isHovering && hover)

    const thisLvl = (
      lvl
      || Math.min(
        upperBlockCtx
          ? upperBlockCtx.lvl + GRADIENT_STEP
          : DEFAULT_COLOR,
        COLORS_COUNT,
      )
    )
    + hoverGradientOffset

    const thisCtx = useMemo(() => ({
      lvl: thisLvl,
    }), [thisLvl])


    let borderStyleClass = ''

    if (size === 'b') borderStyleClass = 'rounded-[8px] py-[29px] px-[26px]'

    if (size === 'm') borderStyleClass = 'rounded-[8px] py-[22px] px-[17px]'

    if (size === 's') borderStyleClass = 'rounded-[4px] py-[11px] px-[11px]'

    if (size === 'xs') borderStyleClass = 'rounded-[4px] py-[5px] px-[5px]'

    const lvlClass = style[`lvl${thisLvl}`]

    return (
      <BlockCtx.Provider value={thisCtx}>
        <div
          ref={ref}
          onClick={onClick}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          className={cn(
            borderStyleClass,
            style.block,
            focusWithin && style.focusWithin,
            lvlClass,
            className,
          )}
        >
          {children}
        </div>
      </BlockCtx.Provider>
    )
  },
)
