import { action, makeObservable, observable } from 'mobx'

import { EventService, EventStatus, Nft } from '@/api/luckyswap'
import { NftWeb3Service } from '@/api/web3/services/NftWeb3Service'
import { IDisposableMobx } from '@/interfaces/disposable-mobx.intf'
import { EMPTY_ADR_40, Web3TxStatus } from '@/util/web3'
import ERC20Abi from '@/abi/ERC20.json'
import { ENV } from '@/util/env'
import { ERC20Typo } from '@/abi/types'
import { useEvmWallet } from '@/stores/EvmWalletService'
import { CollectionPageStore } from '@/stores/CollectionPage.store'
import { MarketplacePageStore } from '@/stores/MarketplacePage.store'
import { NFTPageStore } from '@/stores/NFTPage.store'
import { delay } from '@/util/promise'

export enum DirectBuyNftStage {
  checkout,
  confirmation,
  confirmed,
  error,
}

export class DirectBuyNftFlowStore implements IDisposableMobx {

  @observable public $stage = DirectBuyNftStage.checkout

  @observable public $txStatus: Web3TxStatus | null = null
  @observable public $txHash = ''

  @observable public $disposed = false

  constructor(
    public readonly currentNft: Nft,
    public readonly nftPage: NFTPageStore,
    public readonly marketplacePage: MarketplacePageStore,
    public readonly collectionPage: CollectionPageStore,
  ) {
    makeObservable(this)
  }

  @action.bound public async confirm() {
    this.$stage = DirectBuyNftStage.confirmation
    const { id, collection, sell } = this.currentNft
    const { web3, address } = useEvmWallet()

    if (!sell?.price || !web3) {
      console.error('BuyNftFlowStore buyNFT: cant buy, price is nil')
      this.$stage = DirectBuyNftStage.error
      return
    }

    const isErc20Token = sell.price.address !== EMPTY_ADR_40

    try {
      if (isErc20Token) {
        const erc20 = new web3.eth.Contract(
          ERC20Abi.abi as any,
          sell.price.address,
        ) as any as ERC20Typo

        await erc20.methods
          .approve(
            ENV.PROXY_CONTRACT_ADR,
            sell?.price.total || '0',
          )
          .send({ from: address })
      }

      const [prome] = NftWeb3Service.buyItem(
        collection.addresses[0].address,
        id,
        isErc20Token ? undefined : sell?.price,
      )

      if (!prome) {
        console.error('BuyNftFlowStore buyNFT: cant buy, cuz prome is nil')
        this.$stage = DirectBuyNftStage.error
        return
      }

      this.$txStatus = Web3TxStatus.processing
      this.$stage = DirectBuyNftStage.confirmation
      await prome.once('transactionHash', this.transactionListener)
    }
    catch (err) {
      console.error('BuyNftFlowStore', err)
      this.$stage = DirectBuyNftStage.error
      this.$txStatus = Web3TxStatus.error
    }
  }

  @action.bound public dispose() {
    this.$disposed = true
    if (this.$stage === DirectBuyNftStage.checkout) return

    if (this.nftPage.$currentNft) this.nftPage.nftData.load()
    if (this.collectionPage.collectionNfts.$itemsTotal) this.collectionPage.collectionNfts.reload()
    if (this.marketplacePage.nfts.$itemsTotal) this.marketplacePage.nfts.reload()
  }

  @action.bound private async transactionListener(txHash: string) {
    if (!this.$txHash) this.$txHash = txHash
    const txStatus = await EventService.getEventStatus(txHash)

    if (txStatus === EventStatus.COMPLETED) {
      this.$txStatus = Web3TxStatus.completed
      this.$stage = DirectBuyNftStage.confirmed
    }
    else {
      await delay(1000)
      this.transactionListener(txHash)
    }
  }
}
