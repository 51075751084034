import { PropsWithChildren, useContext } from 'react'

import { cn } from '@/util/exports'

import { Block } from '../../Block'
import { TabsCtx } from '../Tabs.ctx'

type Props = {
  id: string
}

export function TabItem({
  id,
  children,
}: PropsWithChildren<Props>) {
  const ctx = useContext(TabsCtx)

  const isSelected = id === ctx.selectedId

  function handleTabClick() {
    if (id) {
      ctx.setTab(id)
    }
  }

  return (
    <div
      key={id}
      onClick={handleTabClick}
      className="contents"
      data-id={id}
    >
      <Block
        size="xs"
        className={cn(
          'cursor-pointer select-none',
          'w-full',
          'flex flex-row flex-nowrap justify-center',
          {
            '!bg-transparent': !isSelected,
            'text-[color:var(--text-secondary)]': !isSelected,
          },
        )}
      >
        {children}
      </Block>
    </div>
  )
}
