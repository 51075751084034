/* eslint-disable consistent-return */
import {
  PropsWithChildren, useEffect, useRef,
} from 'react'

import { ClassProp } from '@/util/react-props'
import { cn } from '@/util/exports'
import { useResizeObserverCB } from '@/hooks/useResizeObzerver'

const BLOCK_BOTTOM_OFFSET = 50
const BLOCK_TOP_OFFSET = 100

const POSITION_FIXED = 'fixed'
const UNSET = 'unset'

type State =
  | 'screenTop'
  | 'screenBottom'
  | 'containerTop'
  | 'containerBottom'
  | 'freeze'

type Props = {
}
& ClassProp

export function ViewportScroll({
  children,
  className,
}: PropsWithChildren<Props>) {
  const containerRef = useRef<HTMLDivElement>(null)
  const blockRef = useRef<HTMLDivElement>(null)

  useResizeObserverCB(blockRef.current, () => {
    const block = blockRef.current
    if (!block) return

    const containerWidth = containerRef.current?.getBoundingClientRect().width

    block.style.width = `${containerWidth}px`
  })

  useEffect(() => {
    let prevScroll = 0

    function handleScroll() {
      const scroll = document.documentElement.scrollTop
      const dScroll = scroll - prevScroll
      prevScroll = scroll

      const block = blockRef.current
      const container = containerRef.current
      if (!block) return
      if (!container) return

      const { clientHeight } = document.documentElement

      const blockRect = block.getBoundingClientRect()
      const containerRect = container.getBoundingClientRect()

      const isBlockShorterScreen = blockRect.height + BLOCK_BOTTOM_OFFSET + BLOCK_TOP_OFFSET < clientHeight

      let state: State = 'freeze'

      if (dScroll > 0 && blockRect.bottom + BLOCK_BOTTOM_OFFSET <= clientHeight) {
        state = 'screenBottom'

        if (isBlockShorterScreen) state = 'screenTop'
      }

      if (dScroll < 0 && blockRect.top - BLOCK_TOP_OFFSET >= 0) {
        state = 'screenTop'
      }

      if (dScroll < 0 && blockRect.top <= containerRect.top) {
        state = 'containerTop'
      }

      if (dScroll > 0 && blockRect.bottom >= containerRect.bottom) {
        state = 'containerBottom'
      }

      if (state === 'freeze') {
        const offset = blockRect.top - containerRect.top

        block.style.position = UNSET
        block.style.bottom = UNSET
        block.style.top = UNSET
        container.style.paddingTop = `${offset}px`
      }

      if (state === 'containerTop') {
        block.style.position = UNSET
        block.style.bottom = UNSET
        block.style.top = UNSET
        container.style.paddingTop = '0'
      }
      if (state === 'containerBottom') {
        const overflow = Math.max(0, blockRect.bottom - containerRect.bottom)
        const offset = blockRect.top - containerRect.top - overflow

        block.style.position = UNSET
        block.style.bottom = UNSET
        block.style.top = UNSET

        container.style.paddingTop = `${offset}px`
      }

      if (state === 'screenTop') {
        if (isBlockShorterScreen) {
          block.style.top = `${Math.max(container.offsetTop, BLOCK_TOP_OFFSET)}px`
        }
        else {
          block.style.top = `${BLOCK_TOP_OFFSET}px`
        }

        block.style.position = POSITION_FIXED
        block.style.bottom = UNSET
        container.style.paddingTop = '0'
      }
      if (state === 'screenBottom') {
        block.style.position = POSITION_FIXED
        block.style.bottom = `${BLOCK_BOTTOM_OFFSET}px`
        block.style.top = UNSET
        container.style.paddingTop = '0'
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div
      ref={containerRef}
      className={cn(
        'LayoutScroll-container',
        'h-full',
        className,
      )}
    >
      <div ref={blockRef} className="LayoutScroll-block w-inh">
        {children}
      </div>
    </div>
  )
}
