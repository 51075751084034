export enum Networks {
  ethereumMainnet = 'ethereum',
  sepolia = 'sepolia',
  goerli = 'goerli',
  // fantomOpera = 'fantom',
}

export type NetworkOption = {
  currencySymbol: string;
  value: string;
  label: string;
  shortLabel?: string;
  chainId: number;
  rpcUrl: string;
};

export const networkOptionsMap: Record<string, NetworkOption> = {
  1: {
    currencySymbol: 'ETH',
    value: Networks.ethereumMainnet as string,
    label: 'Ethereum',
    chainId: 1,
    rpcUrl: 'https://mainnet.infura.io/v3/1dbe511b72714b1fa1e9842838cbe276',
  },
  11155111: {
    currencySymbol: 'SepoliaETH',
    value: Networks.sepolia as string,
    label: 'Sepolia',
    chainId: 11155111,
    rpcUrl: 'https://rpc.sepolia.dev',
  },
  5: {
    currencySymbol: 'GoerliETH',
    value: Networks.goerli as string,
    label: 'Goerli',
    chainId: 5,
    rpcUrl: 'https://goerli.infura.io/v3/',
  },
  // 56: {
  //   currencySymbol: 'BNB',
  //   value: Networks.bsc as string,
  //   label: 'BNB Chain (Binance Smart Chain)',
  //   shortLabel: 'BNB Chain',
  //   chainId: 56,
  //   rpcUrl: 'https://bsc-dataseed.binance.org/',
  // },
  // 250: { value: Networks.fantomOpera as string, label: 'Fantom Opera', shortLabel: 'Fantom', chainId: 250 },
}

export const getNetworkOptions = (): NetworkOption[] => Object.values(networkOptionsMap).map(network => network)
