import { ArrayParam, StringParam } from 'use-query-params'

import { QUERIES } from '@/queries'

export const NFT_FILTER_QUERIES = {
  [QUERIES.blockchains]: ArrayParam,
  [QUERIES.saleType]: StringParam,
  [QUERIES.price_from]: StringParam,
  [QUERIES.price_to]: StringParam,
  [QUERIES.collection]: StringParam,
  [QUERIES.attributes]: ArrayParam,
}
