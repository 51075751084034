/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Nft = {
    address: {
        address: string;
        blockchain: Nft.blockchain;
    };
    artistRoyalties: number;
    attributes: Array<{
        floor?: {
            address: string;
            currency: string;
            total?: string;
        };
        format: 'textual' | 'numerical';
        percent?: number;
        total?: number;
        trait_object: {
            trait_type: string;
            trait_value?: string;
        };
    }>;
    auction?: {
        address: {
            address: string;
            blockchain: Nft.blockchain;
        };
        bids: Array<{
            created: string;
            maker: string;
            price: {
                address: string;
                currency: string;
                total?: string;
            };
        }>;
        bidsCount: number;
        created: string;
        endTime: string;
        minStep: string;
        price: {
            address: string;
            currency: string;
            total?: string;
        };
        startTime: string;
    };
    collection: {
        addresses: Array<{
            address: string;
            blockchain: 'ethereum' | 'sepolia' | 'goerli';
        }>;
        attributes: Array<{
            floor?: {
                address: string;
                currency: string;
                total?: string;
            };
            format: 'textual' | 'numerical';
            percent?: number;
            total?: number;
            trait_object: {
                trait_type: string;
                trait_value?: string;
            };
        }>;
        avgSale24: {
            address: string;
            currency: string;
            total?: string;
        };
        banner: {
            url?: string;
        };
        description?: string;
        discord?: string;
        floor: {
            address: string;
            currency: string;
            total?: string;
        };
        icon: {
            url?: string;
        };
        isVerified: boolean;
        items?: number;
        linktree?: string;
        listed?: number;
        medium?: string;
        name: string;
        owner: {
            address: {
                address: string;
                blockchain: Nft.blockchain;
            };
            banner: {
                url?: string;
            };
            description?: string;
            discord?: string;
            followers: number;
            following: number;
            icon: {
                url?: string;
            };
            linktree?: string;
            medium?: string;
            name?: string;
            telegram?: string;
            twitter?: string;
            website?: string;
        };
        ownersCount: number;
        preview: {
            url?: string;
        };
        slug: string;
        telegram?: string;
        totalSupply?: number;
        twitter?: string;
        volume: {
            address: string;
            currency: string;
            total?: string;
        };
        website?: string;
    };
    description?: string;
    id: string;
    image: {
        url?: string;
    };
    marketplaceRoyalties: number;
    metadata?: string;
    name: string;
    offers: Array<{
        address: {
            address: string;
            blockchain: 'ethereum' | 'sepolia' | 'goerli';
        };
        created: string;
        offerId: number;
        price: {
            address: string;
            currency: string;
            total?: string;
        };
    }>;
    owner: {
        address: {
            address: string;
            blockchain: Nft.blockchain;
        };
        banner: {
            url?: string;
        };
        description?: string;
        discord?: string;
        followers: number;
        following: number;
        icon: {
            url?: string;
        };
        linktree?: string;
        medium?: string;
        name?: string;
        telegram?: string;
        twitter?: string;
        website?: string;
    };
    preview: {
        url?: string;
    };
    rank: number;
    sell?: {
        address: {
            address: string;
            blockchain: Nft.blockchain;
        };
        created: string;
        price: {
            address: string;
            currency: string;
            total?: string;
        };
    };
    tokenStandard: string;
};

export namespace Nft {

    export enum blockchain {
        ETHEREUM = 'ethereum',
        SEPOLIA = 'sepolia',
        GOERLI = 'goerli',
    }


}

