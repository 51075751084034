
import { CollectionsPaginationResponse } from '@/api/luckyswap'
import { MediaPreview } from '@/components/common/MediaPreview'
import { Price } from '@/components/common/Price'
import { useIntl } from 'react-intl'

import style from './style.module.scss'

type Props = {
  data: CollectionsPaginationResponse['items'][0]
}

export function CollectionsFormItem({
  data,
}: Props) {
  const intl = useIntl();


  return (
    <div className="flex flex-row flex-nowrap gap-2 w-full overflow-hidden">
      <div className="w-[50px] h-[50px] shrink-0">
        <MediaPreview
          url={data.preview.url || ''}
        />
      </div>

      <div className="flex flex-col flex-nowrap justify-between overflow-hidden">

        <div className="flex flex-row flex-nowrap justify-between">
          <span className={style.name}>{data.name}</span>

          <span className="whitespace-nowrap">
            <Price
              fromUnit={{ address: data.avgSale24.address, total: data.avgSale24.total }}
              showSymbol
            />
          </span>
        </div>

        <div className="flex flex-row flex-nowrap justify-between">
          <span className={style.sub}>
            {`${intl.formatMessage({
                id: "CollectionsFormItem.Floor",
                defaultMessage: "Floor"
              })}:  `}
            <Price
              fromUnit={{ address: data.floor.address, total: data.floor.total }}
              showSymbol
            />
          </span>

          <span className={style.sub}>24h</span>
        </div>


      </div>
    </div>
  )
}
