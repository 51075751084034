import { useIntl } from 'react-intl'

import { ReactComponent as TelegramIcon } from '@/assets/telegram.svg'
import { ReactComponent as DiscordIcon } from '@/assets/discord.svg'
import { ReactComponent as TwitterIcon } from '@/assets/twitter.svg'
import { ExternalLink } from '@/components/common/ExternalLink'
import { SOCIAL_LINKS } from '@/constants'

export function SocialLinks() {
  const intl = useIntl()

  return (
    <div className="flex justify-between items-center mt-11">
      <span className="text-[color:var(--text-secondary)]">
        {intl.formatMessage({
          id: 'SignMessage.Stay-tuned',
          defaultMessage: 'Stay tuned',
        })}
      </span>
      <div className="flex gap-2">
        <ExternalLink href={SOCIAL_LINKS.telegram_channel}><TelegramIcon /></ExternalLink>
        <ExternalLink href={SOCIAL_LINKS.discord}><DiscordIcon /></ExternalLink>
        <ExternalLink href={SOCIAL_LINKS.twitter}><TwitterIcon /></ExternalLink>
      </div>
    </div>
  )
}
