import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { cn } from '@/util/exports'
import { ReactComponent as LogoSVG } from '@/assets/logo.svg'
import { ConnectWalletButton } from '@/components/modules/Header/components/ConnectWalletButton'
import { useStores } from '@/hooks/useStores'

import { Explore } from './Explore'
import style from './style.module.scss'

type HeaderProps = {
  onlyLogo?: boolean
}

export const Header = observer(({ onlyLogo }: HeaderProps) => {
  const { profileStore } = useStores()

  return (
    <div className="py-2 h-[72px] flex flex-row flex-nowrap items-center justify-between">
      <div className={cn(style.mobile, 'flex flex-row flex-nowrap items-center gap-7')}>

        <div
          className="ml-4 flex items-center" onClick={() => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'header_logo',
              category: 'page_view',
              id: profileStore.profileData.$value?.address.address ?? '', // UserId
              timestamp: new Date().getTime(),
            })
          }}
        >
          <Link to="/" className="flex">
            <LogoSVG />
            <span className={cn(style.name, 'ml-2 text-[16px] font-bold')}>LuckySwap</span>
          </Link>
        </div>

        <span className="flex flex-row flex-nowrap items-center gap-3">
          <span className={cn(style.connect, style.center)}>
            {!onlyLogo && <ConnectWalletButton />}
          </span>
          {!onlyLogo && <Explore id={profileStore.profileData.$value?.address.address} />}
        </span>
      </div>

      <span className={cn(style.connect, style.right)}>
        {!onlyLogo && <ConnectWalletButton />}
      </span>
    </div>
  )
})
