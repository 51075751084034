import { Link } from 'react-router-dom'

import { ROUTES } from '@/routing/routes'
import { Header } from '@/components/modules/Header'
import { SocialLinks } from '@/components/pages/AuthenticationPage/components/social-links'

import { ConnectWallet } from './components/connect-wallet'
import { SignMessage } from './components/sign-message'
import styles from './styles.module.scss'


export function AuthenticationPage() {
  return (
    <div className={styles.page}>
      <Header onlyLogo />
      <main className="flex flex-wrap justify-center pt-20 mr-16 -ml-16">
        <Link
          to={ROUTES.marketplacePage}
          className="flex items-center gap-2 h-fit font-['JetBrains_Mono'] br-3 mr-14"
        >
          <span className="text-[32px]">&#8592;</span>
          {' '}
          LuckySwap
        </Link>
        <div className={styles.content}>
          <ConnectWallet />
          <SignMessage />
          <SocialLinks />
        </div>
      </main>
    </div>
  )
}
