import { PropsWithChildren } from 'react'

import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'

import style from './index.module.scss'


type Props = {
  loading?: boolean
}
&ClassProp

export function LoadingBox({
  children,
  loading = false,
  className,
}: PropsWithChildren<Props>) {

  return (
    <div
      className={cn(
        'relative',
        style.box,
        className,
        {
          [style.loader]: loading,
        },
      )}
    >
      {children}
    </div>
  )
}
