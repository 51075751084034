import { PropsWithChildren } from 'react'

import { ClassProp } from '@/util/react-props'
import { cn } from '@/util/exports'

import style from './index.module.scss'

export function PageLayoutBody({ children, className }: PropsWithChildren<ClassProp>) {
  return (
    <div className={cn(style.body, className)}>
      {children}
    </div>
  )
}
