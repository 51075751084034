import { useEffect, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'

import { Nft } from '@/api/luckyswap'
import { Block } from '@/components/common/Block'
import { Input } from '@/components/common/Input'
import { Modal } from '@/components/common/Modal'
import { ModalLayout, ModalLayoutProps } from '@/components/layout/ModalLayout'
import { ModalLayoutHeader } from '@/components/layout/ModalLayout/Header/ModalLayoutHeader'
import { Button } from '@/components/common/Button'
import { useStores } from '@/hooks/useStores'
import { CurrenciesService } from '@/stores/Currencies.store'
import { readableCurrency } from '@/util/convert'
import { CurrencyAndTotal } from '@/api/stubs/money'
import { PARAMS } from '@/routing/params'
import { ROUTES } from '@/routing/routes'


function validatePrice(price: string, minBid: string, decimals: number) {
  const minimalAmount = new BigNumber(minBid)
  return new BigNumber(price).shiftedBy(decimals).isGreaterThanOrEqualTo(minimalAmount)
}

type Props = { nft: Nft } & Pick<ModalLayoutProps, 'onClose'>

export function PlaceBidModal({ nft, onClose }: Props) {
  const intl = useIntl()

  const { flowsStore, profileStore } = useStores()
  const { $placeBidFlow } = flowsStore
  const { getByAddress, defaultCurrency } = CurrenciesService
  const { auction } = nft

  const [price, setPrice] = useState<CurrencyAndTotal>({ ...defaultCurrency, total: '' })
  const [isValid, setValid] = useState(false)

  const {
    minBid, bidStep, minBidAmount, tokenDecimals, tokenAddress, tokenSymbol, tokenBlockchain,
  } = useMemo(() => {
    const tokenDetails = getByAddress(auction?.price.address || '')
    if (!auction?.price.total || !tokenDetails) {
      return {
        minBid: '0',
        bidStep: '0',
        minBidAmount: '0',
        tokenSymbol: '',
        tokenAddress: '',
        tokenDecimals: 0,
        tokenBlockchain: '',
      }
    }
    let minAmount = auction.price.total
    if (auction.bidsCount) {
      const highestAmount = auction.bids.slice().sort()[0].price.total || '0'
      minAmount = new BigNumber(highestAmount).plus(auction.minStep).toString()
    }
    return {
      minBidAmount: minAmount,
      minBid: readableCurrency({
        amount: minAmount,
        decimals: tokenDetails.decimals,
      }),
      bidStep: readableCurrency({
        amount: auction.minStep,
        decimals: tokenDetails.decimals,
      }),
      tokenSymbol: tokenDetails.symbol,
      tokenAddress: tokenDetails.address,
      tokenDecimals: tokenDetails.decimals,
      tokenBlockchain: tokenDetails.blockchain,
    }
  }, [price])

  useEffect(() => {
    setPrice({
      address: tokenAddress,
      symbol: tokenSymbol,
      decimals: tokenDecimals,
      total: '',
      blockchain: tokenBlockchain,
    })
  }, [tokenDecimals])

  useEffect(
    () => setValid(validatePrice(price.total, minBidAmount, tokenDecimals)),
    [price, minBid, bidStep],
  )

  const handlePlaceBid = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'step_2',
      category: 'auction_bid',
      nft: nft.name, // name of nft
      collection: nft.collection.name, // name of collection
      path: generatePath(ROUTES.nftPage, {
        [PARAMS.collectionId]: nft.collection.slug,
        [PARAMS.nftId]: nft.id,
      }), // path of nft
      bid: price.total, // value of bid
      id: profileStore.profileData.$value?.address.address, // UserId
      timestamp: new Date().getTime(),
    })
    $placeBidFlow?.placeBid({
      ...price,
      total: new BigNumber(price.total).shiftedBy(price.decimals).toString(),
    }).then(() => {
      if ($placeBidFlow?.$txStatus === 'tx-completed') {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'step_3',
          category: 'auction_bid',
          nft: nft.name, // name of nft
          collection: nft.collection.name, // name of collection
          path: generatePath(ROUTES.nftPage, {
            [PARAMS.collectionId]: nft.collection.slug,
            [PARAMS.nftId]: nft.id,
          }), // path of nft
          bid: price.total, // value of bid
          id: profileStore.profileData.$value?.address.address, // UserId
          timestamp: new Date().getTime(),
        })
      }
    })
  }
  if (!auction) return null

  return (
    <Modal>
      <ModalLayout onClose={onClose}>
        <Block
          lvl={1}
          className="max-w-md"
        >
          <ModalLayoutHeader>
            {intl.formatMessage({
              id: 'PlaceBidModal.Place-bid',
              defaultMessage: 'Place a bid',
            })}
          </ModalLayoutHeader>

          <div className="mb-6 text-[color:var(--text-secondary)]">
            {intl.formatMessage({
              id: 'PlaceBidModal.Place-bid-message',
              defaultMessage: 'You are about to place a bid for',
            })}
            {' '}
            <span className="text-[color:var(--active)]">
              {`${nft.name} ("${nft.collection.name}" ${intl.formatMessage({
                id: 'Common.collection',
                defaultMessage: 'collection',
              })})`}
            </span>
          </div>

          <div className="mb-2">
            <span>
              {intl.formatMessage({
                id: 'PlaceBidModal.Your-bid',
                defaultMessage: 'Your bid',
              })}
            </span>
            <span className="float-right text-[color:var(--text-secondary)]">
              {`${intl.formatMessage({
                id: 'PlaceBidModal.Bid-step',
                defaultMessage: 'Bid step',
              })} - ${bidStep} ${tokenSymbol}`}
            </span>
          </div>

          <Input
            wide
            type="number"
            placeholder={`${intl.formatMessage({
              id: 'PlaceBidModal.min-bid',
              defaultMessage: 'Min bid is',
            })} ${minBid} ${tokenSymbol}`}
            containerClassName="mb-3"
            min={minBid}
            value={price.total}
            onChangeText={value => setPrice({ ...price, total: value.slice(0, tokenDecimals) })}
          />

          <div className="break-words">
            {`${intl.formatMessage({
              id: 'PlaceBidModal.the-min-bid',
              defaultMessage: 'The min bid is',
            })} ${minBid} ${tokenSymbol}`}
          </div>
          <div
            className="text-[color:var(--text-secondary)] mb-4"
          >
            {intl.formatMessage({
              id: 'PlaceBidModal.min-bid-alert',
              defaultMessage: 'Your bid must be higher than the min bid',
            })}
          </div>

          <div className="flex flex-col flex-nowrap gap-4 mb-8">
            {/* <FlowModalRow */}
            {/*  title="Balance" */}
            {/*  price={{ currency: 'eth', total: wallet.balance, decimals: tokenDecimals }} */}
            {/* /> */}

            {/* <FlowModalRow */}
            {/*  title="Service fee 0%" */}
            {/*  price={{ currency: tokenSymbol, total: '0', decimals: tokenDecimals }} */}
            {/* /> */}

            {/* <FlowModalRow */}
            {/*  title="You will pay" */}
            {/*  price={{ currency: tokenSymbol, total: price, decimals: tokenDecimals }} */}
            {/* /> */}
          </div>

          <Button wide onClick={handlePlaceBid} disabled={!isValid}>
            {isValid ? `${intl.formatMessage({
              id: 'PlaceBidModal.place-a',
              defaultMessage: 'Place a',
            })} ${price.total} ${tokenSymbol} ${intl.formatMessage({
              id: 'PlaceBidModal.bid',
              defaultMessage: 'bid',
            })}` : intl.formatMessage({
              id: 'PlaceBidModal.Incorrect-price',
              defaultMessage: 'Incorrect price',
            })}
          </Button>

        </Block>
      </ModalLayout>
    </Modal>
  )
}
