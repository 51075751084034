import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'
import { DateTime, Duration } from 'luxon'
import BigNumber from 'bignumber.js'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'

import { Modal } from '@/components/common/Modal'
import { ModalLayout, ModalLayoutProps } from '@/components/layout/ModalLayout'
import { ModalLayoutHeader } from '@/components/layout/ModalLayout/Header/ModalLayoutHeader'
import { useStores } from '@/hooks/useStores'
import { Block } from '@/components/common/Block'
import { CurrencyInput } from '@/components/common/CurrencyInput'
import { CurrencyAndTotal } from '@/api/stubs/money'
import { Select, SelectItem } from '@/components/common/Select/Select'
import { Checkbox } from '@/components/common/Checkbox'
import { Button } from '@/components/common/Button'
import { Input } from '@/components/common/Input'
import { CurrenciesService } from '@/stores/Currencies.store'
import { PARAMS } from '@/routing/params'
import { ROUTES } from '@/routing/routes'


type Props = Pick<ModalLayoutProps, 'onClose'>


const isValidPrice = (amount: string) => !new BigNumber(amount).isNaN() && !new BigNumber(amount).isZero()

export const ConfigAuctionModal = observer(({ onClose }: Props) => {
  const intl = useIntl()

  const EXPIRE_DATES: SelectItem[] = [
    {
      id: Duration.fromObject({ hours: 1 }).toISO(),
      content: `1 ${intl.formatMessage({
        id: 'Common.hour',
        defaultMessage: 'hour',
      })}`,
    },
    {
      id: Duration.fromObject({ hours: 3 }).toISO(),
      content: `3 ${intl.formatMessage({
        id: 'Common.hours',
        defaultMessage: 'hours',
      })}`,
    },
    {
      id: Duration.fromObject({ hours: 12 }).toISO(),
      content: `12 ${intl.formatMessage({
        id: 'Common.hours',
        defaultMessage: 'hours',
      })}`,
    },
    {
      id: Duration.fromObject({ days: 1 }).toISO(),
      content: `1 ${intl.formatMessage({
        id: 'Common.day',
        defaultMessage: 'day',
      })}`,
    },
    {
      id: Duration.fromObject({ days: 3 }).toISO(),
      content: `3 ${intl.formatMessage({
        id: 'Common.days',
        defaultMessage: 'days',
      })}`,
    },
    {
      id: Duration.fromObject({ days: 5 }).toISO(),
      content: `5 ${intl.formatMessage({
        id: 'Common.days',
        defaultMessage: 'days',
      })}`,
    },
    {
      id: Duration.fromObject({ days: 7 }).toISO(),
      content: `7 ${intl.formatMessage({
        id: 'Common.days',
        defaultMessage: 'days',
      })}`,
    },
    {
      id: Duration.fromObject({ days: 14 }).toISO(),
      content: `14 ${intl.formatMessage({
        id: 'Common.days',
        defaultMessage: 'days',
      })}`,
    },
    {
      id: Duration.fromObject({ months: 1 }).toISO(),
      content: `1 ${intl.formatMessage({
        id: 'Common.month',
        defaultMessage: 'month',
      })}`,
    },
  ]

  const { flowsStore, nftPageStore: { $currentNft }, profileStore } = useStores()

  const { $putForAuctionFlow: flow } = flowsStore
  const { defaultCurrency } = CurrenciesService

  const [price, setPrice] = useState<CurrencyAndTotal>({ ...defaultCurrency, total: '0' })
  const [duration, setDuration] = useState<string>(EXPIRE_DATES[0].id)

  const [showMinStep, setShowMinStep] = useState(false)
  const [minStep, setMinStep] = useState<string>()

  const endDate = useMemo(
    () => DateTime
      .now()
      .plus(Duration.fromISO(duration))
      .toFormat('d LLL, hh:mm a'),
    [duration],
  )

  if (!flow) return null

  const { nft } = flow

  const handleClose = () => {
    onClose?.()
    flow?.dispose()
  }

  function handlePut() {
    if (!price) return

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'auction_2',
      category: 'sale',
      nft: $currentNft?.name, // name of nft
      collection: $currentNft?.collection.name, // name of collection
      path: generatePath(ROUTES.nftPage, {
        [PARAMS.collectionId]: $currentNft?.collection.slug,
        [PARAMS.nftId]: $currentNft?.id,
      }), // path of nft
      price, // first price of auction
      exp: Duration.fromISO(duration).toFormat('h'), // expiration time in hours
      id: profileStore.profileData.$value?.address.address, // UserId
      min_bid: minStep, // min id in auction
      timestamp: new Date().getTime(),
    })
    flow?.setAnalyticsParams(price, Duration.fromISO(duration).toFormat('h'), minStep)
    flow?.putForAuction(
      price,
      Duration.fromISO(duration).toMillis() / 1000,
      showMinStep && minStep
        ? new BigNumber(minStep).shiftedBy(price.decimals || 0).toString()
        : undefined,
    )
  }

  return (
    <Modal>
      <ModalLayout onClose={handleClose}>
        <Block
          lvl={1}
          className="w-[450px] max-w-[100vw] text-base"
        >
          <ModalLayoutHeader>
            {intl.formatMessage({
              id: 'ConfigAuctionModal.Put-auction',
              defaultMessage: 'Put for auction',
            })}
          </ModalLayoutHeader>


          <div className="mb-6 text-[color:var(--text-secondary)]">
            {intl.formatMessage({
              id: 'ConfigAuctionModal.Put-auction-message',
              defaultMessage: 'You are about to put for auction',
            })}
            {' '}
            <span className="text-[color:var(--active)]">
              {`${nft.name} ("${nft.collection.name}" ${intl.formatMessage({
                id: 'Common.collection',
                defaultMessage: 'collection',
              })})`}
            </span>
          </div>

          <div>
            {intl.formatMessage({
              id: 'ConfigAuctionModal.Item-price',
              defaultMessage: 'Item`s price',
            })}
          </div>
          <div className="text-[color:var(--text-secondary)] mb-3">
            {intl.formatMessage({
              id: 'ConfigAuctionModal.alert',
              defaultMessage: 'Bids below this amount won’t be allowed. You will only be able to accept the most recent high bid.',
            })}
          </div>
          <div className="mb-6">
            <CurrencyInput value={price} onChange={setPrice} />
          </div>

          <div>
            {intl.formatMessage({
              id: 'ConfigAuctionModal.Expiration-date',
              defaultMessage: 'Expiration date',
            })}
          </div>
          <div className="text-[color:var(--text-secondary)] mb-3">
            {intl.formatMessage({
              id: 'ConfigAuctionModal.Until',
              defaultMessage: 'Until',
            })}
            {' '}
            {endDate}
            .
            {intl.formatMessage({
              id: 'ConfigAuctionModal.auction-will-end-auto',
              defaultMessage: 'The auction will end automatically, and the highest bidder will win',
            })}
          </div>
          <Select
            items={EXPIRE_DATES}
            selectId={duration}
            onSelect={setDuration}
            className="!w-full mb-6"
          />

          <Checkbox
            className="flex-row-reverse justify-between"
            checked={showMinStep}
            onChecked={setShowMinStep}
          >
            {intl.formatMessage({
              id: 'ConfigAuctionModal.Min-step',
              defaultMessage: 'Min. step',
            })}
          </Checkbox>
          <div className="text-[color:var(--text-secondary)] mb-3">
            {intl.formatMessage({
              id: 'ConfigAuctionModal.Min-step-alert',
              defaultMessage: 'Each new bid must be higher than the previous one by this amount of token',
            })}
          </div>
          {showMinStep && (
            <Input
              value={minStep}
              onChangeText={value => setMinStep(value.slice(0, price.decimals))}
              containerClassName="mb-6"
              wide
              type="number"
              min={0}
            />
          )}

          <div className="flex flex-col flex-nowrap gap-4 mb-8">
            {/* <FlowModalRow title="Creator Royalties" price={price} /> */}
            {/* <FlowModalRow title="Service fee 0%" price={price} /> */}
            {/* <FlowModalRow title="You receive" price={price} /> */}
          </div>

          <Button
            wide
            onClick={handlePut}
            disabled={
              !isValidPrice(price.total)
              || (showMinStep && !isValidPrice(minStep || '0'))
            }
          >
            {intl.formatMessage({
              id: 'ConfigAuctionModal.Put-for-auction',
              defaultMessage: 'Put for auction',
            })}
          </Button>

        </Block>
      </ModalLayout>
    </Modal>
  )
})
