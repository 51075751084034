import BigNumber from 'bignumber.js'

import { Nft } from '@/api/luckyswap'
import { Price } from '@/api/stubs/money'

import { NotNil } from './nil'

export function calcMinBid(auction: NotNil<Nft['auction']>) {
  const minBid: Price = auction.bidsCount === 0
    ? auction.price
    : {
      ...auction.price,
      total: new BigNumber(auction.price.total || '')
        .plus(auction.minStep)
        .toString(),
    }

  return minBid
}

export function calcBidStep(auction: NotNil<Nft['auction']>) {
  const bidStep: Price = {
    address: auction.price.address,
    currency: auction.price.currency,
    total: auction.minStep,
  }

  return bidStep
}
