
import { PageLayout } from '@/components/layout/PageLayout'
import { Footer } from '@/components/modules/Footer'
import { Header } from '@/components/modules/Header'

import 'swiper/swiper-bundle.css'

import { PromoNftBlock } from './PromoNftBlock'
import { PromoCollectionsBlock } from './PromoCollectionsBlock'
import { PromoCreateCollectionBlock } from './PromoCreateCollectionBlock'

export function OverviewPage() {
  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Body>
          <Header />
        </PageLayout.Body>
      </PageLayout.Header>

      <PageLayout.Body>
        <PromoNftBlock />
        <PromoCollectionsBlock />
        <PromoCreateCollectionBlock />
      </PageLayout.Body>

      <PageLayout.Footer>
        <Footer />
      </PageLayout.Footer>
    </PageLayout>
  )
}
