/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CollectionFilter = {
    name?: string;
    skip: number;
    slug?: string;
    sortBy?: {
        createdAt?: CollectionFilter.createdAt;
    };
    take: number;
};

export namespace CollectionFilter {

    export enum createdAt {
        ASC = 'asc',
        DESC = 'desc',
    }


}

