import copy from 'copy-to-clipboard'

import { Block } from '@/components/common/Block'
import { Avatar } from '@/components/common/Avatar'
import { ellipsis } from '@/util/ellipsis'
import { ReactComponent as Copy } from '@/assets/copy.svg'
import { Profile } from '@/api/luckyswap'

type UserBlockProps = {
  profile?: Profile
  userId?: string
}

export function UserBlock({ profile, userId }: UserBlockProps) {
  const user = {
    avatar: profile?.icon.url,
    address: profile?.address?.address || userId,
  }

  return (
    <Block size="b" className="w-inh flex items-center mb-6 -mt-12">
      <Avatar className="!w-[56px] min-h-[56px] mr-2" isRounded={false} avatarImage={user.avatar} />
      <div>
        <span
          className='text-lg font-["JetBrains_Mono"] tracking-tight'
        >
          {ellipsis(user.address ?? '', 7, 4)}
        </span>
        <div className="flex items-center mt-2 cursor-pointer" onClick={() => copy(user.address!)}>
          <Copy />
          <span className="text-sm text-[color:var(--text-secondary)] hover:text-[color:var(--active)] transition-colors">
            {ellipsis(user.address ?? '', 7, 4)}
          </span>
        </div>
      </div> 
    </Block>
  )
}
