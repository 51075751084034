import { observer } from 'mobx-react-lite'
import { generatePath, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { Modal } from '@/components/common/Modal'
import { ModalLayout, ModalLayoutProps } from '@/components/layout/ModalLayout'
import { Block } from '@/components/common/Block'
import { RatioBox } from '@/components/common/RatioBox'
import { MediaPreview } from '@/components/common/MediaPreview'
import { Button } from '@/components/common/Button'
import { cn } from '@/util/exports'
import { STUB } from '@/util/stub'
import { isNil, nil } from '@/util/nil'
import { ROUTES } from '@/routing/routes'
import { QUERIES } from '@/queries'
import { MARKETPLACE_TABS } from '@/routing/tabs'
import { ModalLayoutHeader } from '@/components/layout/ModalLayout/Header/ModalLayoutHeader'
import { Web3TxStatus } from '@/util/web3'
import { Nft } from '@/api/luckyswap'
import { PARAMS } from '@/routing/params'


type Mode =
  | 'bought-nft'
  | 'placed-bid'
  | 'placed-on-auction'
  | 'placed-on-sell'
  | 'made-offer'
  | 'accepted-offer'
  | 'deleted-offer'

type Props = {
  mode: Mode

  flowStatus: Web3TxStatus | nil
  nft: Nft
  txHash: string
}
& Pick<ModalLayoutProps, 'onClose'>


function getStatusTitle(flowStatus: Web3TxStatus) {

  const intl = useIntl()

  if (flowStatus === Web3TxStatus.processing) {
    return (
      intl.formatMessage({
        id: 'Common.Processing',
        defaultMessage: 'Processing',
      }))
  }
  if (flowStatus === Web3TxStatus.completed) {
    return (
      intl.formatMessage({
        id: 'Common.Completed',
        defaultMessage: 'Completed',
      }))
  }
  if (flowStatus === Web3TxStatus.error) {
    return (
      intl.formatMessage({
        id: 'Common.Canceled',
        defaultMessage: 'Canceled',
      }))
  }
  return ''
}

export const FinalModal = observer(
  ({
    mode,
    flowStatus,
    nft,
    txHash,
    onClose,
  }: Props) => {

    const intl = useIntl()

    let title = ''
    let description: JSX.Element | string = ''
    let routeTo = ''

    if (mode === 'bought-nft') {
      title = intl.formatMessage({
        id: 'FinalModal.bought-nft',
        defaultMessage: 'You`ve successfully bought an NFT!',
      })
      description = (
        <>
          {intl.formatMessage({
            id: 'FinalModal.nft.message1',
            defaultMessage: 'The NFT will be displayed in your profile,',
          })}
          <br />
          {intl.formatMessage({
            id: 'FinalModal.nft.message2',
            defaultMessage: 'and other users will be able to see that you are its owner.',
          })}
          <br />
          {intl.formatMessage({
            id: 'FinalModal.nft.message3',
            defaultMessage: 'Congratulations on your purchase!',
          })}
        </>
      )
      routeTo = generatePath(ROUTES.nftPage, { [PARAMS.collectionId]: nft.collection.slug, [PARAMS.nftId]: nft.id })
    }
    if (mode === 'placed-bid') {
      title = intl.formatMessage({
        id: 'FinalModal.successfull-bid',
        defaultMessage: 'You`ve successfully placed a bid!',
      })
      description = ''
    }
    if (mode === 'placed-on-sell') {
      title = intl.formatMessage({
        id: 'FinalModal.successfull-placed-on-sell',
        defaultMessage: 'You’ve successfully put an NFT for sell!',
      })
      description = ''
    }
    if (mode === 'placed-on-auction') {
      title = intl.formatMessage({
        id: 'FinalModal.successfull-placed-on-auction',
        defaultMessage: 'You’ve successfully put an NFT for auction!',
      })
      description = ''
    }
    if (mode === 'made-offer') {
      title = intl.formatMessage({
        id: 'FinalModal.successfull-made-offer',
        defaultMessage: 'You’ve successfully made an offer!',
      })
      description = ''
    }
    if (mode === 'accepted-offer') {
      title = intl.formatMessage({
        id: 'FinalModal.successfull-accepted-offer',
        defaultMessage: 'You’ve successfully accepted the offer!',
      })
      description = ''
    }
    if (mode === 'deleted-offer') {
      title = intl.formatMessage({
        id: 'FinalModal.You-canceled-the-offer!',
        defaultMessage: 'You canceled the offer!',
      })
      description = intl.formatMessage({
        id: 'FinalModal.canceled-offer-description',
        defaultMessage: 'Your offer has been cancelled, it will not be displayed to the NFT owner.',
      })
    }

    let statusColor = ''
    if (flowStatus === Web3TxStatus.processing) statusColor = 'var(--pending)'
    if (flowStatus === Web3TxStatus.error) statusColor = 'var(--error)'
    if (flowStatus === Web3TxStatus.completed) statusColor = 'var(--success)'

    return (
      <Modal>
        <ModalLayout onClose={onClose}>
          <Block className="max-w-md ">
            <RatioBox
              mode="h-by-w"
              ratio={1}
              className="w-full mb-4"
            >
              <MediaPreview url={nft.preview.url || ''} />
            </RatioBox>

            <ModalLayoutHeader>
              {title}
            </ModalLayoutHeader>

            <div className="mb-4">
              {description}
            </div>

            <Block size="s" className="mb-4">
              <div className={cn(
                'grid grid-cols-[1fr_2fr] grid-rows-2',
                'text-base',
              )}
              >
                <span>
                  {intl.formatMessage({
                    id: 'Common.Status',
                    defaultMessage: 'Status',
                  })}
                </span>
                <span>
                  {intl.formatMessage({
                    id: 'Common.Transaction-hash',
                    defaultMessage: 'Transaction hash',
                  })}
                </span>
                <span style={{ color: statusColor }}>
                  {isNil(flowStatus) ? (
                    STUB
                  ) : (
                    getStatusTitle(flowStatus) || STUB
                  )}
                </span>
                <span className="overflow-hidden whitespace-nowrap text-ellipsis mr-4">
                  {txHash || STUB}
                </span>
              </div>
            </Block>

            {mode === 'bought-nft' && (
              <>

                <Link to={routeTo} className="mb-4">
                  <Button wide onClick={onClose} disabled={flowStatus !== Web3TxStatus.completed}>
                    {intl.formatMessage({
                      id: 'Common.View-NFT',
                      defaultMessage: 'View NFT',
                    })}
                  </Button>
                </Link>

                <Link
                  to={`${ROUTES.marketplacePage}?${QUERIES.tab}=${MARKETPLACE_TABS.nfts}`}
                >
                  <Button wide variant="flat" onClick={onClose}>
                    {intl.formatMessage({
                      id: 'Common.Buy-Another-NFT',
                      defaultMessage: 'Buy Another NFT',
                    })}
                  </Button>
                </Link>
              </>
            )}

            {(
              mode === 'placed-bid'
              || mode === 'placed-on-auction'
              || mode === 'placed-on-sell'
              || mode === 'made-offer'
            ) && (
              <Button wide onClick={onClose}>
                {intl.formatMessage({
                  id: 'Common.Close',
                  defaultMessage: 'Close',
                })}
              </Button>
            )}

          </Block>
        </ModalLayout>
      </Modal>
    )

  },
)
