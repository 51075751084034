import { generatePath, Link } from 'react-router-dom'
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useIntl } from 'react-intl'

import { Block } from '@/components/common/Block'
import { MediaPreview } from '@/components/common/MediaPreview'
import { ReactComponent as VerifiedSVG } from '@/assets/verified.svg'
import { ReactComponent as ShareSVG } from '@/assets/share.svg'
import { Button } from '@/components/common/Button'
import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'
import { ROUTES } from '@/routing/routes'
import { PARAMS } from '@/routing/params'
import { STUB } from '@/util/stub'
import { useStores } from '@/hooks/useStores'
import { ellipsis } from '@/util/ellipsis'
import { Avatar } from '@/components/common/Avatar'

import { ShareModal } from './ShareModal'
import style from './style.module.scss'
import { Content } from './Content'
import { Buttons } from './Buttons'

export const Card = observer(({ className }: ClassProp) => {
  const intl = useIntl()
  const [showShare, setShowShare] = useState(false)

  const {
    nftPageStore: {
      $currentNft,
      $ownerAddress,
    },
    profileStore,
  } = useStores()

  return (
    <Block size="b" className={className}>
      <div className="flex flex-row flex-nowrap items-center justify-between mb-3">
        <div className="flex flex-row flex-nowrap gap-2 items-center">
          <div
            className="w-6 h-6 rounded-sm overflow-hidden shrink-0"
          >
            <MediaPreview
              url={$currentNft?.collection?.preview?.url || ''}
            />
          </div>

          <span>{$currentNft?.collection.name || STUB}</span>

          {$currentNft?.collection.isVerified && (
            <VerifiedSVG className="shrink-0" />
          )}
        </div>

        <Button
          variant="flat" className="whitespace-nowrap flex items-center hover:!bg-[#2C3F51]"
          onClick={() => setShowShare(true)}
        >
          <ShareSVG className="inline-block mr-2" />
          <span className="text-[16px] text-[color:white]">
            {intl.formatMessage({
              id: 'Content.Share',
              defaultMessage: 'Share',
            })}
          </span>
        </Button>
      </div>

      <div className={cn(
        'font-["JetBrains_Mono"]',
        'flex flex-row flex-nowrap items-center gap-4',
        'mb-3',
      )}
      >
        <span className="text-[32px]">{$currentNft?.name || STUB}</span>

        {!!$currentNft?.rank && (
          <Block size="xs" className="inline text-[16px]">{`Rank: ${$currentNft.rank}`}</Block>
        )}
      </div>

      {!!$currentNft?.description && (
        <div className="text-[color:var(--text-secondary)]  mb-3">
          {$currentNft?.description}
        </div>
      )}

      <Content />

      {!!$currentNft && (
        <ShareModal
          nftData={$currentNft}
          showModal={showShare}
          onClose={() => setShowShare(false)}
        />
      )}

      <Buttons />

      <Link
        to={generatePath(ROUTES.userMarketPage, {
          [PARAMS.userId]: $currentNft?.owner?.address?.address || '<todo not found>',
        })}
        className="flex flex-row flex-nowrap gap-2.5 items-center mt-6"
        onClick={() => {
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: 'owner_profile',
            category: 'page_view',
            profile: $currentNft?.owner?.address?.address, // external user profile id
            id: profileStore.profileData.$value?.address.address, // UserId
            timestamp: new Date().getTime(),
          })
        }}
      >
        <div className="w-11 h-11 rounded overflow-hidden shrink-0">
          <Avatar avatarImage={$currentNft?.owner?.icon.url} />
        </div>

        <div className="shrink-0 flex flex-col justify-between gap-y-2.5">
          <div className="text-[color:var(--text-secondary)] text-base leading-none">
            {intl.formatMessage({
              id: 'Content.Current-owner',
              defaultMessage: 'Current owner',
            })}
          </div>

          <div className="leading-none">
            {$currentNft?.owner?.name || ellipsis($ownerAddress || STUB, 5, 4)}
          </div>
        </div>

        <div className={`${style.arrow} ml-auto`} />
      </Link>


    </Block>
  )
})
