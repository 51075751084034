import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'

import { useStores } from '@/hooks/useStores'
import { nil } from '@/util/nil'
import { ModalLayoutProps } from '@/components/layout/ModalLayout'
import { PARAMS } from '@/routing/params'
import { ROUTES } from '@/routing/routes'

import { ConfigAuctionModal } from './ConfigAuctionModal'
import { FollowStepsModal } from '../../DirectBuyNftFlow/FollowStepsModal'
import { FollowStep, StepMode } from '../../components/FollowStep'
import { FinalModal } from '../../DirectBuyNftFlow/FinalModal'


type Screen = 'config' | 'confirmation' | 'final'

type Props = {}
& Pick<ModalLayoutProps, 'onClose'>

export const PutForAuctionFlow = observer(
  ({
    onClose,
  }: Props) => {
    const intl = useIntl()

    const { flowsStore, profileStore } = useStores()
    const [screen, setScreen] = useState<Screen>('config')

    const flow = flowsStore.$putForAuctionFlow
    const nft = flow?.nft
    const stage = flow?.$stage

    useEffect(() => {
      if (stage === 'configuration') setScreen('config')

      if (stage === 'confirmation') setScreen('confirmation')

      if (stage === 'confirmed') {
        setScreen('confirmation')

        setTimeout(() => setScreen('final'), 800)
      }

      if (stage === 'error') setScreen('confirmation')

    }, [stage])

    if (!nft) return null

    if (screen === 'config') {
      return (
        <ConfigAuctionModal
          onClose={onClose}
        />
      )
    }

    if (screen === 'confirmation') {
      let mode: StepMode | nil

      if (stage === 'confirmed') mode = 'ok'
      if (stage === 'error') mode = 'error'

      return (
        <FollowStepsModal
          onClose={onClose}
        >
          <FollowStep
            title={intl.formatMessage({
              id: 'PutForAuctionFlow.title.Creating-and-Checking',
              defaultMessage: 'Creating and Checking',
            })}
            text={intl.formatMessage({
              id: 'PutForAuctionFlow.text.Creating-and-Checking',
              defaultMessage: 'Confirm the transaction in your wallet. After confirmation, checking usually takes some time.',
            })}
            mode={mode}
          />
        </FollowStepsModal>
      )
    }

    if (screen === 'final') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'auction_3',
        category: 'sale',
        nft: flow.nft.name, // name of nft
        collection: flow.nft.collection.name, // name of collection
        path: generatePath(ROUTES.nftPage, {
          [PARAMS.collectionId]: flow.nft.collection.slug,
          [PARAMS.nftId]: flow.nft.id,
        }), // path of nft
        price: flow.$price, // first price of auction
        exp: flow.$exp, // expiration time in hours
        min_bid: flow.$min_step_bid, // min id in auction
        id: profileStore.profileData.$value?.address, // UserId
        timestamp: new Date().getTime(),
      })
      return (
        <FinalModal
          onClose={onClose}
          flowStatus={flow.$txStatus}
          mode="placed-on-auction"
          txHash={flow.$txHash}
          nft={nft}
        />
      )
    }

    return null
  },
)
