
import { useIntl } from 'react-intl'

import { formatNumberSuffix } from '@/util/format'
import { ReactComponent as VerifiedSVG } from '@/assets/verified.svg'
import { Collection } from '@/api/luckyswap'
import { Price } from '@/components/common/Price'

import { CommonCard } from '../CommonCard'
import style from './style.module.scss'

type Props = {
  data: Collection
  isHorizontalLayout?: boolean
}

export function CollectionCard({
  data,
  isHorizontalLayout,
}: Props) {
  const intl = useIntl()
  return (
    <div className={style.contr}>
      <CommonCard
        titleOrder="title text"
        iconUrl={data.preview?.url || ''}
        previewUrl={data.banner?.url || ''}
        isHorizontalLayout={isHorizontalLayout}
        title={(
          <span className="flex flex-nowrap justify-left items-center gap-[5px]">
            <span className="min-w-0 overflow-hidden text-ellipsis">{data.name}</span>
            {data.isVerified && <span><VerifiedSVG /></span>}
          </span>
        )}
        text={`${formatNumberSuffix(data.totalSupply || 0)} ${intl.formatMessage({
          id: 'CollectionCards.Items', defaultMessage: 'Items',
        })}`}
        blocks={[
          {
            title: intl.formatMessage({
              id: 'CollectionPage.Total-volume',
              defaultMessage: 'Total volume',
            }),
            content: (
              <Price
                fromUnit={{ address: data.volume.address, total: data.volume.total || '0' }}
                showSymbol
              />
            ),
          },
          {
            title: intl.formatMessage({
              id: 'CollectionsFormItem.Floor',
              defaultMessage: 'Floor',
            }),
            content: (
              <Price
                fromUnit={{ address: data.floor.address, total: data.floor.total || '0' }}
                showSymbol
              />
            ),
          },
        ]}
      />
    </div>
  )
}
