import { observer } from 'mobx-react-lite'
import { useIntl } from 'react-intl'
import { useMemo } from 'react'
import BigNumber from 'bignumber.js'

import { useStores } from '@/hooks/useStores'
import { Modal } from '@/components/common/Modal'
import { ModalLayout } from '@/components/layout/ModalLayout'
import { ModalLayoutHeader } from '@/components/layout/ModalLayout/Header/ModalLayoutHeader'
import { Block } from '@/components/common/Block'
import { Button } from '@/components/common/Button'
import { FlowModalRow } from '@/components/modules/flows/components/FlowModalRow'
import { CurrenciesService } from '@/stores/Currencies.store'
import { calculatePercentAmount } from '@/util/convert'


export const DeleteOfferModal = observer(
  () => {
    const intl = useIntl()
    const { flowsStore } = useStores()
    const { getByAddress } = CurrenciesService

    const flow = flowsStore.$deleteOfferFlow
    const selectedOffer = flowsStore.$deleteOfferFlow?.selectedOffer
    if (!flow || !selectedOffer) return null

    const currency = getByAddress(selectedOffer.price.address)
    if (!currency) return null

    const { nft } = flow
    const { creatorRoyalties, serviceFee, youReceive } = useMemo(
      () => {
        const royalties = calculatePercentAmount(selectedOffer.price.total!, nft.artistRoyalties)
        const fee = calculatePercentAmount(selectedOffer.price.total!, nft.marketplaceRoyalties)
        const receive = new BigNumber(selectedOffer.price.total!).minus(royalties).minus(fee).toString()
        return { creatorRoyalties: royalties, serviceFee: fee, youReceive: receive }
      },
      [currency],
    )

    return (
      <Modal>
        <ModalLayout onClose={flow.dispose}>
          <Block lvl={1} className="max-w-md">
            <ModalLayoutHeader>
              {intl.formatMessage({
                id: 'DeleteOfferModal.accept-agree',
                defaultMessage: 'Offer cancel',
              })}
            </ModalLayoutHeader>

            <div className="mb-6 text-[color:var(--text-secondary)]">
              {intl.formatMessage({
                id: 'DeleteOfferModal.accept',
                defaultMessage: 'You are about to cancel your offer for a',
              })}
              {' '}
              <span className="text-[color:var(--active)]">
                {`${nft.name} ("${nft.collection.name}" ${intl.formatMessage({
                  id: 'Common.collection',
                  defaultMessage: 'collection',
                })})`}
              </span>
            </div>

            <div className="mb-6 flex flex-col flex-nowrap gap-2">
              <FlowModalRow
                title={intl.formatMessage({
                  id: 'Common.creator-royalties',
                  defaultMessage: 'Creator Royalties',
                })}
                price={{ ...currency, total: creatorRoyalties }}
              />

              <FlowModalRow
                title={`${intl.formatMessage({
                  id: 'Common.service-fee',
                  defaultMessage: 'Service fee',
                })} ${nft.marketplaceRoyalties}%`}
                price={{ ...currency, total: serviceFee }}
              />

              <FlowModalRow
                title={intl.formatMessage({
                  id: 'Common.you-receive',
                  defaultMessage: 'You receive',
                })}
                price={{ ...currency, total: youReceive }}
                variant="bright"
              />
            </div>

            <Button wide onClick={flow.deleteOffer}>
              {intl.formatMessage({
                id: 'DeleteOfferModal.confirm',
                defaultMessage: 'Confirm',
              })}
            </Button>
          </Block>
        </ModalLayout>
      </Modal>
    )
  },
)
