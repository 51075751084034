import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router-dom'

import { useStores } from '@/hooks/useStores'
import { DirectBuyNftStage } from '@/stores/flows/DirectBuyNftFlow.store'
import { ROUTES } from '@/routing/routes'
import { PARAMS } from '@/routing/params'

import { CheckoutModal } from './CheckoutModal'
import { FinalModal } from './FinalModal'
import { FollowStepsModal } from './FollowStepsModal'
import { FollowStep, StepMode } from '../components/FollowStep'


type Screen =
  | 'checkout'
  | 'followSteps'
  | 'final'

export const DirectBuyNftFlow = observer(
  () => {
    const intl = useIntl()

    const {
      flowsStore,
      profileStore,
    } = useStores()

    const flow = flowsStore.$directBuyNftFlow

    useEffect(() => () => flow?.dispose(), [flow])

    const [screen, setScreen] = useState<Screen>('checkout')
    const [getCurrentStep, setStep] = useState<0 | 1 | 2>(0)

    const nft = flow?.currentNft

    const stage = flow?.$stage
    const confirmationStatus = flow?.$txStatus

    useEffect(() => {
      if (stage === DirectBuyNftStage.checkout) setScreen('checkout')

      if (stage === DirectBuyNftStage.confirmation) setScreen('followSteps')

      if (stage === DirectBuyNftStage.confirmed) {
        setScreen('followSteps')

        setTimeout(() => setScreen('final'), 800)
      }

      if (stage === DirectBuyNftStage.error) setScreen('followSteps')
    }, [stage])

    if (!flow) return null
    if (!nft) return null

    if (screen === 'checkout') {
      return (
        <CheckoutModal
          nft={nft}
          onConfirm={() => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push(
              {
                event: 'step_2',
                category: 'buy_nft',
                nft: nft.name, // name of nft
                collection: nft.collection.name, // name of collection
                path: generatePath(ROUTES.nftPage, {
                  [PARAMS.collectionId]: nft.collection.slug,
                  [PARAMS.nftId]: nft.id,
                }), // path of nft
                id: profileStore.profileData.$value?.address.address, // UserId
                timestamp: new Date().getTime(),
              },
            )
            flow.confirm()
            setStep(DirectBuyNftStage.confirmation)
          }}
          onClose={flow.dispose}
          __forceSufficientFunds
        />
      )
    }

    if (screen === 'followSteps') {
      let stepMode: StepMode|null = null

      if (stage === DirectBuyNftStage.confirmed && getCurrentStep === DirectBuyNftStage.confirmation) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'step_3',
          category: 'buy_nft',
          nft: nft.name, // name of nft
          collection: nft.collection.name, // name of collection
          path: generatePath(ROUTES.nftPage, {
            [PARAMS.collectionId]: nft.collection.slug,
            [PARAMS.nftId]: nft.id,
          }), // path of nft
          id: profileStore.profileData.$value?.address.address, // UserId
          timestamp: new Date().getTime(),
        })
        stepMode = 'ok'
        setStep(DirectBuyNftStage.confirmed)
      }
      if (stage === DirectBuyNftStage.error) stepMode = 'error'

      return (
        <FollowStepsModal
          onClose={flow.dispose}
        >
          <FollowStep
            className="mb-4"
            title={intl.formatMessage({
              id: 'DirectBuyNftFlow.title.Approve-asset',
              defaultMessage: 'Approve asset',
            })}
            text={intl.formatMessage({
              id: 'DirectBuyNftFlow.text.Approve-asset',
              defaultMessage: 'This transaction is conducted only once per asset type',
            })}
            mode={stepMode}
          />

          <FollowStep
            className="mb-4"
            title={intl.formatMessage({
              id: 'DirectBuyNftFlow.title.Purchase',
              defaultMessage: 'Purchase',
            })}
            text={intl.formatMessage({
              id: 'DirectBuyNftFlow.text.Purchase',
              defaultMessage: 'Send transaction to purchase asset',
            })}
            mode={stepMode}
          />
        </FollowStepsModal>
      )
    }

    if (screen === 'final') {
      return (
        <FinalModal
          mode="bought-nft"
          flowStatus={confirmationStatus}
          nft={nft}
          txHash={flow.$txHash}
          onClose={flow.dispose}
        />
      )
    }

    return null
  },
)
