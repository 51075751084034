import {
  FormEvent, InputHTMLAttributes,
} from 'react'

import { noop } from '@/util/noop'
import { cn } from '@/util/exports'
import { ReactComponent as SearchSVG } from '@/assets/search.svg'


import { Block } from '../Block'

type Props =
InputHTMLAttributes<HTMLInputElement>
& {
  onChangeWithEvent?: (value: string, event: FormEvent<HTMLInputElement>) => void
  onChangeText?: (value: string) => void

  variant?: 'regular' | 'flat'
  showSearchIcon?: boolean

  containerClassName?: string

  wide?: boolean
}

export function Input({
  onChangeWithEvent = noop,
  onChangeText = noop,
  onInput,
  variant = 'regular',
  className,
  wide,
  containerClassName,
  showSearchIcon,
  ...restProps
}: Props) {
  function handleInput(e: FormEvent<HTMLInputElement>) {
    const el = e.target as HTMLInputElement

    onChangeWithEvent(el.value, e)
    onChangeText(el.value)
    onInput?.(e)
  }

  function renderInput() {
    return (
      <input
        type="text"
        {...restProps}
        className={cn(
          'bg-transparent',
          wide && 'w-full',
          className,
        )}
        onInput={handleInput}
      />
    )
  }

  if (variant === 'regular') {
    return (
      <Block
        size="s"
        className={cn(
          'bg-[color:var(--bg-2)] flex flex-row items-center',
          containerClassName,
        )}
      >
        {showSearchIcon && <SearchSVG className="mr-1.5" />}
        {renderInput()}
      </Block>
    )
  }

  if (variant === 'flat') {
    return renderInput()
  }

  return null
}
