import { useIntl } from 'react-intl'

import { ReactComponent as MintIcon } from '@/assets/mint.svg'
import { ReactComponent as SellIcon } from '@/assets/sale.svg'
import { ReactComponent as TransferIcon } from '@/assets/transfer.svg'
import { ReactComponent as ListingIcon } from '@/assets/add_circle_lite.svg'
import { ReactComponent as UnlistingIcon } from '@/assets/cancel.svg'
import { ReactComponent as BidAuctionIcon } from '@/assets/gavel.svg'
import { ReactComponent as OfferIcon } from '@/assets/offer-icon.svg'
import { ActivityTypes } from '@/constants/activity.constants'

type TransactionTypeInfoProps = {
  activityType: string;
}

export function TransactionTypeInfo({ activityType }:TransactionTypeInfoProps) {
  const intl = useIntl()

  const ACTIVITY_STATUS: Record<string, { text: string; icon: JSX.Element }> = {
    [ActivityTypes.Mint]: {
      text: intl.formatMessage({
        id: 'ActivitiesTable.Minting',
        defaultMessage: 'Minting',
      }),
      icon: <MintIcon />,
    },
    [ActivityTypes.Offer]: {
      text: intl.formatMessage({
        id: 'ActivitiesTable.Offer',
        defaultMessage: 'Offer',
      }),
      icon: <OfferIcon />,
    },
    [ActivityTypes.List]: {
      text: intl.formatMessage({
        id: 'ActivitiesTable.Listing',
        defaultMessage: 'Listing',
      }),
      icon: <ListingIcon />,
    },
    [ActivityTypes.Bid]: {
      text: intl.formatMessage({
        id: 'ActivitiesTable.Bid',
        defaultMessage: 'Bid',
      }),
      icon: <BidAuctionIcon />,
    },
    [ActivityTypes.CancelList]: {
      text: intl.formatMessage({
        id: 'ActivitiesTable.Unlisting',
        defaultMessage: 'Unlisting',
      }),
      icon: <UnlistingIcon />,
    },
    [ActivityTypes.Sell]: {
      text:  intl.formatMessage({
        id: 'ActivitiesTable.Sale',
        defaultMessage: 'Sale',
      }),
      icon: <SellIcon />,
    },
    [ActivityTypes.Transfer]: {
      text: intl.formatMessage({
        id: 'ActivitiesTable.Transfer',
        defaultMessage: 'Transfer',
      }),
      icon: <TransferIcon />,
    },
  }

  return (
    <span className="flex gap-2 items-center whitespace-nowrap">
      {ACTIVITY_STATUS[activityType].icon}
      {ACTIVITY_STATUS[activityType].text}
    </span>
  )
}
