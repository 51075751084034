export enum ActivityTypes {
  All = 'All',
  Mint = 'Mint',
  Offer = 'Offer',
  Cancel = 'Cancel',
  Sell = 'Sell',
  Transfer = 'Transfer',
  List = 'List',
  CancelList = 'CancelList',
  Bid = 'Bid',
}
