import { useEffect, useState } from 'react'

import { Blockchain } from '@/api/stubs/blockchain'
import { ChipsList, ChipsListProps } from '@/components/common/ChipsList'
import { DictService } from '@/api/luckyswap'

type Props = {
  selected: Blockchain[],
  onSelect: (selected: Blockchain[]) => void
}
& Pick<ChipsListProps, 'isDisabled'>

export function BlockchainsForm({
  selected,
  onSelect,
  isDisabled = false,
}: Props) {
  const [items, setItems] = useState<{ id: string; content: string }[]>([])
  useEffect(() => {
    if (items.length) return
    DictService
      .getBlockchain()
      .then(response => {
        setItems(
          response.map(bc => ({ id: bc.blockchain, content: bc.blockchain })),
        )
      })
  }, [items])

  return (
    <ChipsList<Blockchain>
      isDisabled={isDisabled}
      selected={selected}
      onSelect={onSelect}
      items={items}
    />
  )
}
