/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Collection = {
    addresses: Array<{
        address: string;
        blockchain: 'ethereum' | 'sepolia' | 'goerli';
    }>;
    attributes: Array<{
        floor?: {
            address: string;
            currency: string;
            total?: string;
        };
        format: 'textual' | 'numerical';
        percent?: number;
        total?: number;
        trait_object: {
            trait_type: string;
            trait_value?: string;
        };
    }>;
    avgSale24: {
        address: string;
        currency: string;
        total?: string;
    };
    banner: {
        url?: string;
    };
    description?: string;
    discord?: string;
    floor: {
        address: string;
        currency: string;
        total?: string;
    };
    icon: {
        url?: string;
    };
    isVerified: boolean;
    items?: number;
    linktree?: string;
    listed?: number;
    medium?: string;
    name: string;
    owner: {
        address: {
            address: string;
            blockchain: Collection.blockchain;
        };
        banner: {
            url?: string;
        };
        description?: string;
        discord?: string;
        followers: number;
        following: number;
        icon: {
            url?: string;
        };
        linktree?: string;
        medium?: string;
        name?: string;
        telegram?: string;
        twitter?: string;
        website?: string;
    };
    ownersCount: number;
    preview: {
        url?: string;
    };
    slug: string;
    telegram?: string;
    totalSupply?: number;
    twitter?: string;
    volume: {
        address: string;
        currency: string;
        total?: string;
    };
    website?: string;
};

export namespace Collection {

    export enum blockchain {
        ETHEREUM = 'ethereum',
        SEPOLIA = 'sepolia',
        GOERLI = 'goerli',
    }


}

