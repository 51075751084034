import { useState } from 'react'
import BigNumber from 'bignumber.js'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { observer } from 'mobx-react-lite'

import { Block } from '@/components/common/Block'
import { Modal } from '@/components/common/Modal'
import { ModalLayout, ModalLayoutProps } from '@/components/layout/ModalLayout'
import { ModalLayoutHeader } from '@/components/layout/ModalLayout/Header/ModalLayoutHeader'
import { Button } from '@/components/common/Button'
import { LoadingBox } from '@/components/common/LoadingBox'
import { CurrencyInput } from '@/components/common/CurrencyInput'
import { CurrenciesService } from '@/stores/Currencies.store'
import { CurrencyAndTotal } from '@/api/stubs/money'
import { useStores } from '@/hooks/useStores'
import { ROUTES } from '@/routing/routes'
import { PARAMS } from '@/routing/params'

type Props = {
  onConfirm: (data: CurrencyAndTotal) => void;
}
& Pick<ModalLayoutProps, 'onClose'>

export const EnterFixedPriceModal = observer(({ onClose, onConfirm }: Props) => {
  const intl = useIntl()
  const { flowsStore: { $directSellNftFlow }, nftPageStore: { $currentNft }, profileStore } = useStores()

  const { defaultCurrency } = CurrenciesService
  const [price, setPrice] = useState<CurrencyAndTotal>({ ...defaultCurrency, total: '0' })
  const [isValid, setValid] = useState(false)
  const [isPutting, setIsPutting] = useState(false)

  const handleOnChangePrice = (value: CurrencyAndTotal) => {
    setPrice(value)
    setValid(new BigNumber(value.total).shiftedBy(value.decimals).isGreaterThan(0))
  }

  async function handlePutOnSale() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'fixed_price_2',
      category: 'sale',
      nft: $currentNft?.name, // name of nft
      collection: $currentNft?.collection.name, // name of collection
      path: generatePath(ROUTES.nftPage, {
        [PARAMS.collectionId]: $currentNft?.collection.slug,
        [PARAMS.nftId]: $currentNft?.id,
      }), // path of nft
      price, // price of sale
      id: profileStore.profileData.$value?.address.address, // UserId
      timestamp: new Date().getTime(),
    })
    setIsPutting(true)
    $directSellNftFlow?.confirm(price)
    onConfirm(price)
  }

  return (
    <Modal>
      <ModalLayout onClose={onClose}>
        <Block
          lvl={1}
          className="max-w-md"
        >
          <ModalLayoutHeader>
            {intl.formatMessage({
              id: 'EnterFixedPriceModal.Enter-price',
              defaultMessage: 'Set the price',
            })}
          </ModalLayoutHeader>

          <div className="mb-6 text-[color:var(--text-secondary)]">
            {intl.formatMessage({
              id: 'EnterFixedPriceModal.Enter-price-message',
              defaultMessage: 'You are about to put on sale',
            })}
            {' '}
            <span className="text-[color:var(--active)]">
              {`${$currentNft?.name} ("${$currentNft?.collection.name}" ${intl.formatMessage({
                id: 'Common.collection',
                defaultMessage: 'collection',
              })})`}
            </span>
          </div>

          <div className="mb-2">
            {intl.formatMessage({
              id: 'EnterFixedPriceModal.Items-price',
              defaultMessage: 'Items price',
            })}
          </div>

          <CurrencyInput value={price} onChange={handleOnChangePrice} />

          <div className="flex flex-col flex-nowrap gap-4 mb-8">
            {/* <FlowModalRow */}
            {/*  title="Creator Royalties" */}
            {/*  price={{ currency: 'eth', total: '0' }} */}
            {/* /> */}

            {/* <FlowModalRow */}
            {/*  title="Service fee 0%" */}
            {/*  price={{ currency: 'eth', total: '0' }} */}
            {/* /> */}

            {/* <FlowModalRow */}
            {/*  title="You receive" */}
            {/*  variant="bright" */}
            {/*  price={{ */}
            {/*    currency: 'eth', */}
            {/*    total: toWei(priceETH || '0', 'ether'), */}
            {/*  }} */}
            {/* /> */}
          </div>

          <Button wide disabled={!isValid || isPutting} onClick={isValid ? handlePutOnSale : undefined}>
            <LoadingBox loading={isPutting}>
              {isValid ? `${intl.formatMessage({
                id: 'EnterFixedPriceModal.place-a',
                defaultMessage: 'Place a',
              })} ${price.total} ${price.symbol} ${intl.formatMessage({
                id: 'EnterFixedPriceModal.price',
                defaultMessage: 'price',
              })}` : intl.formatMessage({
                id: 'EnterFixedPriceModal.Incorrect-price',
                defaultMessage: 'Incorrect price',
              })}
            </LoadingBox>
          </Button>

        </Block>
      </ModalLayout>
    </Modal>
  )
})
