import { matchPath } from 'react-router'
import { makeObservable, observable } from 'mobx'

import { ROUTES } from '@/routing/routes'

// TODO move routes names to centralized file
const TRACKED_PATHS = [
  { path: ROUTES.root, title: 'Overview' },
  { path: ROUTES.marketplacePage, title: 'Marketplace' },
]

export class RoutingStore {
  @observable public $sourcePath = TRACKED_PATHS[0]

  constructor() {
    makeObservable(this)
  }

  public updatePathChange(path: string) {
    setTimeout(() => {
      const trackedPath = TRACKED_PATHS.find(
        item => matchPath(item.path, path),
      )

      if (!trackedPath) return

      this.$sourcePath = trackedPath
    })
  }
}
