import { observer } from 'mobx-react-lite'
import { useIntl } from 'react-intl'

import { useStores } from '@/hooks/useStores'

import { FollowStepsModal } from '../DirectBuyNftFlow/FollowStepsModal'
import { FollowStep } from '../components/FollowStep'
import { FinalModal } from '../DirectBuyNftFlow/FinalModal'
import { AcceptOfferModal } from './AcceptOfferModal'


export const AcceptOfferFlow = observer(
  () => {

    const intl = useIntl()

    const { flowsStore } = useStores()

    const flow = flowsStore.$acceptOfferFlow

    if (!flow) return null

    const status = flow.$status

    if (status === 'configuration') {
      return <AcceptOfferModal />
    }

    if (status === 'done') {
      return (
        <FinalModal
          flowStatus={flow.$txStatus}
          mode="accepted-offer"
          nft={flow.nft}
          txHash={flow.$txHash}
          onClose={flow.dispose}
        />
      )
    }

    return (
      <FollowStepsModal onClose={flow.dispose}>
        <FollowStep
          title={intl.formatMessage({
            id: 'AcceptOfferFlow.title.preparing',
            defaultMessage: 'Preparing',
          })}
          text={intl.formatMessage({
            id: 'AcceptOfferFlow.text.preparing',
            defaultMessage: 'We’re preparing everthing needed for the transaction',
          })}
          mode={flow.$stepsResults[0]}
        />

        <FollowStep
          title={intl.formatMessage({
            id: 'AcceptOfferFlow.title.transaction',
            defaultMessage: 'Transaction',
          })}
          text={intl.formatMessage({
            id: 'AcceptOfferFlow.text.transaction',
            defaultMessage: 'Confirm the transaction in your wallet.',
          })}
          mode={flow.$stepsResults[1]}
        />

        <FollowStep
          title={intl.formatMessage({
            id: 'AcceptOfferFlow.title.checking',
            defaultMessage: 'Checking',
          })}
          text={intl.formatMessage({
            id: 'AcceptOfferFlow.text.checking',
            defaultMessage: 'Checking whether the transaction was successful.',
          })}
          mode={flow.$stepsResults[2]}
        />
      </FollowStepsModal>
    )
  },
)
