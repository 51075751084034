
import { ChangeEvent } from 'react'

import { cn } from '@/util/exports'
import { Radio } from '@/components/common/Radio'
import { SaleType } from '@/api/stubs/sale-type'
import { useIntl } from 'react-intl'

export type FormSaleType = SaleType | 'all'
export const FormSaleType: {[k in FormSaleType]: k} = {
  ...SaleType,
  all: 'all',
}



type OptionsCountMap = {
  [k in FormSaleType]?: number
}

type Props = {
  optionCountMap?: OptionsCountMap

  selected: FormSaleType
  onSelect: (selected: FormSaleType) => void
}

export function SaleTypeForm({
  optionCountMap,
  onSelect,
  selected,
}: Props) {

  const intl = useIntl();
  
  const SALE_TYPES_NAMES = [
    ['all' as FormSaleType, intl.formatMessage(
      {
        id: "SaleTypeForm.All-Types",
        defaultMessage: "All Types"
    })],
    [SaleType.onSale, intl.formatMessage(
      {
        id: "SaleTypeForm.On-Sale",
        defaultMessage: "On Sale"
    })],
    [SaleType.onAuction, intl.formatMessage(
      {
        id: "SaleTypeForm.On-Auction",
        defaultMessage: "On Auction"
    })],
    [SaleType.notForSale, intl.formatMessage(
      {
        id: "SaleTypeForm.Not-For-Sale",
        defaultMessage: "Not For Sale"
    })],
  ] as const

  function handleCheck(e: ChangeEvent<HTMLInputElement>) {
    onSelect(e.target.value as FormSaleType)
  }

  return (
    <div
      className={cn(
        'flex flex-col flex-nowrap gap-[20px]',
      )}
    >
      {SALE_TYPES_NAMES.map(([type, name]) => {
        const count = optionCountMap?.[type]

        return (
          <Radio
            key={type}
            value={type}
            checked={selected === type}
            onChange={handleCheck}
          >
            <span>
              <span>{name}</span>

              {count && (
                <span
                  className={cn(
                    'float-right',
                    'font-["JetBrains_Mono"]',
                    'text-[color:var(--text-secondary)]',
                  )}
                >
                  {count}
                </span>
              )}
            </span>
          </Radio>
        )
      })}
    </div>
  )
}
