/* eslint-disable consistent-return */
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { autorun } from 'mobx'
import BigNumber from 'bignumber.js'

import { ClassProp } from '@/util/react-props'
import { PriceService } from '@/api/Price.service'
import { nil } from '@/util/nil'
import { readableCurrency } from '@/util/convert'
import { CurrencyAndTotal } from '@/api/stubs/money'
import { CurrenciesService } from '@/stores/Currencies.store'

type PriceProps = {
  fromUnit: { address?: string, total?: string } | nil
  to?: 'usdt'
  round?: number
  showSymbol?: boolean
} & ClassProp

export const Price = observer(({
  fromUnit,
  to,
  showSymbol,
  round,
  className,
}: PriceProps) => {
  const { getByAddress } = CurrenciesService
  const [price, setPrice] = useState(0)

  const originToken = useMemo<CurrencyAndTotal | null>(() => {
    if (!fromUnit?.address || !fromUnit?.total) return null
    const tokenDetails = getByAddress(fromUnit.address)
    if (!tokenDetails) return null
    return {
      ...tokenDetails,
      total: fromUnit.total,
      symbol: tokenDetails.symbol,
    }
  }, [fromUnit])

  useEffect(
    () => {
      if (originToken?.symbol) PriceService.require(originToken.symbol)
    },
    [originToken],
  )

  useEffect(() => {
    if (!to) return
    return autorun(() => {
      if (!originToken?.symbol) return
      const ticker = originToken.symbol.toUpperCase()
      setPrice(PriceService.$prices[ticker] || 0)
    })
  }, [to, originToken])

  const usdValue = useMemo(
    () => readableCurrency({
      amount: new BigNumber(originToken?.total || 0)
        .shiftedBy(-(originToken?.decimals || 0))
        .multipliedBy(price)
        .toString(),
      decimals: 0,
      round,
    }),
    [originToken, price],
  )

  const renderValue = () => {
    if (to) return usdValue
    if (!originToken) return '-'
    return readableCurrency({
      amount: originToken.total,
      decimals: originToken.decimals,
      symbol: showSymbol ? originToken.symbol : '',
      round,
    })
  }

  return (
    <span className={className}>
      {renderValue()}
    </span>
  )
})
