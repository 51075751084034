import React, { useEffect, useState } from 'react'
import { useQueryParam, useQueryParams } from 'use-query-params'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import cn from 'classnames'
import { useIntl } from 'react-intl'


import { ScrollToTop } from '@/components/common/ArrowScrollToTop'
import { PageLayout } from '@/components/layout/PageLayout'
import { Header } from '@/components/modules/Header'
import { Breadcrumbs } from '@/components/common/Breadcrumbs'
import { BodyLayout } from '@/components/layout/BodyLayout'
import { ViewportScroll } from '@/components/common/ViewportScroll'
import { SearchBar, SearchSort } from '@/components/pages/MarketplacePage/components/SearchBar'
import { CardType } from '@/components/modules/CardsGrid'
import { PROFILE_TABS } from '@/routing/tabs'
import { Footer } from '@/components/modules/Footer'
import { useStores } from '@/hooks/useStores'
import { CardsGridLoadable } from '@/components/common/CardsGridLoadable'
import { NFT_FILTER_QUERIES } from '@/components/modules/forms/form-query'
import { UserBlock } from '@/components/pages/ProfilePage/components/UserBlock'
import { TabItem } from '@/components/common/Tabs/TabItem'
import { Tabs } from '@/components/common/Tabs'
import { makeNFTsRequest } from '@/util/filter-api'
import { queryToFilter } from '@/components/pages/MarketplacePage/components/NFTsFilter/util'
import { Profile, UserService } from '@/api/luckyswap'
import EmptyProfileBanner from '@/assets/empty_profile_banner.png'
import { useEvmWallet } from '@/stores/EvmWalletService'
import { QUERIES } from '@/queries'

import { FilterParts, NFTsFilter } from '../MarketplacePage/components/NFTsFilter'
import style from './profile-page.module.scss'

const FILTER_PARTS: FilterParts = {
  blockchains: false,
  saleType: true,
  priceRange: false,
  attributes: true,
  collections: true,
}

export const ProfilePage = observer(
  () => {
    const intl = useIntl()

    const { marketplacePageStore: { nfts }} = useStores()
    const { userId } = useParams<{ userId: string }>()
    const { network } = useEvmWallet()

    const [sortByParam, setSortByParam] = useQueryParam<SearchSort>('')
    const [searchParam] = useQueryParam<string>('search')
    const [tabParam] = useQueryParam<keyof typeof PROFILE_TABS>('tab')
    const [filterQuery] = useQueryParams(NFT_FILTER_QUERIES)
    const [tab = PROFILE_TABS.myItems, setTab] = useQueryParam<keyof typeof PROFILE_TABS>(QUERIES.tab)
    const [profile, setProfile] = useState<Profile>()

    useEffect(() => setTab(tabParam), [tabParam])

    useEffect(() => {
      if (!userId) return
      if (tab === 'myItems') nfts.load(makeNFTsRequest(queryToFilter(filterQuery), searchParam, sortByParam, undefined, undefined, userId, '', ''))
      if (tab === 'bidsMade') nfts.load(makeNFTsRequest(queryToFilter(filterQuery), searchParam, sortByParam, true, undefined, '', '', userId))
      if (tab === 'bidsReceive') nfts.load(makeNFTsRequest(queryToFilter(filterQuery), searchParam, sortByParam, true, undefined, userId, '', ''))
      if (tab === 'offerMade') nfts.load(makeNFTsRequest(queryToFilter(filterQuery), searchParam, sortByParam, undefined, true, '', userId, ''))
      if (tab === 'offerReceive') nfts.load(makeNFTsRequest(queryToFilter(filterQuery), searchParam, sortByParam, undefined, true, userId, '', ''))
    }, [userId, filterQuery, sortByParam, searchParam, tab])

    useEffect(() => {
      if (userId) UserService.getUser(network?.value as any, userId).then(setProfile)
    }, [userId, network?.value])

    useEffect(() => {
      if (searchParam) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'search_ua',
          category: 'search',
          phrase: searchParam, // search phrase
          id: userId, // UserId
          timestamp: new Date().getTime(),
        })
      }
    }, [searchParam])

    useEffect(() => {
      if (Object.values(filterQuery).some(_ => _)) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'filter_ua',
          category: 'filter',
          filter_params: filterQuery, // array of filter params: {‘key’: ‘value’}
          id: userId, // UserId
          timestamp: new Date().getTime(),
        })
      }
    }, [filterQuery])

    return (
      <PageLayout>
        <PageLayout.Header>
          <PageLayout.Body>
            <Header />
          </PageLayout.Body>
        </PageLayout.Header>

        <PageLayout.Body>
          <Breadcrumbs
            className="my-7"
            items={[
              { text: intl.formatMessage({ id: 'ProfilePage.Overview', defaultMessage: 'Overview' }), link: '/' },
              { text: intl.formatMessage({ id: 'ProfilePage.Profile', defaultMessage: 'Profile' }) },
            ]}
          />
          <ScrollToTop />

          <div
            className={cn(style['collection-cover-image'], '-mx-10')}
            style={{ backgroundImage: `url(${profile?.banner?.url || EmptyProfileBanner})` }}
          />

          <BodyLayout
            leftColumn={(
              <ViewportScroll className=" w-[362px]">
                <UserBlock profile={profile} userId={userId} />
                <NFTsFilter parts={FILTER_PARTS} />
              </ViewportScroll>
            )}
            body={(
              <div className="w-full h-full container-type-inline-size" id="tabs-container">
                <Tabs className="my-6" selectId={tab} onSelect={setTab}>
                  <TabItem id={PROFILE_TABS.myItems}>
                    {intl.formatMessage({ id: 'ProfilePage.My-items', defaultMessage: 'My items' })}
                  </TabItem>
                  <TabItem id={PROFILE_TABS.bidsMade}>
                    {
                      intl.formatMessage({
                        id: 'ProfilePage.Bids-made',
                        defaultMessage: 'Bids{space}made',
                      }, { space:  <>&nbsp;</> })
                    }
                  </TabItem>
                  <TabItem id={PROFILE_TABS.bidsReceive}>
                    {
                      intl.formatMessage({
                        id: 'ProfilePage.Bids-receive',
                        defaultMessage: 'Bids{space}receive',
                      }, { space:  <>&nbsp;</> })
                    }
                  </TabItem>
                  <TabItem id={PROFILE_TABS.offerMade}>
                    {
                      intl.formatMessage({
                        id: 'ProfilePage.Offer-made',
                        defaultMessage: 'Offer{space}made',
                      }, { space:  <>&nbsp;</> })
                    }
                  </TabItem>
                  <TabItem id={PROFILE_TABS.offerReceive}>
                    {
                      intl.formatMessage({
                        id: 'ProfilePage.Offer-receive',
                        defaultMessage: 'Offer{space}receive',
                      }, { space:  <>&nbsp;</> })
                    }
                  </TabItem>
                </Tabs>
                <SearchBar
                  filterParts="all"
                  sort={sortByParam || SearchSort.price_asc}
                  onSortChange={setSortByParam}
                  onReloadClick={() => setSortByParam(SearchSort.price_asc)}
                />
                <CardsGridLoadable
                  className="mt-6"
                  onLoadNeeded={nfts.loadMore}
                  data={{ type: CardType.nft, value: nfts.$items }}
                />
              </div>
            )}
          />

        </PageLayout.Body>

        <PageLayout.Footer>
          <Footer />
        </PageLayout.Footer>
      </PageLayout>
    )
  },
)
