import { PropsWithChildren } from 'react'

import style from './index.module.scss'

type Props = {
}
& React.InputHTMLAttributes<HTMLInputElement>

export function Radio({
  children,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <label
      className={style.radio}
    >
      <input
        {...props}
        type="radio"
      />

      <div className={style.circle} />

      <span>
        {children}
      </span>
    </label>
  )
}
