import { useQueryParams } from 'use-query-params'
import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useIntl } from 'react-intl'

import { Delim } from '@/components/common/Delim'
import { PriceRangeForm, PriceRangeFormShape } from '@/components/modules/forms/PriceRangeForm'
import { SaleTypeForm } from '@/components/modules/forms/SaleTypeForm'
import { BlockchainsForm } from '@/components/modules/forms/BlockchainsForm'
import { Button } from '@/components/common/Button'
import { CollectionsForm } from '@/components/modules/forms/CollectionsForm'
import { Block } from '@/components/common/Block'
import { cn } from '@/util/exports'
import { ReactComponent as CloseSVG } from '@/assets/close.svg'
import { AttributesForm } from '@/components/modules/forms/AttributesForm'
import { NFT_FILTER_QUERIES } from '@/components/modules/forms/form-query'
import { CollectionsFilterFormStore } from '@/stores/CollectionsFilterForm.store'
import { DictService } from '@/api/luckyswap'

import { filterToQuery, NFTS_FILTER_INITIAL, queryToFilter } from './util'
import style from './style.module.scss'

export type FilterParts =
  | 'all'
  | {
    blockchains?: boolean
    saleType?: boolean
    priceRange?: boolean
    collections?: boolean
    attributes?: boolean
  }

type Props = {
  onCloseClick?: VoidFunction

  parts: FilterParts
}

export const NFTsFilter = observer(
  ({
    onCloseClick,
    parts,
  }: Props) => {

    const intl = useIntl()

    const [collectionsStore] = useState(
      () => new CollectionsFilterFormStore(),
    )

    const [query, setQuery] = useQueryParams(
      NFT_FILTER_QUERIES,
      { updateType: 'replaceIn' },
    )

    const filter = useMemo(() => queryToFilter(query), [query])

    const [price, setPrice] = useState<PriceRangeFormShape>()

    useEffect(() => {
      setPrice(filter.price)
    }, [filter])

    useEffect(() => {
      collectionsStore.collection.load({
        slug: filter.collection || undefined,
      })

      collectionsStore.selectCollection(filter.collection)
    }, [filter, collectionsStore])

    useEffect(() => {
      setQuery(prevQuery => ({
        ...prevQuery,
        attributes: [],
      }))
    }, [filter.collection])

    useEffect(() => {
      if (filter.blockchains.length || (parts !== 'all' && !parts.blockchains)) return
      DictService
        .getBlockchain()
        .then(res => {
          const blockchains = res.map(i => i.blockchain)
          setQuery(prev => ({ ...prev, blockchains }))
        })
    }, [filter.blockchains])

    function handleApplyPrice() {
      if (!price) return

      setQuery(
        prevQuery => ({
          ...prevQuery,
          ...filterToQuery({ ...filter, price }),
        }),
      )
    }

    function handleApply() {
      handleApplyPrice()
      onCloseClick?.()
    }

    function handleReset() {
      setQuery(
        filterToQuery(
          NFTS_FILTER_INITIAL,
        ),
      )
      onCloseClick?.()
    }

    return (
      <Block
        size="b"
        className={cn(
          'w-inh flex flex-col justify-between',
        )}
      >
        <div className={style.headerDesktop}>
          {intl.formatMessage({
            id: 'NFTsFilter.Filter',
            defaultMessage: 'Filter',
          })}
          <Button variant="flat" type="reset" onClick={handleReset}>
            {intl.formatMessage({
              id: 'NFTsFilter.Clear',
              defaultMessage: 'Clear',
            })}
          </Button>
        </div>

        <div className={style.headerMobile}>
          {intl.formatMessage({
            id: 'NFTsFilter.Filter',
            defaultMessage: 'Filter',
          })}
          <CloseSVG onClick={onCloseClick} className="float-right mt-1 cursor-pointer" />
        </div>

        <Delim variant="secondary" className="my-4" />

        {(parts === 'all' || parts.blockchains) && (
          <>
            <p className="uppercase mb-4">
              {intl.formatMessage({
                id: 'NFTsFilter.All-blockchains',
                defaultMessage: 'ALL BLOCKCHAINS',
              })}
            </p>
            <BlockchainsForm
              selected={filter.blockchains}
              onSelect={value => value.length && setQuery({ blockchains: value })}
            />
            <Delim variant="secondary" className="my-4" />
          </>
        )}


        {(parts === 'all' || parts.saleType) && (
          <>
            <p className="uppercase mb-6">
              {intl.formatMessage({
                id: 'NFTsFilter.Sale type',
                defaultMessage: 'SALE TYPE',
              })}
            </p>
            <SaleTypeForm
              selected={filter.saleType}
              onSelect={value => setQuery({ saleType: value })}
            />

            <Delim variant="secondary" className="my-4" />
          </>
        )}

        {(parts === 'all' || parts.priceRange) && (
          <>
            <PriceRangeForm
              range={price || {}}
              onRange={setPrice}
            />

            <Button
              wide
              type="submit"
              className={cn(
                'mt-4',
                style.applyUnderPrice,
              )}
              onClick={handleApplyPrice}
            >
              {intl.formatMessage({
                id: 'NFTsFilter.Apply',
                defaultMessage: 'Apply',
              })}
            </Button>

            <Delim variant="secondary" className="my-4" />
          </>
        )}


        {(parts === 'all' || parts.collections) && (
          <>
            <p className="uppercase mb-3">
              {intl.formatMessage({
                id: 'Common.collection',
                defaultMessage: 'collection',
              })}
            </p>
            <CollectionsForm
              store={collectionsStore}
              className={cn(!filter.collection && 'h-[400px]')}
              selectedId={filter.collection}
              onSelect={id => setQuery({ collection: id })}
            />
          </>
        )}

        {(parts === 'all' || parts.attributes) && filter.collection && (
          <AttributesForm
            className="mt-4"
            store={collectionsStore}
            selectedIds={filter.attributes}
            onSelect={ids => setQuery({ attributes: ids })}
          />
        )}

        <div className={style.controlsBottom}>
          <Button
            type="reset"
            wide
            variant="flat"
            onClick={handleReset}
          >
            {intl.formatMessage({
              id: 'NFTsFilter.Reset-All',
              defaultMessage: 'Reset All',
            })}
          </Button>
          <Button
            type="submit"
            wide
            variant="regular"
            onClick={handleApply}
          >
            {intl.formatMessage({
              id: 'NFTsFilter.Apply-Filters',
              defaultMessage: 'Apply Filters',
            })}
          </Button>
        </div>

      </Block>
    )
  },
)
