import {
  action, computed, makeObservable, observable,
} from 'mobx'

export enum AppLocales {
  RU = 'ru',
  EN = 'en'
}

export class LocaleStore {
  @observable private locale: AppLocales.EN | AppLocales.RU

  constructor() {
    makeObservable(this)
    if (typeof window !== 'undefined' && navigator) {
      this.locale = navigator.language.toLowerCase().includes('ru')
        ? AppLocales.RU
        : AppLocales.EN
    }
    else {
      this.locale = AppLocales.EN
    }
  }

  @computed public get getCurrentLocale() {
    return this.locale
  }

  @action.bound public setLocale(newLocale: AppLocales.EN | AppLocales.RU) {
    this.locale = newLocale
  }
}
