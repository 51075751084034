import { Config } from 'react-popper-tooltip'
import { generatePath, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { Attribute } from '@/api/stubs/attribute'
import { ReactComponent as EmptySVG } from '@/assets/empty_value.svg'
import { Block } from '@/components/common/Block'
import { Delim } from '@/components/common/Delim'
import { Tooltip } from '@/components/common/Tooltip'
import { ROUTES } from '@/routing/routes'
import { PARAMS } from '@/routing/params'
import { readableCurrency } from '@/util/convert'
import { CurrenciesService } from '@/stores/Currencies.store'

const TOOLTIP_CONFIG: Config = {
  followCursor: true,
  trigger: 'hover',
  offset: [0, 12],
}

type Props = {
  collectionSlug: string,
  attrs: Attribute[]
}

export function Attributes({ collectionSlug, attrs }: Props) {
  const { getByAddress } = CurrenciesService
  const intl = useIntl()

  return (
    <Block
      className="grid grid-cols-2 gap-3 max-h-[454px] overflow-auto"
    >
      {attrs
        .sort(attr => (attr.trait_object?.trait_value ? -1 : 1))
        .map(attr => {
          let floorValue: string | null = null
          if (attr.floor?.total) {
            const currency = getByAddress(attr.floor.address)
            if (currency) {
              floorValue = readableCurrency({
                amount: attr.floor.total,
                decimals: currency.decimals,
                symbol: currency.symbol,
              })
            }
          }
          const trait = attr.trait_object || {}
          const collectionRoute = generatePath(ROUTES.collectionPage, {
            [PARAMS.collectionId]: collectionSlug,
          })

          return (
            <Tooltip
              config={TOOLTIP_CONFIG}
              content={(
                <Block lvl={4} className="max-w-[200px]">
                  <div className="text-[color:var(--active)] text-sm">
                    <span>
                      1/
                      {attr.total}
                    </span>
                  </div>

                  <div className="text-xs mb-5">
                    {intl.formatMessage({
                      id: 'Attributes.number-of-occurs',
                      defaultMessage: 'The number of times the attribute occurs in other NFTs.',
                    })}
                  </div>

                  <div className="text-[color:var(--active)] text-sm">
                    {attr.percent}
                    %
                  </div>

                  <div className="text-xs">
                    {intl.formatMessage({
                      id: 'Attributes.rarity',
                      defaultMessage: 'The rarity percentage of the attribute.',
                    })}
                  </div>
                </Block>
              )}
            >
              <Link to={collectionRoute}>
                <Block hover size="s">
                  <div className="text-[color:var(--active)] text-xs mb-2">
                    {trait.trait_type}
                  </div>

                  <div className="text-base min-h-[1rem] min-h-[1.5rem] flex items-center">
                    {trait.trait_value || <EmptySVG />}
                  </div>

                  <div className="text-[color:var(--text-secondary)] text-xs mb-2 min-h-[1rem]">
                    {floorValue || ''}
                  </div>

                  <div className="text-[color:var(--text-secondary)] text-sm flex items-senter">
                    <span>
                      1 /
                      {attr.total}
                    </span>
                    <Delim vertical />
                    <span>
                      {attr.percent}
                      %
                    </span>
                  </div>
                </Block>
              </Link>
            </Tooltip>
          )
        })}
    </Block>
  )
}
