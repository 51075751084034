/* eslint-disable max-classes-per-file */
import {
  action, computed, makeObservable, observable,
} from 'mobx'
import { PromiEvent } from 'web3-core'

import { IDisposableMobx } from '@/interfaces/disposable-mobx.intf'
import { Chain } from '@/util/chain'
import { Web3TxStatus } from '@/util/web3'
import { EventService, EventStatus } from '@/api/luckyswap'
import { delay } from '@/util/promise'

type StepResult =
  | 'ok'
  | 'error'

type UniversalFlowStatus =
  | 'configuration'
  | 'confirmation'
  | 'done'
  | 'error'

export class UniversalFlowStore<T> implements IDisposableMobx {
  @observable public $status: UniversalFlowStatus = 'configuration'

  @observable public $txStatus: Web3TxStatus | null = null
  @observable public $txHash = ''

  @observable public $stepsResults: (StepResult | null)[] = []

  @observable public $disposed = false

  constructor(
    private readonly steps: Chain<any>,
  ) {
    makeObservable(this)

    this.$stepsResults = new Array(steps.length).fill(null)
  }

  @computed public get $allStepsOk() {
    return this.$stepsResults.every(
      step => step === 'ok',
    )
  }

  @action.bound private async transactionListener(txHash: string) {
    if (!this.$txHash) this.$txHash = txHash
    const txStatus = await EventService.getEventStatus(txHash)

    if (txStatus === EventStatus.COMPLETED) {
      this.$txStatus = Web3TxStatus.completed
      this.$status = 'done'
    }
    else {
      await delay(1000)
      this.transactionListener(txHash)
    }
  }

  @action.bound protected async trackTx(prome: PromiEvent<any>) {
    try {
      await prome.on('transactionHash', this.transactionListener)
    }
    catch (err) {
      this.$txStatus = Web3TxStatus.error
      this.$status = 'error'

      console.error(err)
    }
  }

  @action.bound protected async runSteps(params: T) {
    this.$status = 'confirmation'

    const { funcs } = this.steps

    let result: any = params
    let i = 0

    try {
      for (i = 0; i < funcs.length; i++) {
        const func = funcs[i]

        result = await func(result)

        this.$stepsResults[i] = 'ok'
      }
    }
    catch (err) {
      this.$stepsResults[i] = 'error'

      this.$status = 'error'

      console.error(err)
    }
  }

  @action.bound public dispose(): void {
    this.$disposed = true
  }
}
