import {
  computed, makeObservable, observable, reaction,
} from 'mobx'
import { DateTime } from 'luxon'

import { Nft, NftService } from '@/api/luckyswap'

import { ValueStore } from './util/ValueStore'

type NftDataParams = Parameters<typeof NftService.getCollection>

export class NFTPageStore {
  public readonly nftData = new ValueStore<Nft | undefined, NftDataParams>(
    undefined,
    async params => {
      if (!params) return undefined
      const r = await NftService.getCollection(...params)

      return r
    },
  )

  @observable public $isTimeUp = false

  constructor() {
    makeObservable(this)

    let timer: any = null
    reaction(
      () => this.nftData.$value?.auction,
      auction => {
        clearInterval(timer)

        if (!auction) return

        timer = setInterval(() => {
          this.$isTimeUp = DateTime.now() > DateTime.fromISO(auction.endTime)

          if (this.$isTimeUp) clearInterval(timer)
        }, 1000)
      },
    )
  }

  @computed public get $nftCollectionAddress() {
    return this.nftData.$value?.collection.addresses[0].address ?? null
  }

  @computed public get $nftId() {
    return this.nftData.$value?.id ?? null
  }

  @computed public get $currentNft() {
    return this.nftData.$value ?? null
  }

  @computed public get $currentNftAddress() {
    return this.nftData.$value?.address.address ?? null
  }

  @computed public get $ownerAddress() {
    return this.nftData.$value?.owner.address.address ?? null
  }
}
