import cn from 'classnames'

import EmptyAvatarImage from '@/assets/empty-avatar.svg'
import { ClassProp } from '@/util/react-props'

type AvatarProps = {
  avatarImage?: string,
  isRounded?: boolean,
} & ClassProp

export function Avatar({ avatarImage, className, isRounded = true }: AvatarProps) {
  return (
    <div
      style={{ backgroundImage: `url(${avatarImage || EmptyAvatarImage})` }}
      className={cn(
        className,
        'h-full w-full border-[3px] border-[color:#445775] bg-center bg-contain',
        isRounded ? 'rounded-full' : 'rounded-lg',
      )}
    />
  )
}
