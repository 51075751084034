export function removeFromArray(arr: any[], item: any) {
  const index = arr.indexOf(item)

  if (index < 0) return arr

  const clone = [...arr]

  clone.splice(index, 1)

  return clone
}
