import style from './style.module.scss'

type Props = {
  leftColumn: JSX.Element
  body: JSX.Element
}

export function BodyLayout({
  body,
  leftColumn,
}:Props) {
  return (
    <div className={style.box}>
      <div
        className={style.left}
      >
        {leftColumn}
      </div>

      <div
        className="w-full h-full"
      >
        {body}
      </div>
    </div>
  )
}
