export class Chain<P = void> {
  constructor(
    private readonly _funcs: ((prev: any) => any | Promise<any>)[] = [],
  ) { }

  public get funcs() {
    return [...this._funcs]
  }

  public get length() {
    return this._funcs.length
  }

  public add<R = void>(
    func: (prev: P extends void ? void : P) => R | Promise<R>,
  ) {
    this._funcs.push(func)

    return new Chain<R>(this._funcs)
  }
}
