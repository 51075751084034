import React, { useEffect, useMemo } from 'react'
import { useQueryParam, useQueryParams } from 'use-query-params'
import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'
import BigNumber from 'bignumber.js'
import { useIntl } from 'react-intl'

import { PageLayout } from '@/components/layout/PageLayout'
import { Header } from '@/components/modules/Header'
import { Breadcrumbs } from '@/components/common/Breadcrumbs'
import { BodyLayout } from '@/components/layout/BodyLayout'
import { ViewportScroll } from '@/components/common/ViewportScroll'
import { Block } from '@/components/common/Block'
import { cn } from '@/util/exports'
import { SearchBar, SearchSort } from '@/components/pages/MarketplacePage/components/SearchBar'
import { CardType } from '@/components/modules/CardsGrid'
import { CollectionProfile } from '@/components/pages/CollectionPage/components/collection-profile'
import { CollectionInfo } from '@/components/pages/CollectionPage/components/collection-info'
import { QUERIES } from '@/queries'
import { MARKETPLACE_TABS } from '@/routing/tabs'
import { ROUTES } from '@/routing/routes'
import { Footer } from '@/components/modules/Footer'
import { useStores } from '@/hooks/useStores'
import { PathParams } from '@/routing/params'
import { CardsGridLoadable } from '@/components/common/CardsGridLoadable'
import { makeCollectionsRequest, makeNFTsRequest } from '@/util/filter-api'
import { CollectionsPaginationResponse, Currency } from '@/api/luckyswap'
import { NFT_FILTER_QUERIES } from '@/components/modules/forms/form-query'
import { CurrenciesService } from '@/stores/Currencies.store'
import { queryToFilter } from '@/components/pages/MarketplacePage/components/NFTsFilter/util'

import style from './collection-page.module.scss'
import { FilterParts, NFTsFilter } from '../MarketplacePage/components/NFTsFilter'

const FILTER_PARTS: FilterParts = {
  blockchains: false,
  saleType: true,
  priceRange: false,
  attributes: true,
  collections: true,
}

const formatAmount = (
  { total, address }: CollectionsPaginationResponse['items'][0]['floor'],
  currencies: Currency[],
) => {
  const tokenDetails = currencies.find(cur => cur.address === address)
  return `${new BigNumber(total || 0).shiftedBy(-(tokenDetails?.decimals || 0)).toString()} ${tokenDetails?.symbol || ''}`
}

export const CollectionPage = observer(
  () => {
    const intl = useIntl()

    const {
      routingStore, marketplacePageStore: { collections }, collectionPageStore, profileStore,
    } = useStores()
    const { currencies } = CurrenciesService
    const { collectionId: slug } = useParams<PathParams>()

    const [sortByParam, setSortByParam] = useQueryParam<SearchSort>('')
    const [searchParam] = useQueryParam<string>('search')
    const [query, setQuery] = useQueryParams(NFT_FILTER_QUERIES, { updateType: 'replaceIn' })

    const currentCollection = useMemo(
      () => collections.$items.find(item => item.slug === slug),
      [slug, collections.$itemsTotal],
    )
    const collectionInfo = useMemo(() => {
      if (!currentCollection) return []
      return [
        {
          title: intl.formatMessage({
            id: 'CollectionPage.Floor-price',
            defaultMessage: 'Floor price',
          }),
          text: formatAmount(currentCollection.floor, currencies),
        },
        {
          title: intl.formatMessage({
            id: 'CollectionPage.Total-volume',
            defaultMessage: 'Total volume',
          }),
          text: formatAmount(currentCollection.volume, currencies),
        },
        {
          title: intl.formatMessage({
            id: 'CollectionPage.Owners',
            defaultMessage: 'Owners',
          }),
          text: currentCollection.ownersCount,
        },
        {
          title: intl.formatMessage({
            id: 'CollectionPage.Total-Supply',
            defaultMessage: 'Total Supply',
          }),
          text: currentCollection.totalSupply,
        },
      ]
    }, [currentCollection, intl])

    const socialLinks = useMemo(() => [
      {
        type: 'telegram',
        link: currentCollection?.telegram || '',
      },
      {
        type: 'website',
        link: currentCollection?.website || '',
      },
      {
        type: 'medium',
        link: currentCollection?.medium || '',
      },
      {
        type: 'discord',
        link: currentCollection?.discord || '',
      },
      {
        type: 'twitter',
        link: currentCollection?.twitter || '',
      },
      {
        type: 'linktree',
        link: currentCollection?.linktree || '',
      },
    ].filter(item => !!item.link), [currentCollection])

    useEffect(() => {
      if (!collections.$itemsTotal) collections.load(makeCollectionsRequest('', slug))
    }, [slug])

    useEffect(() => {
      setQuery({ collection: slug }, 'replaceIn')
      if (currentCollection && query.collection) {
        collectionPageStore.loadCollectionNfts(makeNFTsRequest(queryToFilter(query), searchParam, sortByParam))
      }
      return () => { collectionPageStore.reset() }
    }, [sortByParam, searchParam, query, collections.$itemsTotal])

    useEffect(() => {
      if (Object.values(query).some(_ => _)) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'filter_collection',
          category: 'filter',
          filter_params: query, // array of filter params: {‘key’: ‘value’}
          id: profileStore.profileData.$value?.address.address, // UserId
          timestamp: new Date().getTime(),
        })
      }
    }, [query])

    useEffect(() => {
      if (searchParam) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'search_collection',
          category: 'search',
          phrase: searchParam, // search phrase
          id: profileStore.profileData.$value?.address.address, // UserId
          timestamp: new Date().getTime(),
        })
      }
    }, [searchParam])

    return (
      <PageLayout>
        <PageLayout.Header>
          <PageLayout.Body>
            <Header />
          </PageLayout.Body>
        </PageLayout.Header>

        <PageLayout.Body>
          <Breadcrumbs
            className="my-7"
            items={[
              {
                text: routingStore.$sourcePath.title === 'Overview' ? intl.formatMessage({ id: 'CollectionPage.Overview', defaultMessage: 'Overview' }) : intl.formatMessage({ id: 'CollectionPage.Marketplace', defaultMessage: 'Marketplace' }),
                link: routingStore.$sourcePath.path,
              },
              { text: intl.formatMessage({ id: 'CollectionPage.Collection', defaultMessage: 'Collection' }), link: `${ROUTES.marketplacePage}?${QUERIES.tab}=${MARKETPLACE_TABS.collections}` },
              { text: currentCollection?.name || '' },
            ]}
          />

          <div
            className={cn(style['collection-cover-image'], '-mx-10')}
            style={{ backgroundImage: `url(${currentCollection?.banner?.url || ''})` }}
          />

          <BodyLayout
            leftColumn={(
              <ViewportScroll className="-mt-36 w-[362px]">
                <Block
                  size="b"
                  className={cn(
                    'w-inh flex flex-col justify-between mb-6',
                  )}
                >
                  <CollectionProfile
                    collectionName={currentCollection?.name}
                    owner={currentCollection?.owner}
                    isVerified={!!currentCollection?.isVerified}
                    description={currentCollection?.description}
                    preview={currentCollection?.preview?.url}
                    socialLinks={socialLinks}
                    address={currentCollection?.addresses[0].address}
                  />
                </Block>

                <NFTsFilter parts={FILTER_PARTS} />
              </ViewportScroll>
            )}
            body={(
              <div className="w-full h-full container-type-inline-size">
                <CollectionInfo collectionInfo={collectionInfo} />
                <SearchBar
                  filterParts="all"
                  sort={sortByParam || SearchSort.price_asc}
                  onSortChange={setSortByParam}
                  onReloadClick={() => setSortByParam(SearchSort.price_asc)}
                />
                <CardsGridLoadable
                  className="mt-6"
                  onLoadNeeded={collectionPageStore.collectionNfts.loadMore}
                  data={{ type: CardType.nft, value: collectionPageStore.collectionNfts.$items }}
                />
              </div>
            )}
          />

        </PageLayout.Body>

        <PageLayout.Footer>
          <Footer />
        </PageLayout.Footer>
      </PageLayout>
    )
  },
)
