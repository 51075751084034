import { useEffect, useState } from 'react'

import { NetworkOption, networkOptionsMap } from '@/util/get-networks'


export const useCurrentNetwork = (nftBlockchain: string | undefined, walletNetwork: NetworkOption | undefined) => {
  const [isCurrentNetwork, setIsCurrentNetwork] = useState(false)
  const [newChainId, setNewChainId] = useState(1)

  useEffect(() => {
    setIsCurrentNetwork(walletNetwork?.value === nftBlockchain)
    const chainId = Object
      .values(networkOptionsMap)
      .find(networkOption => networkOption.value === nftBlockchain)
      ?.chainId

    if (chainId) setNewChainId(chainId)
  }, [nftBlockchain, walletNetwork])

  return { isCurrentNetwork, newChainId }
}
