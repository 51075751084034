import { PropsWithChildren } from 'react'

import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'

type Props = {
}
& ClassProp

export function ModalLayoutHeader({
  children,
  className,
}: PropsWithChildren<Props>) {
  return (
    <div className={cn(
      'font-["JetBrains_Mono"] font-bold text-[32px] mb-4',
      className,
    )}
    >
      {children}
    </div>
  )
}
