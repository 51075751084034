const MARKETPLACE_CONTRACT_ADR_MOCK = '0xec5f47C252A180500b22C63c98D02128eA9902d1'
const REACT_APP_ERC_20_CONTRACT_ADR_MOCK = '0xC67aA1E41039CA9b2DFBB97adFA90f36fc33D738'
const PROXY_CONTRACT_ADR_MOCK = '0x27eAa09058B2c5685063058356aa6512fe969A05'
const API_URL_MOCK = 'https://lswap-prod-back.bf.works'

export const ENV = {
  MARKETPLACE_CONTRACT_ADR: process.env.REACT_APP_MARKETPLACE_CONTRACT_ADR || MARKETPLACE_CONTRACT_ADR_MOCK,
  ERC20_CONTRACT_ADR: process.env.REACT_APP_ERC_20_CONTRACT_ADR || REACT_APP_ERC_20_CONTRACT_ADR_MOCK,
  PROXY_CONTRACT_ADR: process.env.REACT_APP_PROXY_CONTRACT_ADR || PROXY_CONTRACT_ADR_MOCK,
  API_URL: process.env.REACT_APP_API_URL || API_URL_MOCK,

  ERC20_TOKEN_ADR: '0xAAEEe85ba28CCdDf293075F48EF3d443c8e6A8cD',
} as const
