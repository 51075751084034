
import copy from 'copy-to-clipboard'

import { Modal } from '@/components/common/Modal'
import { ModalLayout } from '@/components/layout/ModalLayout'
import { MediaPreview } from '@/components/common/MediaPreview'
import { Block } from '@/components/common/Block'
import { STUB } from '@/util/stub'
import { ReactComponent as VerifiedSVG } from '@/assets/verified.svg'
import { ReactComponent as Twitter } from '@/assets/twitter-white.svg'
import { ReactComponent as VK } from '@/assets/vk.svg'
import { ReactComponent as Facebook } from '@/assets/facebook.svg'
import { ReactComponent as Telegram } from '@/assets/telegram-white.svg'
import { ReactComponent as Copy } from '@/assets/copy_white.svg'
import { useStores } from '@/hooks/useStores'

import style from './style.module.scss'

const text = 'Check out this item on Luckyswap!'
const PROD_URL = 'https://lswap-prod-front.bf.works'

type ShareProps = {
  showModal?: boolean
  onClose?: () => void
  nftData: any
}
export function ShareModal({ showModal, nftData, onClose } : ShareProps) {
  if (!showModal || !window) return null

  const {
    profileStore,
  } = useStores()

  const socials = [
    {
      name: 'Twiiter',
      icon: <Twitter />,
      link: `https://twitter.com/intent/tweet?text=${text}&url=${PROD_URL}${window.location.pathname}`,
    },
    {
      name: 'Facebook',
      icon: <Facebook />,
      link: `https://www.facebook.com/sharer/sharer.php?u=${PROD_URL}${window.location.pathname}`,
    },
    {
      name: 'Telegram',
      icon: <Telegram />,
      link: `https://telegram.me/share/url?url=${PROD_URL}${window.location.pathname}&text=${text}`,
    },
    {
      name: 'VK',
      icon: <VK />,
      link: `https://vk.com/share.php?url=${PROD_URL}${window.location.pathname}&title=${text}`,
    },
  ]

  return (
    <Modal>
      <ModalLayout onClose={onClose}>
        <Block lvl={1} className="max-w-[100vw]">
          <div
            className="max-w-[420px] h-[420px]"
          >
            <MediaPreview
              url={nftData?.image?.url || ''}
            />
          </div>
          <h1 className="text-3xl mt-8 mb-2 font-medium">{nftData?.name}</h1>
          <div className="flex flex-row flex-nowrap gap-2 items-center mb-6">
            <div
              className="w-6 h-6 rounded-sm overflow-hidden shrink-0"
            >
              <MediaPreview
                url={nftData?.collection?.preview?.url || ''}
              />
            </div>

            <span>{nftData?.collection.name || STUB}</span>

            {nftData?.collection.isVerified && (
              <VerifiedSVG className="shrink-0" />
            )}
          </div>

          <div className="flex flex-wrap justify-between">
            {socials.map((item: any) => (
              <a
                href={item.link}
                key={item.name}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: 'share',
                    category: 'social',
                    path: item.link, // path of external link
                    s_network: item.name, // social network name [discord|instagram etc…]
                    id: profileStore.profileData.$value?.address.address, // UserId
                    timestamp: new Date().getTime(),
                  })
                }}
              >
                <Block size="s" className={style.block}>
                  {item.icon}
                  <span className="text-base mt-2 text-[color:var(--text-secondary)]">{item.name}</span>
                </Block>
              </a>
            ))}
          </div>
          <Block className={style.copy} onClick={() => copy(`${PROD_URL}${window.location.pathname}`)}>
            <span className="
                truncate
                max-w-[315px]
                text-base
                text-[color:var(--text-secondary)]
                transition-colors`
              "
            >
              {PROD_URL}
              {window.location.pathname}
            </span>
            <Copy />
          </Block>
        </Block>
      </ModalLayout>
    </Modal>
  )
}
