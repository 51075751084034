import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import BigNumber from 'bignumber.js'
import { generatePath } from 'react-router'

import { Modal } from '@/components/common/Modal'
import { ModalLayout } from '@/components/layout/ModalLayout'
import { ModalLayoutHeader } from '@/components/layout/ModalLayout/Header/ModalLayoutHeader'
import { useStores } from '@/hooks/useStores'
import { Block } from '@/components/common/Block'
import { Price } from '@/components/common/Price'
import { Button } from '@/components/common/Button'
import { CurrencyInput } from '@/components/common/CurrencyInput'
import { CurrenciesService } from '@/stores/Currencies.store'
import { CurrencyAndTotal } from '@/api/stubs/money'
import { EMPTY_ADR_40 } from '@/util/web3'
import { PARAMS } from '@/routing/params'
import { ROUTES } from '@/routing/routes'

export const MakeOfferModal = observer(
  () => {
    const intl = useIntl()
    const { currencies } = CurrenciesService
    const [price, setPrice] = useState<CurrencyAndTotal | null>(null)
    const { flowsStore, profileStore } = useStores()

    useEffect(() => {
      if (price) return
      const erc20 = currencies.filter(cur => cur.address !== EMPTY_ADR_40)
      if (erc20[0]) setPrice({ ...erc20[0], total: '0' })
    }, [currencies])

    const flow = flowsStore.$makeOfferFlow
    if (!flow) return null

    const { nft } = flow

    const handleMakeOffer = () => {
      if (price) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'step_2',
          category: 'make_offer_nft',
          nft: nft.name, // name of nft
          collection: nft.collection.name, // name of collection
          path: generatePath(ROUTES.nftPage, {
            [PARAMS.collectionId]: nft.collection.slug,
            [PARAMS.nftId]: nft.id,
          }), // path of nft
          cost: price, // offer cost
          id: profileStore.profileData.$value?.address.address, // UserId
          timestamp: new Date().getTime(),
        })
        flow?.makeOffer(price)
      }
    }

    return (
      <Modal>
        <ModalLayout onClose={flow.dispose}>
          <Block lvl={1} className="max-w-md">
            <ModalLayoutHeader>
              {intl.formatMessage({
                id: 'MakeOfferModal.Make-offer',
                defaultMessage: 'Make offer',
              })}
            </ModalLayoutHeader>

            <div className="mb-6 text-[color:var(--text-secondary)]">
              {intl.formatMessage({
                id: 'MakeOfferModal.Make-offer-message',
                defaultMessage: 'You are about to make offer for',
              })}
              {' '}
              <span className="text-[color:var(--active)]">
                {`${nft.name} ("${nft.collection.name}" ${intl.formatMessage({
                  id: 'Common.collection',
                  defaultMessage: 'collection',
                })})`}
              </span>
            </div>

            <div>
              <span>
                {intl.formatMessage({
                  id: 'MakeOfferModal.Your-price',
                  defaultMessage: 'Your price',
                })}
              </span>
              {!!nft.sell?.price && (
                <span
                  className="float-right text-[color:var(--text-secondary)]"
                >
                  {intl.formatMessage({
                    id: 'MakeOfferModal.Current-price',
                    defaultMessage: 'Current price -',
                  })}
                  {' '}
                  <Price
                    fromUnit={nft.sell.price}
                    showSymbol
                    round={6}
                  />
                </span>
              )}

              {price && (
                <CurrencyInput
                  onlyErc20
                  className="mt-2 mb-6"
                  value={price}
                  onChange={setPrice}
                />
              )}
            </div>

            <div className="flex flex-col flex-nowrap gap-3 mb-6">
              {/* TODO return modal rows */}
              {/* <FlowModalRow */}
              {/*  title={intl.formatMessage({
                      id: "Common.balance",
                      defaultMessage: "Balance"
                    })} */}
              {/*  price={{ total: wallet.balance, currency: 'eth' }} */}
              {/* /> */}

              {/* <FlowModalRow */}
              {/*  title={intl.formatMessage({
                      id: "Common.Service-fee",
                      defaultMessage: "Service fee 0"
                    })} */}
              {/*  price={{ total: '0', currency: 'eth' }} */}
              {/* /> */}

              {/* <FlowModalRow */}
              {/*  title={intl.formatMessage({
                      id: "Common.You-will-pay",
                      defaultMessage: "You will pay"
                    })} */}
              {/*  price={{ */}
              {/*    total: priceWEI || '0', */}
              {/*    currency: 'eth', */}
              {/*  }} */}
              {/* /> */}
            </div>

            <Button
              wide
              onClick={handleMakeOffer}
              disabled={!price || new BigNumber(price.total).isZero() || new BigNumber(price.total).isNaN()}
              nftBlockchains={nft.address.blockchain}
              isWalletRequired
            >
              {intl.formatMessage({
                id: 'MakeOfferModal.Send-offer',
                defaultMessage: 'Send offer',
              })}
            </Button>

          </Block>
        </ModalLayout>
      </Modal>
    )
  },

)
