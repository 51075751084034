import {
  ChangeEvent,
} from 'react'

import { cn } from '@/util/exports'
import { removeFromArray } from '@/util/array'

import { Block } from '../Block'

export type ChipsListProps<T extends string = string> = {
  selected: T[]
  onSelect: (selected: T[]) => void
  onChange?: (e: ChangeEvent) => void

  isDisabled?: boolean

  inputsName?: string

  items: { id: string, content: string | JSX.Element }[]
}

export function ChipsList<T extends string = string>({
  items,
  selected,
  onSelect,
  onChange,

  inputsName,
  isDisabled = false,
}: ChipsListProps<T>) {
  function handleChange(e: ChangeEvent) {
    if (isDisabled) return

    onChange?.(e)

    const el = e.target as HTMLInputElement
    if (!('checked' in el)) return

    const { checked } = el
    const id = el.dataset.id as T
    if (!id) return

    if (!checked) {
      const newSelected = removeFromArray(selected, id)
      onSelect(newSelected)
    }
    else {
      selected.push(id)
      onSelect([...selected])
    }
  }

  return (
    <div
      className={cn(
        'flex flex-row flex-wrap gap-2',
      )}
    >
      {items.map(item => {
        const isSelected = selected.includes(item.id as T)

        return (
          <label key={item.id} className="cursor-pointer">
            <input
              className="hidden"
              name={inputsName}
              data-id={item.id}
              value={item.id}
              type="checkbox"
              checked={isSelected}
              onChange={handleChange}
            />
            <Block
              size="xs"
              className={cn(
                'text-[color:var(--text-secondary)]',
                'select-none',
                isDisabled && 'brightness-75',
                isSelected && 'text-[color:var(--text-primary-invert)]',
                isSelected && '!bg-[color:var(--active)]',
              )}
            >
              {typeof item.content === 'string' ? (
                <span>{item.content}</span>
              ) : (
                item.content
              )}
            </Block>
          </label>
        )
      })}
    </div>
  )
}
