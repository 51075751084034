import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { useStores } from '@/hooks/useStores'
import { Block } from '@/components/common/Block'
import { Timer } from '@/components/common/Timer'
import { cn } from '@/util/exports'
import { Delim } from '@/components/common/Delim'
import { useNftStates } from '@/hooks/useNftStates'
import { calcBidStep, calcMinBid } from '@/util/auction'
import { Price } from '@/components/common/Price'
import { Avatar } from '@/components/common/Avatar'
import { ROUTES } from '@/routing/routes'
import { PARAMS } from '@/routing/params'
import { ellipsis } from '@/util/ellipsis'

import { InteractionListItem } from './InteractionListItem'

export const Content = observer(
  () => {
    const intl = useIntl()

    const {
      nftPageStore,
      profileStore
    } = useStores()

    const auction = nftPageStore.nftData.$value?.auction
    const sell = nftPageStore.nftData.$value?.sell

    const {
      isNftOnSale,
      isNftOnAuction,
    } = useNftStates()

    const highestBid = useMemo(() => {
      if (!auction?.bidsCount) return null
      const highestBidItem = auction.bids.slice().sort()[0]
      return {
        maker: highestBidItem.maker,
        makerPath: generatePath(ROUTES.userMarketPage, { [PARAMS.userId]: highestBidItem.maker }),
        address: highestBidItem.price.address,
        total: highestBidItem.price.total || '0',
      }
    }, [auction?.bidsCount])

    if (isNftOnAuction && auction) {
      const minBid = calcMinBid(auction)
      const bidStep = calcBidStep(auction)

      return (
        <>
          <Timer
            className="mb-6"
            targetTimeISO={auction?.endTime || ''}
            days
            hours
            minutes
            seconds
          />

          <Block size="s" className="!p-0 mb-6">

            {!!highestBid && (
              <>
                <InteractionListItem
                  icon={<Avatar className="!min-w-[44px] !min-h-[40px] !max-h-[44px]" />}
                  title={
                    intl.formatMessage({
                      id: 'Content.Highest-bid',
                      defaultMessage: 'Highest bid',
                    })
                  }
                  text={(
                    <>
                      {intl.formatMessage({
                        id: 'Content.by',
                        defaultMessage: 'by',
                      })}
                      &nbsp;
                      <Link
                        to={highestBid.makerPath} className="text-[color:var(--active)]"
                        onClick={() => {
                          window.dataLayer = window.dataLayer || []
                          window.dataLayer.push({
                            event: 'bigest_bid_profile',
                            category: 'page_view',
                            profile: highestBid.makerPath, // external user profile id
                            id: profileStore.profileData.$value?.address.address, // UserId
                            timestamp: new Date().getTime(),
                          })
                        }}
                      >
                        {ellipsis(highestBid.maker, 5, 5)}
                      </Link>
                    </>
                  )}
                  price={{ address: highestBid.address, total: highestBid.total }}
                />

                <Delim variant="secondary" />
              </>
            )}

            <InteractionListItem
              title={intl.formatMessage({
                id: 'Content.Min-bid',
                defaultMessage: 'Min. bid',
              })}
              price={{ address: minBid.address, total: minBid.total || '0' }}
              text={(
                auction.bidsCount
                  ? `${auction.bidsCount} ${intl.formatMessage({ id: 'Content.bids', defaultMessage: 'bids' })}`
                  : intl.formatMessage({ id: 'Content.no-bids', defaultMessage: 'No bids yet' })
              )}
            />

            <Delim variant="secondary" />

            <InteractionListItem
              title={intl.formatMessage({ id: 'Content.bid-step', defaultMessage: 'Bid step' })}
              price={{ address: bidStep.address, total: bidStep.total || '0' }}
            />
          </Block>
        </>
      )
    }

    if (isNftOnSale && sell) {
      return (
        <Block size="s" className="w-fit mb-6">
          <div className="text-[16px] text-[color:var(--text-secondary)]">
            {intl.formatMessage({ id: 'Content.Price', defaultMessage: 'Price' })}
          </div>

          <div className='font-["JetBrains_Mono"] text-[color:var(--active)] text-[32px]'>
            <Price fromUnit={sell.price} showSymbol />
          </div>

          <div className='font-["JetBrains_Mono"]'>
            $
            <Price fromUnit={sell.price} to="usdt" round={5} />
          </div>

        </Block>
      )
    }

    return (
      <Block
        size="s"
        className={cn(
          'w-fit mb-6',
          'font-["JetBrains_Mono"] text-[color:var(--active)] text-[32px]',
        )}
      >
        <div>
          {intl.formatMessage({
            id: 'Content.Not-for-sale',
            defaultMessage: 'Not for sale',
          })}
        </div>

      </Block>
    )
  },
)
