/* eslint-disable consistent-return */
import { observer } from 'mobx-react-lite'
import { useQueryParam, useQueryParams } from 'use-query-params'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { PageLayout } from '@/components/layout/PageLayout'
import { Header } from '@/components/modules/Header'
import { Breadcrumbs } from '@/components/common/Breadcrumbs'
import { ViewportScroll } from '@/components/common/ViewportScroll'
import { BodyLayout } from '@/components/layout/BodyLayout'
import { Tabs } from '@/components/common/Tabs'
import { CardType } from '@/components/modules/CardsGrid'
import { TabItem } from '@/components/common/Tabs/TabItem'
import { QUERIES } from '@/queries'
import { MARKETPLACE_TABS } from '@/routing/tabs'
import { useStores } from '@/hooks/useStores'
import { Footer } from '@/components/modules/Footer'
import { NFT_FILTER_QUERIES } from '@/components/modules/forms/form-query'
import { CardsGridLoadable } from '@/components/common/CardsGridLoadable'
import { ScrollToTop } from '@/components/common/ArrowScrollToTop'
import { makeCollectionsRequest, makeNFTsRequest } from '@/util/filter-api'

import { SearchBar, SearchSort } from './components/SearchBar'
import style from './style.module.scss'
import { FilterParts, NFTsFilter } from './components/NFTsFilter'
import { queryToFilter } from './components/NFTsFilter/util'
import Banner from './components/banner/banner'

export const MarketplacePage = observer(() => {
  const intl = useIntl()

  const { marketplacePageStore } = useStores()
  const { profileStore } = useStores()

  const [tab = MARKETPLACE_TABS.nfts, setTab] = useQueryParam<keyof typeof MARKETPLACE_TABS>(QUERIES.tab)
  const [filterQuery] = useQueryParams(NFT_FILTER_QUERIES)
  const [search] = useQueryParam<string>(QUERIES.search)

  const [sort, setSort] = useState<SearchSort>(SearchSort.price_asc)

  const {
    nfts,
    collections,
  } = marketplacePageStore

  const isNftsTab = tab === MARKETPLACE_TABS.nfts


  const filterParts: FilterParts = {
    blockchains: true,
    saleType: isNftsTab,
    priceRange: false,
    collections: isNftsTab,
    attributes: isNftsTab,
  }

  const excludeSortItems = tab === 'collections'
    ? [SearchSort.price_asc, SearchSort.price_desc, SearchSort.published_asc, SearchSort.published_desc]
    : [SearchSort.createdAt_asc, SearchSort.createdAt_desc]

  useEffect(
    () => setSort(tab === 'collections' ? SearchSort.createdAt_desc : SearchSort.price_asc),
    [tab],
  )

  useEffect(() => {
    if (tab === 'nfts') nfts.load(makeNFTsRequest(queryToFilter(filterQuery), search, sort))
    if (tab === 'collections') collections.load(makeCollectionsRequest(search))


  }, [tab, filterQuery, sort, search])

  useEffect(() => {
    if (search) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'search_marketplace',
        category: 'search',
        phrase: search, // search phrase
        id: profileStore.profileData.$value?.address.address, // UserId
        timestamp: new Date().getTime(),
      })
    }
  }, [search])

  useEffect(() => {
    if (Object.values(filterQuery).some(_ => _)) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'filter_marketplace',
        category: 'filter',
        filter_params: filterQuery, // array of filter params: {‘key’: ‘value’}
        id: profileStore.profileData.$value?.address.address, // UserId
        timestamp: new Date().getTime(),
      })
    }
  }, [filterQuery])

  function handleReload() {
    if (tab === 'nfts') nfts.reload()
    if (tab === 'collections') collections.reload()
  }

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Body>
          <Header />
        </PageLayout.Body>
      </PageLayout.Header>

      <PageLayout.Body>

        <Breadcrumbs
          className="my-7"
          items={[
            // TODO add routes
            {
              text: intl.formatMessage(
                {
                  id: 'MarketplacePage.Overview',
                  defaultMessage: 'Overview',
                },
              ),
              link: '/',
            },
            {
              text: intl.formatMessage(
                {
                  id: 'MarketplacePage.Marketplace',
                  defaultMessage: 'Marketplace',
                },
              ),
            },
          ]}
        />
        <ScrollToTop />
        <Banner />

        <BodyLayout
          leftColumn={(
            <ViewportScroll>
              <NFTsFilter parts={filterParts} />
            </ViewportScroll>
          )}
          body={(
            <div className="w-full h-full container-type-inline-size">
              <Tabs
                className={style.tabs}
                selectId={tab}
                onSelect={setTab}
              >
                <TabItem id={MARKETPLACE_TABS.nfts}>
                  {intl.formatMessage({
                    id: 'MarketplacePage.NFTs',
                    defaultMessage: 'NFTs',
                  })}
                </TabItem>

                <TabItem id={MARKETPLACE_TABS.collections}>
                  {intl.formatMessage({
                    id: 'MarketplacePage.Collections',
                    defaultMessage: 'Collections',
                  })}
                </TabItem>

              </Tabs>

              <SearchBar
                onReloadClick={handleReload}
                sort={sort}
                onSortChange={setSort}
                filterParts={filterParts}
                excludeSort={excludeSortItems}
              />

              {tab === 'nfts' && (
                <CardsGridLoadable
                  className="mt-6"
                  onLoadNeeded={nfts.loadMore}
                  data={{ type: CardType.nft, value: nfts.$items }}
                  userId={profileStore.profileData.$value?.address.address}
                />
              )}

              {tab === 'collections' && (
                <CardsGridLoadable
                  className="mt-6"
                  onLoadNeeded={collections.loadMore}
                  data={{ type: CardType.collection, value: collections.$items }}
                  userId={profileStore.profileData.$value?.address.address}
                />
              )}

            </div>
          )}
        />

      </PageLayout.Body>

      <PageLayout.Footer>
        <Footer />
      </PageLayout.Footer>
    </PageLayout>
  )
})
