import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { observer } from 'mobx-react-lite'
import { useIntl } from 'react-intl'

import { ReactComponent as ProcessingIcon } from '@/assets/processing.svg'
import { Button } from '@/components/common/Button'
import { cn } from '@/util/exports'
import { useEvmWallet } from '@/stores/EvmWalletService'
import { useStores } from '@/hooks/useStores'
import { ROUTES } from '@/routing/routes'
import { useCurrentNetwork } from '@/hooks/useCurrentNetwork'
import { BlockchainRecord, DictService } from '@/api/luckyswap'

import styles from './styles.module.scss'

export const SignMessage = observer(() => {
  const intl = useIntl()

  const navigate = useNavigate()
  const {
    disconnect, isReady, network, changeNetwork,
  } = useEvmWallet()
  const { profileStore } = useStores()
  const [blockchainList, setBlockchainList] = useState<BlockchainRecord[]>([])
  const [isSignProcess, setIsSignProcess] = useState(false)

  const { isCurrentNetwork, newChainId } = useCurrentNetwork(blockchainList[0]?.blockchain, network)

  const handleSignMessage = async () => {
    setIsSignProcess(true)
    if (!isCurrentNetwork) changeNetwork(newChainId)
    else await profileStore.createProfile()
    setIsSignProcess(false)
  }

  useEffect(() => {
    if (blockchainList.length) return
    DictService.getBlockchain().then(setBlockchainList)
  }, [intl])

  useEffect(() => {
    if (!profileStore.profileData.$value) return
    navigate(ROUTES.marketplacePage)
  }, [profileStore.profileData.$value])

  if (
    !isReady
    || (isReady && profileStore.profileData.$value && !profileStore.profileData.$isLoading)
    || (isReady && profileStore.profileData.$isLoading)
  ) return null

  return (
    <>
      <h1 className="text-[48px] font-['JetBrains_Mono'] font-bold leading-none mb-6">
        {intl.formatMessage({
          id: 'SignMessage.Sign-to-continue',
          defaultMessage: 'Sign the message in your wallet to continue',
        })}
      </h1>
      <p className="text-[16px] text-[color:var(--text-secondary)] mb-8">
        {intl.formatMessage({
          id: 'SignMessage.Sign-to-verify',
          defaultMessage: 'LuckySwap uses this signature to verify that you’re the owner of this Ethereum address.',
        })}
      </p>
      <div className="flex flex-col gap-3">
        <Button
          wide
          className={cn(
            'rounded',
            isSignProcess && styles['button-sign-process'],
          )}
          disabled={isSignProcess}
          onClick={handleSignMessage}
        >
          {!isSignProcess ? intl.formatMessage({
            id: 'SignMessage.Continue',
            defaultMessage: 'Continue',
          }) : (
            <>
              <ProcessingIcon />
              {' '}
              {intl.formatMessage({
                id: 'SignMessage.Sign',
                defaultMessage: 'Sign message in wallet',
              })}
            </>
          )}
        </Button>
        <Button
          wide
          variant="flat"
          className="text-white"
          onClick={disconnect}
        >
          {intl.formatMessage({
            id: 'SignMessage.Disconnect',
            defaultMessage: 'Disconnect',
          })}
        </Button>
      </div>
    </>
  )
})
