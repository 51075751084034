/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type GetActivityByUserPayload = {
    blockchain: GetActivityByUserPayload.blockchain;
    userAddress: string;
};

export namespace GetActivityByUserPayload {

    export enum blockchain {
        ETHEREUM = 'ethereum',
        SEPOLIA = 'sepolia',
        GOERLI = 'goerli',
    }


}

