import { autorun } from 'mobx'

import {
  LoginOrRegisterPayload, Profile, UserService,
} from '@/api/luckyswap'
import { EvmWalletService, useEvmWallet } from '@/stores/EvmWalletService'

import { ValueStore } from './util/ValueStore'


const MESSAGE = (
  // eslint-disable-next-line indent
`Welcome to LuckySwap!
This is a cross-chain NFT marketplace with a wide range of in-depth analytic tools.

Let us now conduct a test transaction to make sure that your MetaMask wallet is successfully connected to the marketplace.

By confirming the test transaction, you agree to the Privacy Policy and Terms of Use.`
)

const MESSAGE_EXPIRATION_TIME = 10 * 60_000

export class ProfileStore {
  public readonly profileData = new ValueStore<Profile | undefined>(
    undefined,
    async (_, { set }) => {
      set(undefined)
      const { isConnected, address, network } = useEvmWallet()
      if (!isConnected || !address) return

      const profile = await UserService.getUser(network?.value as any, address)
      set(profile)
    },
  )

  constructor(
    wallet: EvmWalletService,
  ) {
    autorun(() => {
      const {
        isConnected, isDisconnecting, address, disconnect, chainId,
      } = wallet

      if (!isConnected || !address) return

      if ((isConnected || chainId) && !isDisconnecting) {
        this.profileData.load({ skipIfLoaded: false })
      }
      else if (isDisconnecting) {
        disconnect()
        this.profileData.clear()
      }
    })
  }

  public async createProfile() {
    try {
      const { signMessage, address, network } = useEvmWallet()
      if (!address) return

      const signedMessage = await signMessage(
        `${MESSAGE}#${(Date.now() + MESSAGE_EXPIRATION_TIME)}`,
      )
      if (!signedMessage) return

      const payload: LoginOrRegisterPayload = {
        address: {
          address,
          blockchain: network?.value as any,
        },
        ...signedMessage,
      }
      await UserService.postUser(payload)
      this.profileData.load()
    }
    catch (e) {
      console.error('[createProfile] ERROR', e)
    }
  }
}
