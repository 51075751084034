import { arrayToMap } from '@/util/array-to-map'

export const QUERIES = arrayToMap([
  'blockchains',

  'saleType',
  'price_from',
  'price_to',

  'search',

  'collection',
  'attributes',

  'tab',
] as const)
