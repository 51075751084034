import {
  action, computed, makeObservable, observable,
} from 'mobx'

import { Currency, DictService } from '@/api/luckyswap'
import { EMPTY_ADR_40 } from '@/util/web3'

class CurrenciesStore {
  @observable public currencies: Currency[] = []
  private emptyCurrency = {
    address: '', symbol: '', decimals: 0, blockchain: '',
  }

  constructor() {
    makeObservable(this)
  }

  public async load() {
    const currencies = await DictService.getCurrency() || []
    this.currencies = currencies.map(cur => ({
      ...cur,
      // eslint-disable-next-line no-nested-ternary
      symbol: cur.symbol.toLowerCase().includes('sepolia') ? 'SETH'
        : cur.symbol.toLowerCase().includes('goerli') ? 'GETH' : cur.symbol,
    }))
  }

  @action.bound public getByAddress(tokenAddress: string) {
    return this.currencies.find(cur => cur.address === tokenAddress)
  }

  @computed get defaultCurrency(): Currency {
    return this.currencies.find(cur => cur.address === EMPTY_ADR_40) || this.emptyCurrency
  }
}

export const CurrenciesService = new CurrenciesStore()
