export enum Web3TxStatus {
  processing = 'tx-processing',
  completed = 'tx-completed',
  error = 'tx-error',
}

export const EMPTY_ADR_64 = '0x0000000000000000000000000000000000000000000000000000000000000000'
export const EMPTY_ADR_40 = '0x0000000000000000000000000000000000000000'

export const isEvmPageProviderInjected = () => !!window.ethereum
