/* eslint-disable consistent-return */
import { useEffect, useRef } from 'react'

import { CardsGrid, CardsGridProps } from '@/components/modules/CardsGrid'

type Props = CardsGridProps & {
  onLoadNeeded?: VoidFunction
  isLoading?: boolean
}

export function CardsGridLoadable({
  isLoading,
  onLoadNeeded,
  ...props
}: Props) {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return

    const observer = new IntersectionObserver(
      ([entry]) => {

        if (entry.intersectionRatio === 1) {
          onLoadNeeded?.()
        }

      },
    )

    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [])

  return (
    <div>
      <CardsGrid {...props} />

      <div ref={ref} />
    </div>
  )
}
