import BigNumber from 'bignumber.js'

import { formatNumberSuffix } from '@/util/format'

export type ReadableCurrency = {
  amount: string
  decimals: number
  symbol?: string
  round?: number
}

export const readableCurrency = ({
  amount, decimals, symbol, round,
}: ReadableCurrency) => {
  let result = formatNumberSuffix(new BigNumber(amount).shiftedBy(-decimals).toString(), round)
  if (symbol) result += ` ${symbol.toUpperCase()}`
  return result
}

export const calculatePercentAmount = (from: string, of: number) => new BigNumber(from).div(100).multipliedBy(of).toString()
