/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Collection } from '../models/Collection';
import type { CollectionFilter } from '../models/CollectionFilter';
import type { CollectionsPaginationResponse } from '../models/CollectionsPaginationResponse';

import type { CancelablePromise } from '@/api/util/CancelablePromise'
import { OpenAPI } from '@/api/util/OpenAPI'
import { request as __request } from '@/api/util/request'

export class CollectionsService {

    /**
     * @param body
     * @returns CollectionsPaginationResponse OK
     * @throws ApiError
     */
    public static postCollectionFilter(
        body: CollectionFilter,
    ): CancelablePromise<CollectionsPaginationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collection/filter',
            body: body,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param collectionSlug
     * @returns Collection OK
     * @throws ApiError
     */
    public static getCollection(
        collectionSlug: string,
    ): CancelablePromise<Collection> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collection/{collectionSlug}',
            path: {
                'collectionSlug': collectionSlug,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
