/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type NftFilter = {
    bidder?: string;
    blockchain: Array<'ethereum' | 'sepolia' | 'goerli'>;
    collection?: string;
    offerer?: string;
    onlyWithActiveBids?: boolean;
    onlyWithActiveOffers?: boolean;
    owner?: string;
    priceFrom?: number;
    priceTo?: number;
    skip: number;
    sortBy?: {
        price?: NftFilter.price;
        published?: NftFilter.published;
    };
    status: Array<'onSale' | 'onAuction' | 'notForSale'>;
    take: number;
    text?: string;
    traits: Array<{
        format: 'textual' | 'numerical';
        traitMaxValue?: number;
        traitMinValue?: number;
        traitType: string;
        traitValue?: string;
    }>;
};

export namespace NftFilter {

    export enum price {
        ASC = 'asc',
        DESC = 'desc',
    }

    export enum published {
        ASC = 'asc',
        DESC = 'desc',
    }


}

