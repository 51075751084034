export type nil = null | undefined

export type NotNil<T, N = undefined | null | void> = T extends N ? never : T

export function isNil(value: any): value is nil {
  if (value === undefined) return true
  if (value === null) return true

  return false
}
