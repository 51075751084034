import { useIntl } from 'react-intl'
import { useState } from 'react'

import { Block } from '@/components/common/Block'
import { Button } from '@/components/common/Button'
import { Delim } from '@/components/common/Delim'
import { ExternalLink } from '@/components/common/ExternalLink'
import { Input } from '@/components/common/Input'
import { SOCIAL_LINKS } from '@/constants'
import { ReactComponent as TwitterSVG } from '@/assets/twitter-white.svg'
import { ReactComponent as DiscordSVG } from '@/assets/discord-white.svg'
import { ReactComponent as TelegramSVG } from '@/assets/telegram-white.svg'
import { cn } from '@/util/exports'
import { Localization } from '@/components/common/Localization'
import { UserService } from '@/api/luckyswap'
import { isEmail } from '@/util/is-email'
import { useStores } from '@/hooks/useStores'

import style from './index.module.scss'


export function Footer() {
  const { profileStore } = useStores()
  const intl = useIntl()
  const [postAddress, setPostAddress] = useState<string>()
  const [message, setMessage] = useState(intl.formatMessage(
    {
      id: 'Footer.Im-in',
      defaultMessage: 'FAKE Подписаться',
    },
  ))

  const subscribe = () => {
    if (postAddress) {
      console.log(profileStore.profileData.$value, 'profileStore.profileData.$value?.address.address')
      UserService.postSubscribe({ email: postAddress })
      setMessage(intl.formatMessage({
        id: 'Footer.FAKE Succesfull',
        defaultMessage: 'FAKE Succesfull',
      }))
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'email_marketing',
        category: 'social',
        id: profileStore.profileData.$value?.address.address, // UserId
        email: postAddress, // email
        timestamp: new Date().getTime(),
      })
    }
  }

  return (
    <footer className={style.footer}>
      <div className={style.container}>
        <div className={style.column}>
          <header>
            {intl.formatMessage({
              id: 'Footer.Get-the-latest',
              defaultMessage: 'Get the latest',
            })}
            <br />
            {intl.formatMessage({
              id: 'Footer.Luckyswap-updates',
              defaultMessage: 'всех новостей',
            })}
          </header>

          <span className={cn('flex flex-wrap flex-row gap-4')}>
            <Block size="s">
              <Input
                variant="flat"
                placeholder="Email"
                className="w-64"
                onChange={e => setPostAddress(e.target.value.trim())}
              />
            </Block>
            <Button
              className="inline-block"
              disabled={postAddress ? !isEmail(postAddress) : false}
              onClick={subscribe}
            >
              {message}
            </Button>
          </span>
        </div>

        <div className={cn(style.columnright, 'items-top justify-evenly max-w-[500px]')}>
          <ExternalLink href={SOCIAL_LINKS.twitter}>
            <TwitterSVG />
            <p>
              {intl.formatMessage({
                id: 'Footer.Twitter',
                defaultMessage: 'Twitter',
              })}
            </p>
          </ExternalLink>
          <ExternalLink href={SOCIAL_LINKS.discord}>
            <DiscordSVG />
            <p>
              {intl.formatMessage({
                id: 'Footer.Discord',
                defaultMessage: 'Discord',
              })}
            </p>
          </ExternalLink>
          <ExternalLink href={SOCIAL_LINKS.telegram_channel}>
            <TelegramSVG />
            <p>
              {intl.formatMessage({
                id: 'Footer.Telegram',
                defaultMessage: 'Telegram',
              })}
            </p>
          </ExternalLink>
        </div>

      </div>

      <Delim variant="secondary" />


      <div className="flex flex-row flex-wrap-reverse justify-between gap-5 py-6">
        <Localization className={style.localization} />

        <span className="mr-auto opacity-60">
          {intl.formatMessage({
            id: 'Footer.copyrite',
            defaultMessage: '© Luckyswap, Inc. All rights reserved.',
          })}
        </span>

        <div className={cn(style.copyrite, 'flex flex-row flex-wrap gap-5 opacity-60')}>
          <ExternalLink href="https://luckyswap.com/termsofuse">
            {intl.formatMessage({
              id: 'Footer.Terms',
              defaultMessage: 'Terms',
            })}
          </ExternalLink>
          <ExternalLink href="https://luckyswap.com/privacypolicy">
            {intl.formatMessage({
              id: 'Footer.Privacy-policy',
              defaultMessage: 'Privacy policy',
            })}
          </ExternalLink>
        </div>
      </div>
    </footer>
  )
}

