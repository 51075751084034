import React from 'react'

import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'
import { ReactComponent as VerifiedSVG } from '@/assets/verified.svg'

import style from './style.module.scss'
import { MediaPreview } from '../../../common/MediaPreview'
import { Block } from '../../../common/Block'


export type CardMetaBlock = {
  title: string
  content: JSX.Element | string
}

export type CommonCardProps = {
  previewUrl: string
  iconUrl?: string

  isCollectionVerified?: boolean
  collectionName?: string
  title: JSX.Element | string
  text?: JSX.Element | string
  titleOrder?: 'title text' | 'text title' | 'title'

  blocks?: CardMetaBlock[]

  mediaClassName?: string
  iconClassName?: string

  isHorizontalLayout?: boolean
}
& ClassProp

export function CommonCard({
  previewUrl,
  iconUrl,

  isCollectionVerified,
  collectionName,
  title,
  text,
  titleOrder = 'title text',

  blocks,

  className,
  mediaClassName,
  iconClassName,
  isHorizontalLayout,
}:CommonCardProps) {

  function renderTitle() {
    return (
      <div className="flex items-center gap-1">
        <span
          className={cn(
            style.name,
            'text-[20px] font-normal font-["JetBrains_Mono"]',
            'max-w-full',
          )}
        >
          {title}
        </span>
      </div>
    )
  }

  function renderText() {
    return (
      <div
        className={cn(
          style.name,
          'text-[16px] font-normal text-[color:var(--text-secondary)]',
        )}
      >
        {text}
      </div>
    )
  }

  return (
    <div className={cn(style.body, className, isHorizontalLayout && style.horizontal)}>

      <div className={cn(style.media, mediaClassName)}>
        <MediaPreview url={previewUrl} />
      </div>

      <div style={{ width: isHorizontalLayout ? '28%' : 'unset' }}>
        {iconUrl && (
          <div className={cn(style.iconBox)}>
            <div className={cn(style.icon, iconClassName)}>
              <MediaPreview url={iconUrl} />
            </div>
          </div>
        )}

        <div className={style.data}>
          <div
            className={cn('flex flex-col flex-nowrap gap-[5px]')}
          >
            {collectionName && (
              <span
                className="flex flex-nowrap justify-start items-center gap-[5px]"
              >
                <span className="min-w-0 whitespace-nowrap text-[color:var(--text-secondary)] overflow-hidden text-ellipsis max-w-4/5">{collectionName}</span>
                {isCollectionVerified && <span><VerifiedSVG /></span>}
              </span>
            )}
            {titleOrder === 'title text' && (
              <>
                {renderTitle()}
                {renderText()}
              </>
            )}

            {titleOrder === 'text title' && (
              <>
                {renderText()}
                {renderTitle()}
              </>
            )}

            {titleOrder === 'title' && (
              <>
                {renderTitle()}
              </>
            )}
          </div>

          {!!blocks?.length && (
            <div className="flex flex-row flex-wrap gap-[5px]">
              {blocks?.map((block, index) => (
                <Block
                  key={index}
                  size="s"
                  lvl={2}
                  className={style.block}
                >
                  <div className="text-[14px] text-[color:var(--text-secondary)]">
                    {block.title}
                  </div>

                  <div className="font-['JetBrains_Mono']">
                    {block.content}
                  </div>
                </Block>
              ))}
            </div>
          )}
        </div>
      </div>

    </div>
  )
}
