import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { PageLayout } from '@/components/layout/PageLayout'
import { Header } from '@/components/modules/Header'
import { Footer } from '@/components/modules/Footer'
import { ReactComponent as ErrorImage } from '@/assets/404.svg'
import { Button } from '@/components/common/Button'

import style from './style.module.scss'


export default function Erro404Page() {

  const intl = useIntl()

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Body>
          <Header />
        </PageLayout.Body>
      </PageLayout.Header>

      <PageLayout.Body>
        <div className={style.body}>
          <ErrorImage />
          <p>
            {
              intl.formatMessage(
                {
                  id: 'Error404Page.page_doesnt-exist',
                  defaultMessage: 'This page doesn\'t seem to exist',
                },
              )
            }
          </p>
          <div className={style.aside}>
            <p>
              {
                intl.formatMessage(
                  {
                    id: 'Error404Page.message',
                    defaultMessage: 'Unless you were looking for the page with the fallen four. In that case it clearly exists and you definitely found it.',
                  },
                )
              }
            </p>
            <Link to="/" className="flex">
              <Button>
                {
                  intl.formatMessage(
                    {
                      id: 'Error404Page.Time-to-go-Home',
                      defaultMessage: 'Time to go Home',
                    },
                  )
                }
              </Button>
            </Link>
          </div>
        </div>
      </PageLayout.Body>

      <PageLayout.Footer>
        <Footer />
      </PageLayout.Footer>
    </PageLayout>
  )
}
