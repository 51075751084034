/* eslint-disable no-restricted-syntax */


import { NftFilter, NftService } from '@/api/luckyswap'

import { FilterableStore } from './util/FilterableStore'

export class CollectionPageStore {
  public readonly collectionNfts = new FilterableStore(NftService.postNftFilter)

  public async loadCollectionNfts(req: Omit<NftFilter, 'skip' | 'take'>) {
    await this.collectionNfts.load(req)
  }

  public reset() {
    this.collectionNfts.$items = []
    this.collectionNfts.$itemsTotal = 0
  }
}
