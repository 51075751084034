/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginOrRegisterPayload } from '../models/LoginOrRegisterPayload';
import type { LoginOrRegisterResponse } from '../models/LoginOrRegisterResponse';
import type { Profile } from '../models/Profile';
import type { SubscribePayload } from '../models/SubscribePayload';

import type { CancelablePromise } from '@/api/util/CancelablePromise'
import { OpenAPI } from '@/api/util/OpenAPI'
import { request as __request } from '@/api/util/request'

export class UserService {

    /**
     * @param body
     * @returns void
     * @throws ApiError
     */
    public static postSubscribe(
        body: SubscribePayload,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/subscribe',
            body: body,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param body
     * @returns LoginOrRegisterResponse OK
     * @throws ApiError
     */
    public static postUser(
        body: LoginOrRegisterPayload,
    ): CancelablePromise<LoginOrRegisterResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user',
            body: body,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param blockchain
     * @param userAddress
     * @returns Profile OK
     * @throws ApiError
     */
    public static getUser(
        blockchain: 'ethereum' | 'sepolia' | 'goerli',
        userAddress: string,
    ): CancelablePromise<Profile> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/{blockchain}/user/{user_address}',
            path: {
                'blockchain': blockchain,
                'user_address': userAddress,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }   
}
