import { observer } from 'mobx-react-lite'
import { Link, useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import cn from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { Avatar } from '@/components/common/Avatar'
import { useStores } from '@/hooks/useStores'
import { ellipsis } from '@/util/ellipsis'
import { useEvmWallet } from '@/stores/EvmWalletService'
import { readableCurrency } from '@/util/convert'
import { ReactComponent as EthLogoSVG } from '@/assets/eth-logo.svg'
import { ReactComponent as MetamaskSVG } from '@/assets/metamask.icon.svg'
import { ReactComponent as DisconnectSVG } from '@/assets/disconnect.svg'
import { ReactComponent as CloseSVG } from '@/assets/close.svg'
import { Button } from '@/components/common/Button'
import { Block } from '@/components/common/Block'

export const ProfileOverview = observer(() => {
  const profileRef = useRef<HTMLDivElement | null>(null)
  const {
    balance, coin, disconnect, network, address, isMetaMask,
  } = useEvmWallet()
  const { profileStore: { profileData }} = useStores()
  const intl = useIntl()
  const navigate = useNavigate()

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    const onClick = ({ target }: any) => {
      if (profileRef && profileRef.current && !profileRef.current.contains(target)) {
        setIsOpen(false)
      }
      return !profileRef.current?.contains(target)
    }
    document.addEventListener('click', onClick)
    return () => document.removeEventListener('click', onClick)
  }, [])

  useEffect(() => {
    function handleEscapeKey(event: KeyboardEvent) {
      if (event.code === 'Escape') {
        setIsOpen(false)
      }
    }
    document.addEventListener('keydown', handleEscapeKey)
    return () => document.removeEventListener('keydown', handleEscapeKey)
  }, [])

  const sendEvent = (eventName: string) => {
    setIsOpen(false)
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: eventName,
      category: 'page_view',
      id: profileData.$value!.address.address, // UserId
      timestamp: new Date().getTime(),
    })
  }

  if (!profileData.$value) return null
  return (
    <div className="md:relative" ref={profileRef}>
      <Button
        onClick={() => setIsOpen(!isOpen)} variant="flat"
        className="flex"
      >
        <Avatar className="w-[56px] h-[56px]" avatarImage={profileData.$value?.icon?.url} />
      </Button>

      {isOpen && (
        <div
          className={cn(
            'absolute md:block md:w-[300px] md:h-auto bg-[color:var(--bg-1)] right-0 rounded px-3 py-4',
            'md:top-auto top-0 w-screen h-screen flex flex-col',
            isOpen && 'block z-10',
          )}
        >
          <div
            className="flex gap-3 items-center mb-12 cursor-pointer hover:text-[color:var(--active)]"
            onClick={() => {
              sendEvent('profile')
              navigate(`/market/user/${profileData.$value?.address?.address}?tab=myItems`)
            }}
          >
            <Avatar
              avatarImage={profileData.$value?.icon.url}
              className="!w-[48px] !h-[48px] !md:w-[32px] !md:h-[32px] !border-[2px]"
            />
            <span>
              {profileData.$value && ellipsis(profileData.$value?.address?.address, 5, 5)}
            </span>
            <Button onClick={() => setIsOpen(!isOpen)} variant="flat" className="block md:hidden ml-auto">
              <CloseSVG />
            </Button>
          </div>

          <div className="flex flex-col gap-4">
            <span className='font-["JetBrains_Mono"] text-sm mb-2.5 text-[color:var(--text-secondary)]'>
              MY PROFILE
            </span>
            <Link
              className="text-4xl md:text-2xl hover:text-[color:var(--active)]"
              to={`/market/user/${profileData.$value?.address?.address}?tab=myItems`}
              onClick={() => sendEvent('profile')}
            >
              {intl.formatMessage({ id: 'ProfilePage.My-items', defaultMessage: 'My items' })}
            </Link>
            <Link
              className="text-4xl md:text-2xl hover:text-[color:var(--active)]"
              onClick={() => sendEvent('profile')}
              to={`/market/user/${profileData.$value?.address?.address}?tab=bidsReceive`}
            >
              {
                intl.formatMessage({
                  id: 'ProfilePage.Bids-receive',
                  defaultMessage: 'Bids{space}receive',
                }, { space: <>&nbsp;</> })
              }
            </Link>
            <Link
              className="text-4xl md:text-2xl hover:text-[color:var(--active)]"
              onClick={() => sendEvent('profile')}
              to={`/market/user/${profileData.$value?.address?.address}?tab=offerReceive`}
            >
              {
                intl.formatMessage({
                  id: 'ProfilePage.Offer-receive',
                  defaultMessage: 'Offer{space}receive',
                }, { space: <>&nbsp;</> })
              }
            </Link>
          </div>

          <div className="mt-auto md:mt-0">
            <span className='block font-["JetBrains_Mono"] text-sm text-[color:var(--text-secondary)] mt-12 mb-6'>
              CONNECTED WALLET
            </span>

            <Block lvl={2} size="s" className="flex flex-col gap-6 rounded p-3">
              <div className="flex gap-2">
                {isMetaMask ? <MetamaskSVG className="w-[40px] h-auto" /> : <EthLogoSVG className="w-[40px] h-auto" />}
                <div className="mr-auto flex flex-col justify-between">
                  <span className="">
                    {network?.label}
                  </span>
                  <span className="text-xs text-[color:var(--text-secondary)]">
                    {ellipsis(address || '', 5, 3)}
                  </span>
                </div>
                <Button onClick={disconnect} variant="secondary" className="flex flex-col items-center w-[40px] h-[40px]">
                  <DisconnectSVG />
                </Button>
              </div>

              <span className='font-["JetBrains_Mono"] text-3xl'>
                {readableCurrency({
                  amount: balance || '0',
                  decimals: coin.decimals,
                  symbol: coin.symbol.slice(0, 7),
                })}
              </span>
            </Block>
          </div>
        </div>
      )}
    </div>
  )
})
