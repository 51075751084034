/* eslint-disable no-restricted-syntax */
import axios from 'axios'
import { makeObservable, observable } from 'mobx'

type SymbolResponse = {
  'symbol': string,
  'price': string
}

function mapTicker(ticker: string) {
  ticker = ticker.toLowerCase()

  if (ticker === 'sepoliaeth' || ticker === 'goerlieth') return 'eth'

  return ticker
}

function makeUSDTSymbol(ticker: string) {
  ticker = ticker.toUpperCase()
  return `${ticker}USDT`
}

function makeReqUrl(tickers: string[]) {
  const symbols = tickers
    .map(ticker => makeUSDTSymbol(ticker))
    .map(symbol => `"${symbol}"`)
    .join(',')

  return `https://api.binance.com/api/v3/ticker/price?symbols=[${symbols}]`
}

class _PriceService {
  /** map like: `{ ETH: 123, ... }` */
  @observable public $prices: Record<string, number> = {}

  constructor() {
    makeObservable(this)

    setInterval(async () => {
      const tickers = Object.keys(this.$prices)

      if (tickers.length === 0) return

      try {
        const { data } = await axios.get<SymbolResponse[]>(
          makeReqUrl(
            Array.from(
              new Set(
                tickers.map(mapTicker),
              ),
            ),
          ),
        )

        for (const ticker of tickers) {
          const symbol = makeUSDTSymbol(
            mapTicker(ticker),
          )

          for (const resp of data) {
            if (symbol.toUpperCase() === resp.symbol.toUpperCase()) {
              this.$prices[ticker] = Number(resp.price)
              break
            }
          }
        }
      }
      catch (err) {
        console.warn(err)
      }

    }, 10000)
  }

  public require(ticker: string) {
    if (!ticker) return

    ticker = ticker.toUpperCase()

    if (ticker in this.$prices) return

    this.$prices[ticker] = 0
  }

  public delete(ticker: string) {
    ticker = ticker.toUpperCase()

    delete this.$prices[ticker]
  }
}

export const PriceService = new _PriceService()
