import { DateTime } from 'luxon'

import { NftModel } from '@/api/stubs/models'
import { useEvmWallet } from '@/stores/EvmWalletService'

import { nil } from './nil'

export function getNFTPermissions(nft: NftModel | nil) {
  const { address } = useEvmWallet()

  const nftOwnerAddress = nft?.owner.address.address

  const isOwner = address && nftOwnerAddress
    ? address.toLowerCase() === nftOwnerAddress.toLowerCase()
    : false

  const sell = nft?.sell
  const auction = nft?.auction

  const isAuctionEnded = auction
    ? DateTime.now() > DateTime.fromISO(auction.endTime)
    : false

  const isBidsExists = !!auction?.bidsCount

  let canCancelAuction = false
  if (auction) {
    if (isBidsExists) canCancelAuction = isAuctionEnded
    else canCancelAuction = isOwner
  }

  return {
    canDirectSell: isOwner && !sell,
    canDirectBuy: !isOwner && !!sell,
    canCancelDirectSell: isOwner && !!sell,

    canPutOnAuction: isOwner && !auction,
    canCancelAuction,
    canPlaceBid: !isOwner && !!auction && !isAuctionEnded,

    canMakeOffer: !isOwner && !auction,
    canAcceptOffer: isOwner && !auction,
  }
}
