import { observer } from 'mobx-react-lite'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'

import { Button } from '@/components/common/Button'
import { useEvmWallet } from '@/stores/EvmWalletService'
import LockedIcon from '@/assets/lock.icon.svg'
import { INSTALL_WALLET_LINKS } from '@/constants'
import { isEvmPageProviderInjected } from '@/util/web3'
import { useStores } from '@/hooks/useStores'
import { BlockchainRecord, DictService } from '@/api/luckyswap'

import { WALLETS_LIST } from '../../wallets-list'
import styles from './styles.module.scss'

export const ConnectWallet = observer(() => {
  const intl = useIntl()

  const { profileStore: { profileData }} = useStores()
  const { connect, isConnected } = useEvmWallet()
  const [blockchainList, setBlockchainList] = useState<BlockchainRecord[]>([])
  const [getWalletType, setWalletType] = useState<string | null>(null)

  const handleConnect = (type: string) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'connect_step_2',
      category: 'wallet',
      wallet_type: type, // wallet type
      timestamp: new Date().getTime(),
    })
    if (type === 'metamask' && !isEvmPageProviderInjected()) {
      window.open(INSTALL_WALLET_LINKS.metamask, '_blank')
      return
    }
    setWalletType(type)
    connect(type)
  }

  useEffect(() => {
    if (isConnected && !profileData.$error) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'connect_step_3',
        category: 'wallet',
        id: profileData.$value?.address.address, // UserId
        wallet_type: getWalletType, // wallet type
        wallet_number: profileData.$value?.address.address, // wallet number
        timestamp: new Date().getTime(),
      })
    }
  }, [isConnected])

  useEffect(() => {
    if (blockchainList.length) return
    DictService.getBlockchain().then(setBlockchainList)
  }, [intl])

  if ((isConnected && !profileData.$error) || (profileData.$error)) return null

  return (
    <>
      <h1 className="text-[48px] font-['JetBrains_Mono'] font-bold mb-6">
        {intl.formatMessage({
          id: 'ConnectWallet.Connect-Wallet',
          defaultMessage: 'Connect Wallet',
        })}
      </h1>
      <p className="text-[16px] text-[color:var(--text-secondary)]">
        {intl.formatMessage({
          id: 'ConnectWallet.Choose-how-you-want-to-connect.',
          defaultMessage: 'Choose how you want to connect.',
        })}
        <br />
        {intl.formatMessage({
          id: 'ConnectWallet.There-several-wallet-services',
          defaultMessage: 'There several wallet services.',
        })}
      </p>
      <div className="flex flex-col">
        <h3 className='text-[18px] font-["JetBrains_Mono"] mt-10 mb-6'>
          {intl.formatMessage({
            id: 'ConnectWallet.Ethereum',
            defaultMessage: 'Ethereum',
          })}
        </h3>
        {WALLETS_LIST.map(({
          icon, title, type, disabled,
        }) => !disabled
          && (
            <Button
              wide
              key={title}
              onClick={() => handleConnect(type)}
              className={styles.button}
              nftBlockchains={type === 'metamask' ? blockchainList[0]?.blockchain : undefined}
              isWalletRequired={type === 'metamask'}
              isLoginButton
            >
              <img src={icon} alt={title} />
              {title}
            </Button>
          ))}

        <h3 className='text-[18px] font-["JetBrains_Mono"] mt-10 mb-6'>
          {intl.formatMessage({
            id: 'ConnectWallet.Coming-soon',
            defaultMessage: 'Coming soon',
          })}
        </h3>

        {WALLETS_LIST.map(({ icon, title, disabled }) => disabled && (
          <Button
            key={title}
            wide
            disabled={disabled}
            className={styles.button}
          >
            <img src={icon} alt={title} />
            {title}
            <img src={LockedIcon} alt="locked" />
          </Button>
        ))}
      </div>
    </>
  )
})
