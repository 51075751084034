import { PropsWithChildren, useEffect } from 'react'
import cn from 'classnames'
import { isMobile } from 'web3modal'

import { Block } from '@/components/common/Block'
import { ReactComponent as CloseSVG } from '@/assets/close.svg'

import style from './style.module.scss'


export type ModalLayoutProps = {
  onClose?: VoidFunction
}

export function ModalLayout({ onClose, children }: PropsWithChildren<ModalLayoutProps>) {
  useEffect(() => {
    const closeModal = (event: KeyboardEvent) => event.key === 'Escape' && onClose?.()
    document.addEventListener('keydown', closeModal, false)
    return () => document.removeEventListener('keydown', closeModal, false)
  }, [onClose])

  return (
    <div
      className="w-full min-h-full flex justify-center items-center overflow-auto"
    >
      <div
        className={cn('relative', isMobile() && 'mx-3')}
      >
        <div className={style.close} onClick={onClose}>
          <Block
            lvl={isMobile() ? 2 : 1}
            size="xs"
            className={cn('absolute', isMobile() ? 'right-3 top-3 z-10' : 'left-[calc(100%+10px)]')}
          >
            <CloseSVG />
          </Block>
        </div>
        {children}
      </div>
    </div>
  )
}
