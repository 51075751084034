import { action, makeObservable, observable } from 'mobx'
import { AbiItem } from 'web3-utils'

import { EventService, EventStatus, Nft } from '@/api/luckyswap'
import { NftWeb3Service } from '@/api/web3/services/NftWeb3Service'
import { IDisposableMobx } from '@/interfaces/disposable-mobx.intf'
import { Web3TxStatus } from '@/util/web3'
import { useEvmWallet } from '@/stores/EvmWalletService'
import { CurrencyAndTotal } from '@/api/stubs/money'
import BasicTokenAbi from '@/abi/BasicToken.json'
import { BasicTokenTypo } from '@/abi/types'
import { ENV } from '@/util/env'
import { delay } from '@/util/promise'

export enum DirectSellNftStage {
  checkout,
  approved,
  confirmation,
  confirmed,
  error,
}

export class DirectSellNftFlowStore implements IDisposableMobx {

  @observable public $stage = DirectSellNftStage.checkout

  @observable public $txStatus: Web3TxStatus | null = null
  @observable public $txHash = ''

  @observable public $disposed = false

  constructor(public readonly nft: Nft) {
    makeObservable(this)
  }

  @action.bound public async confirm(price: CurrencyAndTotal) {
    this.$stage = DirectSellNftStage.confirmation
    const { id, collection } = this.nft
    const { web3, address } = useEvmWallet()

    if (!price || !web3) {
      console.error('BuyNftFlowStore buyNFT: cant buy, price is nil')
      this.$stage = DirectSellNftStage.error
      return
    }

    try {
      const basicTokenContract = new web3.eth.Contract(
        BasicTokenAbi.abi as unknown as AbiItem,
        collection.addresses[0].address,
      ) as unknown as BasicTokenTypo

      await basicTokenContract.methods
        .approve(ENV.PROXY_CONTRACT_ADR, id)
        .send({ from: address })

      this.$stage = DirectSellNftStage.approved

      const [prome] = NftWeb3Service.directSaleItem(
        collection.addresses[0].address,
        id,
        price,
      )

      if (!prome) {
        console.error('SellNftFlowStore buyNFT: cant sell, cuz prome is nil')
        this.$stage = DirectSellNftStage.error
        return
      }

      this.$txStatus = Web3TxStatus.processing
      this.$stage = DirectSellNftStage.confirmation
      await prome.once('transactionHash', this.transactionListener)
    }
    catch (err) {
      console.error('SellNftFlowStore', err)
      this.$stage = DirectSellNftStage.error
      this.$txStatus = Web3TxStatus.error
    }
  }

  @action.bound public dispose() {
    this.$disposed = true
  }

  @action.bound private async transactionListener(txHash: string) {
    if (!this.$txHash) this.$txHash = txHash
    const txStatus = await EventService.getEventStatus(txHash)

    if (txStatus === EventStatus.COMPLETED) {
      this.$txStatus = Web3TxStatus.completed
      this.$stage = DirectSellNftStage.confirmed
    }
    else {
      await delay(1000)
      this.transactionListener(txHash)
    }
  }
}
