
import React, { PropsWithChildren } from 'react'
import { useIntl } from 'react-intl'

import { Modal } from '@/components/common/Modal'
import { ModalLayout, ModalLayoutProps } from '@/components/layout/ModalLayout'
import { Block } from '@/components/common/Block'
import { ModalLayoutHeader } from '@/components/layout/ModalLayout/Header/ModalLayoutHeader'

type Props = {
}
& Pick<ModalLayoutProps, 'onClose'>

export function FollowStepsModal({
  onClose,
  children,
}: PropsWithChildren<Props>) {

  const intl = useIntl()

  return (
    <Modal>
      <ModalLayout onClose={onClose}>
        <Block className="max-w-md">
          <ModalLayoutHeader>
            {intl.formatMessage({
              id: 'FollowStepsModal.Follow-steps',
              defaultMessage: 'Follow steps',
            })}
          </ModalLayoutHeader>

          <div className="flex flex-col flex-nowrap gap-2">
            {children}
          </div>

        </Block>
      </ModalLayout>
    </Modal>
  )
}
