import {
  PropsWithChildren, useRef,
} from 'react'

import { ClassProp } from '@/util/react-props'
import { cn } from '@/util/exports'

import style from './index.module.scss'

type Props = ClassProp & {
}

export function PageLayoutHeader({
  children,
  className,
}: PropsWithChildren<Props>) {
  const ref = useRef<HTMLHeadElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  return (
    <header
      ref={ref}
      className={cn(style.header)}
    >
      <div
        ref={contentRef}
        className={cn(style.content, className)}
      >
        {children}
      </div>
    </header>
  )
}
