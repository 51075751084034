/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react'

export type TabsCtx = {
  selectedId: string
  setTab: (id: string) => void
}

export const TabsCtx = createContext<TabsCtx>({
  selectedId: '',
  setTab: () => { },
})
