
import { cn } from '@/util/exports'
import { Price } from '@/components/common/Price'

type Props = {
  icon?: JSX.Element
  title: string | JSX.Element
  price: { address: string; total: string }
  text?: string | JSX.Element
  rowButton?: JSX.Element
}

export function InteractionListItem({
  icon,
  title,
  price,
  text,
  rowButton,
}: Props) {
  return (
    <div className="flex flex-row flex-nowrap justify-between p-[11px]">
      <div className={cn(icon && 'flex items-center gap-2')}>
        {icon}
        <div className="flex flex-col justify-center items-start w-fit">
          <div className="text-base">{title}</div>
          {text && (
            <div className="text-sm text-[color:var(--text-secondary)]">
              {text}
            </div>
          )}
        </div>
      </div>

      <div className={cn('font-["JetBrains_Mono"]', rowButton && 'ml-auto')}>
        <div className="text-right">
          <Price
            fromUnit={price}
            round={7}
            showSymbol
          />
        </div>
        <div className="text-sm text-[color:var(--text-secondary)] text-right">
          $
          <Price fromUnit={price} to="usdt" />
        </div>
      </div>

      {rowButton}
    </div>
  )
}
