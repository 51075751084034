import { useIntl } from 'react-intl'

import { cn } from '@/util/exports'

import style from './style.module.scss'


export default function Banner() {

  const intl = useIntl()

  return (
    <div className={cn(style.banner, 'my-7 flex flex-col justify-center items-center')}>
      <p className={cn(style.title, 'md:text-5xl lg:text-7xl font-extrabold mb-5 max-w-[850px]')}>
        {
          intl.formatMessage(
            {
              id: 'PromoNFTBlock.we-here',
              defaultMessage: 'Мы запустили маркет',
            },
            {
              bring: <br />,
            },
          )
        }
      </p>
      <p className={cn(style.text, 'md:text-xl lg:text-2xl text-center')}>
        {
          intl.formatMessage(
            {
              id: 'PromoNFTBlock.we-here2',
              defaultMessage: 'и собрали подборку самых горячих коллекций прямо здесь',
            },
            {
              bring: <br />,
            },
          )
        }
      </p>
    </div>
  )
}
