import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'
import { useIntl } from 'react-intl'

import { Button } from '@/components/common/Button'
import { useStores } from '@/hooks/useStores'

import { ProfileOverview } from './ProfileOverview'

export const ConnectWalletButton = observer(() => {
  const navigate = useNavigate()
  const { profileStore: { profileData }} = useStores()
  const intl = useIntl()

  return (
    <>
      {!profileData.$valueExists && (
        <Button
          className='text-[16px] leading-none rounded font-["JetBrains_Mono"]'
          onClick={() => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'connect_step_1',
              category: 'wallet',
              timestamp: new Date().getTime(),
            })
            navigate('/authentication')
          }}
        >
          {intl.formatMessage({
            id: 'ConnectWallet.Connect-Wallet',
            defaultMessage: 'Подключить кошелёк',
          })}
        </Button>
      )}
      <ProfileOverview />
    </>
  )
})
