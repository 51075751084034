import { action, makeObservable, observable } from 'mobx'

interface IPaginableReq {
  skip: number
  take: number
}

interface IPaginableResp<T> {
  skip: number
  total: number
  items: Array<T>
}

const PAGE_SIZE = 9

export class FilterableStore<
  REQ extends IPaginableReq,
  T
> {
  @observable public $lastReq: REQ | undefined
  @observable public $items: T[] = []
  @observable public $itemsTotal = 0

  constructor(
    private readonly loader: (req: REQ) => Promise<IPaginableResp<T>>,
  ) {
    makeObservable(this)
  }

  @action.bound public async load(req: Omit<REQ, 'skip' | 'take'> & Partial<IPaginableReq>) {
    this.$lastReq = {
      ...req,

      skip: 0,
      take: PAGE_SIZE,
    } as REQ

    const resp = await this.loader(this.$lastReq)

    this.$items = resp.items
    this.$itemsTotal = resp.total
  }

  @action.bound public async loadMore() {
    if (this.$items.length === 0) return

    if (!this.$lastReq) {
      console.warn('Cant load next page, cuz 1st load missing')
      return
    }

    const nextSkip = this.$lastReq.skip + PAGE_SIZE

    this.$lastReq = {
      ...this.$lastReq,
      skip: nextSkip,
      take: PAGE_SIZE,
    }

    const resp = await this.loader(this.$lastReq)

    this.$items = [...this.$items, ...resp.items]
    this.$itemsTotal = resp.total
  }

  @action.bound public async reload() {
    if (!this.$lastReq) {
      console.warn('Cant reload, cuz 1st load missing')
      return
    }

    await this.load(this.$lastReq)
  }
}
