import { PropsWithChildren, useEffect, useState } from 'react'

import { ClassProp } from '@/util/react-props'
import { cn } from '@/util/exports'
import { ReactComponent as ArrowUpward } from '@/assets/arrow_upward.svg'

import style from './style.module.scss'

type Props = ClassProp

export function ScrollToTop({
  className,
}: PropsWithChildren<Props>) {
  const [showScroll, setShowScroll] = useState(false)

  const scrollTop = () => {
    document.getElementById('layout')?.scroll({ behavior: 'smooth', top: 0, left: 0 })
  }

  useEffect(() => {
    function checkScrollTop() {
      const elem = document.getElementById('layout')
      if (elem && elem?.scrollTop > 500) {
        setShowScroll(true)
      }
      if (elem && elem?.scrollTop <= 500) {
        setShowScroll(false)
      }
    }
    window.addEventListener('scroll', checkScrollTop, true)
    checkScrollTop()
    return () => window.removeEventListener('scroll', checkScrollTop, true)
  }, [])

  if (!showScroll) return null

  return (
    <div
      onClick={scrollTop}
      className={cn(className, showScroll ? style.scrollTop : style.hide)}
    >
      <ArrowUpward />
    </div>
  )
}
