import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { Input } from '@/components/common/Input'
import { Button } from '@/components/common/Button'
import { ClassProp } from '@/util/react-props'
import { cn } from '@/util/exports'
import { Block } from '@/components/common/Block'
import { ReactComponent as CloseSVG } from '@/assets/close.svg'
import { CollectionsFilterFormStore } from '@/stores/CollectionsFilterForm.store'

import { CollectionsFormItem } from './CollectionsFormItem'
import { useIntl } from 'react-intl'


export type CollectionsFormShape = string

type Props = {
  store: CollectionsFilterFormStore

  selectedId: string
  onSelect: (selectedId: string) => void
}
& ClassProp

export const CollectionsForm = observer(
  ({
    store,
    selectedId,
    onSelect,

    className,
  }: Props) => {
    const intl = useIntl();

    const items = store.collection.$items

    const [search, setSearch] = useState('')

    useEffect(() => {
      store.collection.load({
        name: search || undefined,
      })
    }, [store, search])

    function handleLoadMore() {
      store.collection.loadMore()
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      onSelect(e.target.value)
    }

    function handleClearSelected() {
      onSelect('')
    }

    const selectedItem = items.find(
      item => item.slug === selectedId,
    )

    return (
      <div
        className={cn(
          'flex flex-col flex-nowrap gap-3 max-h-full',
          className,
        )}
      >
        {selectedItem ? (
          <div className="contents cursor-pointer" onClick={handleClearSelected}>
            <Block
              size="xs"
              className="flex flex-row flex-nowrap items-center gap-3"
            >
              <CollectionsFormItem
                data={selectedItem}
              />

              <CloseSVG />
            </Block>
          </div>
        ) : (
          <>
            <Input
              wide
              placeholder={intl.formatMessage({
                id: "CollectionsForm.Search-by-Collections",
                defaultMessage: "Search by Collections"
              })}
              value={search}
              onChangeText={setSearch}
            />

            <div className="overflow-auto w-full h-full">
              <div className="flex flex-col flex-nowrap gap-2 w-full">
                {items.map(item => (
                  <label key={item.slug} className="cursor-pointer">
                    <input
                      type="radio"
                      checked={selectedId === item.slug}
                      onChange={handleChange}
                      value={item.slug}
                      className="hidden"
                    />

                    <CollectionsFormItem data={item} />
                  </label>
                ))}
              </div>
            </div>

            <Button wide onClick={handleLoadMore}>
              {intl.formatMessage({
                id: "CollectionsForm.Load-More",
                defaultMessage: "Load More"
              })}
            </Button>
          </>
        )}

      </div>
    )
  },
)
