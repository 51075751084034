import { observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { useIntl } from 'react-intl'

import { Nft } from '@/api/luckyswap'
import { Block } from '@/components/common/Block'
import { Button } from '@/components/common/Button'
import { Modal } from '@/components/common/Modal'
import { useEvmWallet } from '@/stores/EvmWalletService'
import { ReactComponent as EthLogoSVG } from '@/assets/eth-logo.svg'
import { ellipsis } from '@/util/ellipsis'
import { ModalLayout, ModalLayoutProps } from '@/components/layout/ModalLayout'
import { ModalLayoutHeader } from '@/components/layout/ModalLayout/Header/ModalLayoutHeader'
import { CurrencyAndTotal } from '@/api/stubs/money'
import { CurrenciesService } from '@/stores/Currencies.store'

import { FlowModalRow } from '../../components/FlowModalRow'


type Props = {
  nft: Nft
  onConfirm: VoidFunction
  __forceSufficientFunds?: boolean
}
& Pick<ModalLayoutProps, 'onClose'>

export const CheckoutModal = observer(
  ({
    nft,
    onConfirm,
    onClose,
    __forceSufficientFunds,
  }: Props) => {

    const intl = useIntl()

    const {
      network,
      address,
      isConnected,
      balanceOfErc20Token,
      balance: userBalance,
      getBalanceOfErc20Token,
    } = useEvmWallet()
    const { getByAddress } = CurrenciesService

    useEffect(() => {
      if (nft.sell) getBalanceOfErc20Token(nft.sell.price.address)
    }, [nft.sell])

    const balance = useMemo<CurrencyAndTotal | null>(() => {
      if (!nft.sell) return null
      const tokenDetails = getByAddress(nft.sell.price.address)
      if (!tokenDetails) return null
      return {
        address: tokenDetails.address,
        blockchain: tokenDetails.blockchain,
        total: balanceOfErc20Token || userBalance || '0',
        symbol: tokenDetails.symbol,
        decimals: tokenDetails.decimals,
      }
    }, [balanceOfErc20Token, userBalance])

    const isEnoughFunds = (
      __forceSufficientFunds
      // TODO add fee to calculations
      // || Number(fee.total) + Number(balance.total) >= Number(nft.sell?.price.total)
      || new BigNumber(balance?.total || 0).isGreaterThanOrEqualTo(nft.sell?.price.total || 0)
    )

    const handleCheckout = () => onConfirm()

    const renderButton = () => {
      if (!isEnoughFunds) {
        return (
          <Button disabled wide>
            {intl.formatMessage({
              id: 'CheckoutModal.Insufficient-funds',
              defaultMessage: 'Insufficient funds',
            })}
          </Button>
        )
      }
      return (
        <Button
          wide
          isWalletRequired
          nftBlockchains={nft.address.blockchain}
          onClick={handleCheckout}
        >
          {intl.formatMessage({
            id: 'CheckoutModal.Confirm',
            defaultMessage: 'Confirm',
          })}
        </Button>
      )
    }

    return (
      <Modal>
        <ModalLayout onClose={onClose}>
          <Block className="max-w-md">
            <ModalLayoutHeader>
              {intl.formatMessage({
                id: 'CheckoutModal.checkout',
                defaultMessage: 'Checkout',
              })}

            </ModalLayoutHeader>

            <div className="mb-6 text-[color:var(--text-secondary)]">
              {intl.formatMessage({
                id: 'CheckoutModal.purchase',
                defaultMessage: 'You are about to purchase a',
              })}

              {' '}
              <span className="text-[color:var(--active)]">
                {`${nft.name} ("${nft.collection.name}" ${intl.formatMessage({
                  id: 'Common.collection',
                  defaultMessage: 'collection',
                })})`}
              </span>
            </div>

            <Block
              className="flex flex-row flex-nowrap items-center mb-4"
              size="s"
            >

              {isConnected && (
                <>
                  {/* TODO switch logo with network */}
                  <EthLogoSVG />

                  <div className="mr-auto">
                    <div>
                      {ellipsis(address || '', 5, 3)}
                    </div>
                    <div className="text-[color:var(--text-secondary)]">
                      {network?.label}
                    </div>
                  </div>
                </>
              )}

              <Block size="xs">
                <span className={`text-[color:var(${isConnected ? '--active' : '--error'})]`}>
                  {isConnected ? intl.formatMessage({
                    id: 'Common.connected',
                    defaultMessage: 'Connected',
                  }) : intl.formatMessage({
                    id: 'Common.not-connected',
                    defaultMessage: 'Not Connected',
                  })}
                </span>
              </Block>
            </Block>

            <div className="flex flex-col flex-nowrap gap-4 mb-8">
              {balance && (
                <FlowModalRow
                  title={
                    intl.formatMessage({
                      id: 'Common.balance',
                      defaultMessage: 'Balance',
                    })
                  }
                  price={balance}
                />
              )}

              {/* <FlowModalRow */}
              {/*  title={`Service fee ${0}%`} */}
              {/*  price={{ total: '0', symbol: 'eth' }} */}
              {/* /> */}

              {/* <FlowModalRow */}
              {/*  title="You will pay" */}
              {/*  price={nft.sell?.price} */}
              {/* /> */}
            </div>

            {renderButton()}
          </Block>

        </ModalLayout>
      </Modal>
    )
  },
)
