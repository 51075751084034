
import { Form, Formik } from 'formik'
import { useState } from 'react'

import { noop } from '@/util/noop'

import { Block } from '../common/Block'
import { Breadcrumbs } from '../common/Breadcrumbs'
import { Button } from '../common/Button'
import { Delim } from '../common/Delim'
import { Input } from '../common/Input'
import { MediaPreview } from '../common/MediaPreview'
import { Radio } from '../common/Radio'
import { Select } from '../common/Select/Select'
import { Tabs } from '../common/Tabs'
import { PriceRangeFormShape } from '../modules/forms/PriceRangeForm'
import { CommonCard } from '../modules/cards/CommonCard'
import { ChipsList } from '../common/ChipsList'
import { TabItem } from '../common/Tabs/TabItem'


export function UIPage() {
  const [text, setText] = useState('kek')

  return (
    <div
      className={`
        border-[2px] border-[#ffffff37] p-[4px] 
        flex flex-col flex-nowrap gap-[15px]
      `}
    >
      <Block lvl={1}>
        1
        <Block>
          2
          <Block>
            3
            <Block lvl={1}>
              4
              <Block>
                5
                <Block>
                  6
                  <Block>
                    7
                  </Block>
                </Block>
              </Block>
            </Block>
          </Block>
        </Block>
      </Block>

      <Block className="flex gap-11 flex-wrap">
        <Block size="b">
          B
        </Block>

        <Block size="m">
          M
        </Block>

        <Block size="s">
          S
        </Block>

        <Block size="xs">
          XS
        </Block>
      </Block>

      <Block size="b">
        <Delim />
      </Block>
      <Block size="m">
        <Delim />
      </Block>
      <Block size="s">
        <Delim />
      </Block>
      <Block size="xs">
        <Delim />
      </Block>

      <Delim />

      <Button>Regular</Button>
      <Button wide>Wide regular</Button>

      <Button variant="flat">Flat</Button>
      <Button variant="flat" wide>Flat wide</Button>

      <Delim />

      <Breadcrumbs
        items={[
          { text: 'Overview', link: 'overview' },
          { text: 'Top kek', link: 'top kek' },
          { text: 'Marketplace' },
        ]}
      />

      <Delim />

      <Tabs>
        <TabItem id="0">NFTs</TabItem>
        <TabItem id="1">Collections</TabItem>
        <TabItem id="2">Users</TabItem>
      </Tabs>

      <Tabs onSelect={console.log}>
        <TabItem id="0">in span</TabItem>
        <TabItem id="1">just string, but very long, i dunno what to do ...</TabItem>
      </Tabs>

      <Delim />

      <form>
        <Radio name="a">
          Hello
        </Radio>
        <Radio name="a">
          Hello
        </Radio>
      </form>

      <form>
        <Radio name="a">
          Hello
        </Radio>
        <Radio name="a">
          Hello
        </Radio>
      </form>

      <Delim />

      <Input
        placeholder="Form"
        onChangeText={setText}
        value={text}
      />

      <Input
        placeholder="Form"
        variant="flat"
        onChangeText={setText}
        value={text}
      />

      <Block size="s" className="flex flex-row gap-4">
        <Button variant="flat">Hello</Button>
        <Input variant="flat" placeholder="Type here ..." />
      </Block>

      <Input
        placeholder="Uncontrolled"
        onChangeText={console.log}
        variant="flat"
      />

      <Delim />

      <div className="mb-[150px]">
        <Select
          selectId="lh"
          items={[
            { id: 'lh', content: 'Price: low to high' },
            { id: 'hl', content: 'Price: high to low' },
          ]}
        />
      </div>

      <Delim />

      <Formik
        initialValues={{
          saleType: 'All Types',
          priceRange: {
            from: '',
            to: '',
          } as PriceRangeFormShape,
        }}
        onSubmit={(values, formikHelpers) => {
          console.log(values)
          console.log(formikHelpers)
        }}
      >
        <Form>

          <Block size="m" className="w-[300px] flex flex-col flex-nowrap gap-[20px]">

            <div>
              <span>Filters</span>
              <Button variant="flat" className="float-right">Clear</Button>
            </div>

            <Delim />

            {/* <SaleTypeForm
              formName="saleType"
              optionCountMap={{
                'All Types': 10,
                'For Auction': 12,
                'For Sale': 123,
                'Not For Sale': 1234,
              }}
            /> */}

            <Delim />

            {/* <PriceRangeForm formName="priceRange" /> */}

            <div>
              <Button type="submit">Submit</Button>
              <Button type="reset">clear</Button>
            </div>
          </Block>

        </Form>
      </Formik>

      <Delim />

      <div className="m-[100px]">
        <span>vertical image occluded</span>
        <div
          className="w-[200px] h-[200px]"
        >
          <MediaPreview
            url="https://picsum.photos/200/300"
          />
        </div>

        <span>horizontal image occluded</span>
        <div
          className="w-[200px] h-[200px]"
        >
          <MediaPreview
            url="https://picsum.photos/300/200"
            __throttleMS={2000}
          />
        </div>
      </div>

      <Delim />

      <span>Nft card</span>
      <div className="ml-40 flex gap-5">
        {/* <NFTCard
          data={{
            address: '',
            collectionName: 'Coll name',
            name: 'Nft name #123',
            cryptoPrice: { value: 0.033, symbol: 'ETH' },
            fiatPrice: { value: 1, symbol: 'USD' },
            previewUrl: 'https://picsum.photos/400/200',
            rank: 1,
          }}
        /> */}

        {/* <Link to="/item-page">
          <NFTCard
            data={{
              address: '',
              collectionName: 'Coll name Coll name Coll name Coll name ',
              name: 'Nft name #123 Nft name #123 Nft name #123',
              cryptoPrice: { value: 10000, symbol: 'ETH' },
              fiatPrice: { value: 1550, symbol: 'USD' },
              previewUrl: 'https://picsum.photos/200/400',
              rank: 1213,
            }}
          />
        </Link> */}
      </div>

      <Delim />

      <span>Common card</span>
      <div className="mx-6 grid grid-cols-2 gap-3">
        <CommonCard
          iconUrl=""
          previewUrl="https://picsum.photos/200/400"
          title="Title"
          text="Some text"
        />

        <CommonCard
          iconUrl="https://picsum.photos/50/50"
          previewUrl="https://picsum.photos/200/400"
          title="Title"
          text="Some text"
          titleOrder="text title"
          blocks={[
            { title: 'Total volume', content: '4.3M ETH' },
          ]}
        />

        <CommonCard
          iconUrl="https://picsum.photos/50/50"
          previewUrl="https://picsum.photos/200/400"
          title="Title Title Title Title Title Title "
          text="Some text Some text Some text Some text "
          titleOrder="text title"
          blocks={[
            { title: 'Total volume', content: '4.3M ETH' },
            { title: 'Floor', content: '0.04 ETH' },
          ]}
        />
      </div>

      <Delim />

      <span>Collection card</span>
      <div className="mx-6 grid grid-cols-2 gap-3">
        {/* <CollectionCard
          data={{
            iconUrl: 'https://picsum.photos/50/50',
            previewUrl: 'https://picsum.photos/200/400',
            title: 'Title Title Title Title Title Title ',
            ownersCount: 12345,
            floor: { value: 4_300_000, symbol: 'ETH' },
            totalVolume: { value: 4_300_000, symbol: 'ETH' },
          }}
        /> */}

        {/* <CollectionCard
          data={{
            iconUrl: 'https://picsum.photos/50/50',
            previewUrl: 'https://picsum.photos/400/200',
            title: 'Title Title Title Title Title Title ',
            ownersCount: 12345,
            floor: { total: 4_300_000, currency: 'ETH' },
            totalVolume: { value: 4_300_000, symbol: 'ETH' },
          }}
        /> */}

        {/* <CollectionCard
          data={{
            iconUrl: 'https://picsum.photos/50/50',
            previewUrl: 'https://picsum.photos/200/400',
            title: 'Title Title Title Title Title Title ',
            ownersCount: 12345,
            floor: { value: 4_300_0, symbol: 'ETH' },
            totalVolume: { value: 0.1, symbol: 'ETH' },
          }}
        /> */}
      </div>

      <Delim />

      <span>User card</span>
      <div className="cq-is mx-32 flex flex-wrap gap-4">
        {/* <UserCard
          iconUrl="https://picsum.photos/50/50"
          previewUrl="https://picsum.photos/400/200"
          title="User name"
          followersCount={24000}
          holdingValue={{ value: 4_300_0, symbol: 'USD' }}
          nftsCount={123}
          verified
        /> */}
      </div>

      <Delim />

      {/* <div className="mx-20 h-[400px]">
        <CollectionsForm
          store={smallCollStore}
          onSelect={noop}
          selectedId=""
        />
      </div> */}

      <Delim />

      <ChipsList
        onSelect={console.log}
        selected={[]}
        items={[
          { id: '0', content: '00000' },
          { id: '1', content: '1' },
          { id: '2', content: '2' },
          {
            id: '3',
            content: (
              <span>
                {' '}
                kek lol
              </span>
            ),
          },
        ]}
      />

      <ChipsList
        isDisabled
        selected={['0', '2']}
        onSelect={noop}
        items={[
          { id: '0', content: '00000' },
          { id: '1', content: '1' },
          { id: '2', content: '2' },
          {
            id: '3',
            content: (
              <span>
                {' '}
                kek lol
              </span>
            ),
          },
        ]}
      />
    </div>
  )
}
