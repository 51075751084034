import {
  PropsWithChildren, useEffect, useRef, useState,
} from 'react'
import { usePopper } from 'react-popper'
import { Placement } from '@popperjs/core'

import { ClassProp } from '@/util/react-props'
import { cn } from '@/util/exports'
import { noop } from '@/util/noop'
import { nil } from '@/util/nil'
import useClickOutside from '@/hooks/useClickOutside'

import style from './index.module.scss'
import { Block } from '../Block'

const UPDATE_INTERVAL_MS = 250

export type DropdownProps = {
  isDropdownRow?: boolean,
  title: JSX.Element | string
  onChange?: (opened: boolean) => void
  opened?: boolean
  contentClassName?: string
  popRefElement?: HTMLElement|null
  dropYOffset?: string
  action?: 'click' | 'hover'

  placement?: Placement
} & ClassProp

export function Dropdown({
  isDropdownRow = true,
  title,
  onChange = noop,
  opened: openedProp = false,
  children,
  className,
  contentClassName,
  popRefElement = undefined,
  dropYOffset = undefined,
  action = 'click',
  placement,
}: PropsWithChildren<DropdownProps>) {
  const buttonRef = useRef<HTMLDivElement|null>(null)
  const updateRef = useRef<any>()

  const [referenceElement, setReferenceElement] = useState<HTMLElement|nil>(() => popRefElement)
  const [popperElement, setPopperElement] = useState<HTMLDivElement|null>(null)
  const { styles, attributes, forceUpdate } = usePopper(referenceElement, popperElement, { placement })

  const [opened, setOpen] = useState(() => openedProp)

  useEffect(() => {
    updateRef.current = setInterval(
      () => forceUpdate?.(),
      UPDATE_INTERVAL_MS,
    )

    return () => clearInterval(updateRef.current)
  }, [forceUpdate])

  useEffect(
    () => setOpen(openedProp),
    [openedProp],
  )

  useEffect(
    () => setReferenceElement(popRefElement || buttonRef.current),
    [popRefElement],
  )

  useClickOutside(
    [popperElement, buttonRef.current],
    () => {
      setOpen(false)
      onChange(false)
    },
  )

  function handleClick() {
    if (action !== 'click') return

    const newValue = !opened
    setOpen(newValue)
    onChange(newValue)
  }

  function handleHover(open: boolean) {
    if (action !== 'hover') return

    setOpen(open)
    onChange(open)
  }
  const onMouseIn = handleHover.bind(null, true)
  const onMouseOut = handleHover.bind(null, false)

  const popperClassName = attributes.popper?.className

  return (
    <>
      <button
        className="contents"
        type="button"
        onClick={handleClick}
        onMouseEnter={onMouseIn}
        onMouseLeave={onMouseOut}
      >
        <Block
          ref={buttonRef}
          size="xs"
          className={cn(
            style.dropdown,
            isDropdownRow && style.arrow,
            'flex items-center px-2 justify-between',
            className,
          )}
        >
          {title}
        </Block>
      </button>

      {opened && (
        <div
          ref={setPopperElement}
          {...attributes.popper}
          style={styles.popper}
          className={cn(style.content, popperClassName, contentClassName)}
          onMouseEnter={onMouseIn}
          onMouseLeave={onMouseOut}
        >
          <div style={{ paddingTop: dropYOffset, paddingBottom: dropYOffset }}>
            {children}
          </div>
        </div>
      )}
    </>
  )
}
