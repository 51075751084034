/* eslint-disable consistent-return */
import { PromiEvent } from 'web3-core'
import BigNumber from 'bignumber.js'
import { generatePath } from 'react-router'

import { Chain } from '@/util/chain'
import MarketplaceAbi from '@/abi/Marketplace.json'
import ERC20Abi from '@/abi/ERC20.json'
import { ERC20Typo, MarketplaceTypo } from '@/abi/types'
import { CurrencyAndTotal } from '@/api/stubs/money'
import { delay } from '@/util/promise'
import { Nft } from '@/api/luckyswap'
import { ENV } from '@/util/env'
import { PARAMS } from '@/routing/params'
import { ROUTES } from '@/routing/routes'

import { useEvmWallet } from '../EvmWalletService'
import { UniversalFlowStore } from '../util/UniversalFlow.store'

type Params = {
  nftId: string
  collectionAdr: string
  price: CurrencyAndTotal
}

export class MakeOfferFlowStore extends UniversalFlowStore<Params> {
  constructor(
    public readonly nft: Nft,
  ) {
    const { web3, address } = useEvmWallet()

    if (!web3) throw new Error('no web3 to instantiate flow')

    super(
      new Chain<Params>()

        .add(async params => {
          const erc20 = new web3.eth.Contract(
            ERC20Abi.abi as any,
            params.price.address,
          ) as any as ERC20Typo

          await erc20.methods
            .approve(
              ENV.PROXY_CONTRACT_ADR,
              new BigNumber(params.price.total).shiftedBy(params.price.decimals).toString(),
            )
            .send({ from: address })

          const market = new web3.eth.Contract(
            MarketplaceAbi.abi as any,
            ENV.MARKETPLACE_CONTRACT_ADR,
          ) as any as MarketplaceTypo

          const prome = market.methods
            .createOffer(
              params.nftId,
              params.collectionAdr,
              [
                new BigNumber(params.price.total).shiftedBy(params.price.decimals).toString(),
                params.price.address,
              ],
            )
            .send({ from: address })

          this.trackTx(prome)

          return new Promise<[PromiEvent<any>]>((res, rej) => {
            prome
              .once('transactionHash', () => {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: 'step_3',
                  category: 'make_offer_nft',
                  nft: this.nft.name, // name of nft
                  collection: this.nft.collection.name, // name of collection
                  path: generatePath(ROUTES.nftPage, {
                    [PARAMS.collectionId]: nft.collection.slug,
                    [PARAMS.nftId]: this.nft.id,
                  }), // path of nft
                  cost: params.price.total, // offer cost
                  id: address, // UserId
                  timestamp: new Date().getTime(),
                })
                res([prome])
              })
              .once('error', rej)
          })
        })

        .add(async ([prome]) => {
          await prome
        })

        .add(async () => {
          await delay(800)
        }),
    )
  }

  public async makeOffer(price: CurrencyAndTotal) {
    this.runSteps({
      nftId: this.nft.id,
      collectionAdr: this.nft.collection.addresses[0].address,
      price,
    })
  }
}
