import { useIntl } from 'react-intl'
import { generatePath, Link } from 'react-router-dom'
import { useMemo } from 'react'

import { ClassProp } from '@/util/react-props'
import { InteractionListItem } from '@/components/pages/NFTPage/components/Card/Content/InteractionListItem'
import { Nft } from '@/api/luckyswap'
import { Block } from '@/components/common/Block'
import { NotNil } from '@/util/nil'
import { Avatar } from '@/components/common/Avatar'
import { formatDateToDayAndTime } from '@/util/format'
import { ellipsis } from '@/util/ellipsis'
import { ROUTES } from '@/routing/routes'
import { PARAMS } from '@/routing/params'
import { Button } from '@/components/common/Button'
import { useStores } from '@/hooks/useStores'
import { getNFTPermissions } from '@/util/nft-permissions'
import { Price } from '@/api/stubs/money'
import { useEvmWallet } from '@/stores/EvmWalletService'


type OffersListProps = {
  offers: NotNil<Nft['offers']>
} & ClassProp

export function OffersList({ offers } : OffersListProps) {
  const getProfileUrl = (userAddress: string) => generatePath(
    ROUTES.userMarketPage,
    { [PARAMS.userId]: userAddress },
  )
  const intl = useIntl()
  const { address: userAddress } = useEvmWallet()
  const { flowsStore, nftPageStore: { nftData }} = useStores()
  const { canAcceptOffer } = getNFTPermissions(nftData.$value)

  const canCancelOffer = useMemo(() => {
    let canCancel = false
    offers.forEach(offer => {
      if (offer.address.address === userAddress?.toLowerCase()) canCancel = true
    })
    return canCancel
  }, [offers.length])

  const renderButton = (price: Price, offerId: number, maker: string) => {
    if (canAcceptOffer) {
      return (
        <Button
          wide
          onClick={() => flowsStore.startAcceptOfferFlow({ price, offerId: offerId.toString() })}
          className="w-[74px] h-[40px] !p-[8px] text-[18px] ml-5 tracking-tighter"
        >
          {intl.formatMessage({
            id: 'Buttons.Accept',
            defaultMessage: 'Accept',
          })}
        </Button>
      )
    }

    if (maker === userAddress?.toLowerCase()) {
      return (
        <Button
          wide
          onClick={() => flowsStore.startDeleteOfferFlow({ price, offerId: offerId.toString() })}
          className="w-[74px] h-[40px] !p-[8px] text-[18px] ml-5 tracking-tighter"
        >
          {intl.formatMessage({
            id: 'Buttons.Cancel',
            defaultMessage: 'Cancel',
          })}
        </Button>
      )
    }

    if (canCancelOffer) {
      return (
        <div className="w-[74px] h-[40px] !p-[8px] ml-5" />
      )
    }

    return undefined
  }

  return (
    <Block className="grid grid-cols-1 gap-3 max-h-[454px] overflow-auto">
      <div id="bidsList">
        {
          offers.map(({
            price, created, address, offerId,
          }) => (
            <InteractionListItem
              key={created.toString()}
              icon={<Avatar className="!min-w-[40px] !min-h-[40px] !max-w-[40px] !max-h-[40px]" />}
              title={(
                <>
                  {intl.formatMessage(
                    {
                      id: 'OffersList.Offer-by',
                      defaultMessage: 'Offer{space}by{space}',
                    },
                    {
                      space: <>&nbsp;</>,
                    },
                  )}
                  <Link to={getProfileUrl(address.address)} className="text-[color:var(--active)]">
                    {ellipsis(address.address, 5, 5)}
                  </Link>
                </>
              )}
              rowButton={renderButton(price, offerId, address.address)}
              text={formatDateToDayAndTime(new Date(created))}
              price={{ address: price.address, total: price?.total || '0' }}
              data-created={created}
            />
          ))
        }
      </div>
    </Block>
  )
}
