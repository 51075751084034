import { useEvmWallet } from '@/stores/EvmWalletService'
import { useStores } from '@/hooks/useStores'
import { Nft } from '@/api/luckyswap'
import { nil } from '@/util/nil'

// TODO ugly. Refac 
export const useNftStates = (nft?: Nft | nil) => {
  const { address } = useEvmWallet()
  const {
    nftPageStore: {
      $currentNft,
      $ownerAddress,
    },
  } = useStores()

  nft = nft || $currentNft

  return {
    // TODO Dima, this checks are also useless. Just equation is enough
    isOwner: address && $ownerAddress
      ? address.toLowerCase() === $ownerAddress.toLowerCase()
      : false,
    isNftOnSale: !!nft?.sell,
    isNftOnAuction: !!nft?.auction,
  }
}
