import ReactDOM from 'react-dom/client'
import React from 'react'
import { configure } from 'mobx'
import { observer } from 'mobx-react-lite'
import { BrowserRouter } from 'react-router-dom'


import { App } from './App'

import './normalize.css'
import '@/styles/fonts/fonts.scss'
import './index.css'

configure({
  enforceActions: 'never',
})

export const AppWrapper = observer(() => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
))

ReactDOM.hydrateRoot(document.getElementById('root') as HTMLElement, <AppWrapper />)

