import { computed, makeObservable, observable } from 'mobx'

import { CollectionsService } from '@/api/luckyswap'

import { FilterableStore } from './util/FilterableStore'

export class CollectionsFilterFormStore {
  public readonly collection = new FilterableStore(
    CollectionsService.postCollectionFilter,
  )

  @observable private $attrsSearch = ''
  @observable private $selectedSlug = ''

  constructor() {
    makeObservable(this)
  }

  @computed public get $selected() {
    const { $selectedSlug } = this

    return this.collection.$items.find(
      item => item.slug.toLowerCase() === $selectedSlug.toLowerCase(),
    )
    || null
  }

  @computed public get $searchedAttrs() {
    const { $selected, $attrsSearch } = this

    if (!$selected) return []
    if (!$attrsSearch) return $selected.attributes

    return $selected.attributes
      .filter(attr => {
        const trait = attr.trait_object

        return (
          trait.trait_type.toLowerCase().includes($attrsSearch)
          || trait.trait_value?.toLowerCase().includes($attrsSearch)
        )
      })
  }

  public selectCollection(slug: string) {
    this.$selectedSlug = slug
  }

  public searchAttributes(value: string) {
    this.$attrsSearch = value
  }
}
