import {
  PropsWithChildren, useEffect, useMemo, useState,
} from 'react'

import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'

import { Block } from '../Block'
import { TabsCtx } from './Tabs.ctx'
import style from './style.module.scss'

export type TabItem = {
  content: JSX.Element | string
  id: string
}

type Props<T extends string = string> = {
  selectId?: T
  onSelect?: (id: T) => void
}
& ClassProp

export function Tabs<T extends string = string>({
  selectId,
  onSelect,
  className,
  children,
}: PropsWithChildren<Props<T>>) {
  const [tab, setTab] = useState(() => selectId || '')

  const ctx: TabsCtx = useMemo(() => ({
    selectedId: tab,
    setTab: (id: string) => {
      setTab(id)
      onSelect?.(id as T) // TODO may be implement full generic
    },
  }), [tab, onSelect])


  useEffect(() => setTab(selectId || ''), [selectId])

  // useEffect(() => onSelect?.(tab), [tab, onSelect])

  return (
    <TabsCtx.Provider value={ctx}>
      <Block
        size="xs"
        className={cn(
          style.box,
          className,
        )}
      >
        {children}
      </Block>
    </TabsCtx.Provider>
  )
}
