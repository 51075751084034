import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import BigNumber from 'bignumber.js'
import { useIntl } from 'react-intl'

import { Select, SelectItem } from '@/components/common/Select/Select'
import { Table } from '@/components/common/Table'
import { ClassProp } from '@/util/react-props'
import { PathParams } from '@/routing/params'
import { Activity, ActivityService, CollectionsPaginationResponse } from '@/api/luckyswap'
import { ellipsis } from '@/util/ellipsis'
import { STUB } from '@/util/stub'
import { formatDateToDayAndTime } from '@/util/format'
import { ActivityTypes } from '@/constants/activity.constants'
import { CurrenciesService } from '@/stores/Currencies.store'

import { TransactionTypeInfo } from './TransactionTypeInfo'

const formatAmount = (price?: CollectionsPaginationResponse['items'][0]['floor']) => {
  if (!price || !price.total) return STUB
  const { currencies } = CurrenciesService
  const tokenDetails = currencies.find(cur => cur.address === price.address)
  if (!tokenDetails) return STUB
  return `${new BigNumber(price.total).shiftedBy(-tokenDetails.decimals).toString()} ${tokenDetails.symbol}`
}

const renderActivityRow = ({
  activityType, price, fromUser, toUser, activityTime,
}: Activity) => [
  { content: <TransactionTypeInfo activityType={activityType} /> },
  { content: <span className="float-right whitespace-nowrap">{formatAmount(price)}</span> },
  { content: <span className="float-right">{ellipsis(fromUser || STUB, 5, 4)}</span> },
  { content: <span>{ellipsis(toUser || STUB, 5, 4)}</span> },
  { content: <span className="float-right">{formatDateToDayAndTime(new Date(activityTime))}</span> },
]

export function ActivitiesTable({ className }: ClassProp) {
  const intl = useIntl()

  const SORT_ITEMS: SelectItem[] = [
    {
      id: ActivityTypes.All,
      content: intl.formatMessage({
        id: 'ActivitiesTable.All',
        defaultMessage: 'All',
      }),
    },
    {
      id: ActivityTypes.Mint,
      content: intl.formatMessage({
        id: 'ActivitiesTable.Minting',
        defaultMessage: 'Minting',
      }),
    },
    {
      id: ActivityTypes.Offer,
      content: intl.formatMessage({
        id: 'ActivitiesTable.Offer',
        defaultMessage: 'Offer',
      }),
    },
    {
      id: ActivityTypes.List,
      content: intl.formatMessage({
        id: 'ActivitiesTable.Listing',
        defaultMessage: 'Listing',
      }),
    },
    {
      id: ActivityTypes.Bid,
      content: intl.formatMessage({
        id: 'ActivitiesTable.Bid',
        defaultMessage: 'Bid',
      }),
    },
    {
      id: ActivityTypes.CancelList,
      content: intl.formatMessage({
        id: 'ActivitiesTable.Unlisting',
        defaultMessage: 'Unlisting',
      }),
    },
    {
      id: ActivityTypes.Sell,
      content: intl.formatMessage({
        id: 'ActivitiesTable.Sale',
        defaultMessage: 'Sale',
      }),
    },
    {
      id: ActivityTypes.Transfer,
      content: intl.formatMessage({
        id: 'ActivitiesTable.Transfer',
        defaultMessage: 'Transfer',
      }),
    },
  ]

  const [sortBy, setSortBy] = useState('')
  const [activities, setActivities] = useState<Activity[]>([])
  const { nftId, collectionId } = useParams<PathParams>()

  useEffect(() => {
    if (!nftId || !collectionId) return
    ActivityService
      .postActivityFilter({ tokenId: nftId, collectionSlug: collectionId })
      .then(setActivities)
  }, [nftId, collectionId])

  const activitiesToRender = useMemo(() => {
    if (!sortBy || sortBy === ActivityTypes.All) return activities
    return activities.filter(item => item.activityType === sortBy)
  }, [activities, sortBy])

  return (
    <div className={className}>
      <div className="w-full flex flex-row flex-wrap justify-between gap-5 mb-8">
        <div className='font-bold text-[32px] font-["JetBrains_Mono"]'>
          {intl.formatMessage({
            id: 'ActivitiesTable.Last-activities',
            defaultMessage: 'Last activities',
          })}
        </div>

        <Select
          title={sortBy
            ? intl.formatMessage({ id: `ActivitiesTable.${sortBy}` })
            : intl.formatMessage({ id: 'ActivitiesTable.Type', defaultMessage: 'Type' })}
          items={SORT_ITEMS}
          selectId={sortBy}
          onSelect={setSortBy}
        />
      </div>

      <Table
        className="max-h-[500px]"
        header={[
          intl.formatMessage({
            id: 'ActivitiesTable.Type',
            defaultMessage: 'Type',
          }),
          <span className="float-right">
            {intl.formatMessage({
              id: 'ActivitiesTable.Price',
              defaultMessage: 'Price',
            })}
          </span>,
          <span className="float-right">
            {intl.formatMessage({
              id: 'ActivitiesTable.From',
              defaultMessage: 'From',
            })}
          </span>,
          intl.formatMessage({
            id: 'ActivitiesTable.To',
            defaultMessage: 'To',
          }),
          <span className="float-right">
            {intl.formatMessage({
              id: 'ActivitiesTable.Time',
              defaultMessage: 'Time',
            })}
          </span>,
        ]}
        rows={activitiesToRender.map(renderActivityRow)}
      />
    </div>
  )
}
