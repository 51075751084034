import { DecodedValueMap } from 'use-query-params'

import { PriceRangeFormShape } from '@/components/modules/forms/PriceRangeForm'
import { QUERIES } from '@/queries'
import { CollectionsFormShape } from '@/components/modules/forms/CollectionsForm'
import { NFT_FILTER_QUERIES } from '@/components/modules/forms/form-query'
import { FormSaleType } from '@/components/modules/forms/SaleTypeForm'
import { Blockchain } from '@/api/stubs/blockchain'

export const NFTS_FILTER_INITIAL = {
  blockchains: <Blockchain[]>[],

  saleType: <FormSaleType>'all',

  price: <PriceRangeFormShape>{
    from: '',
    to: '',
  },

  collection: <CollectionsFormShape>'',

  attributes: new Array<string>(),
}

export function filterToQuery(params: typeof NFTS_FILTER_INITIAL) {
  return {
    [QUERIES.blockchains]: params.blockchains,

    [QUERIES.saleType]: params.saleType,

    [QUERIES.price_from]: params.price.from,
    [QUERIES.price_to]: params.price.to,

    [QUERIES.collection]: params.collection,

    [QUERIES.attributes]: params.attributes,
  }
}

export function queryToFilter(
  query: DecodedValueMap<typeof NFT_FILTER_QUERIES>,
) {
  return <typeof NFTS_FILTER_INITIAL>{
    blockchains: query.blockchains || NFTS_FILTER_INITIAL.blockchains,

    saleType: query.saleType as FormSaleType || NFTS_FILTER_INITIAL.saleType,

    price: {
      from: query.price_from || NFTS_FILTER_INITIAL.price.from,
      to: query.price_to || NFTS_FILTER_INITIAL.price.to,
    },

    collection: query.collection || NFTS_FILTER_INITIAL.collection,

    attributes: query.attributes || [],
  }
}
