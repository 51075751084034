import cn from 'classnames'
import { observer } from 'mobx-react-lite'

import { Button } from '@/components/common/Button'
import { AppLocales } from '@/stores/Locale.store'
import { useStores } from '@/hooks/useStores'
import { ClassProp } from '@/util/react-props'

type LocalizationProps = ClassProp

export const Localization = observer(({ className }: LocalizationProps) => {
  const { localeStore: { setLocale, getCurrentLocale }} = useStores()
  return (
    <div className={cn('flex flex-row flex-wrap gap-4', className)}>
      <Button
        className={cn(
          'text-sm font-["JetBrains_Mono"] !bg-transparent !p-0',
          getCurrentLocale === AppLocales.RU
            ? '!text-[color:var(--text-primary)]'
            : '!text-[color:var(--text-secondary)]',
        )}
        onClick={() => setLocale(AppLocales.RU)}
      >
        <span className={cn(
          'border rounded p-1',
          getCurrentLocale !== AppLocales.RU && 'border-[color:var(--text-secondary)]',
        )}
        >
          RU
        </span>
        &nbsp;Русский
      </Button>
      <Button
        className={cn(
          'text-sm font-["JetBrains_Mono"] !bg-transparent !p-0',
          getCurrentLocale === AppLocales.EN
            ? '!text-[color:var(--text-primary)]'
            : '!text-[color:var(--text-secondary)]',
        )}
        onClick={() => setLocale(AppLocales.EN)}
      >
        <span className={cn(
          'border rounded p-1',
          getCurrentLocale !== AppLocales.EN && 'border-[color:var(--text-secondary)]',
        )}
        >
          EN
        </span>
        &nbsp;English
      </Button>
    </div>
  )
})
