import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { generatePath, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { newMoreItemsStore } from '@/stores/MoreItems.store'
import { CardsGrid, CardType } from '@/components/modules/CardsGrid'
import { ROUTES } from '@/routing/routes'
import { PARAMS } from '@/routing/params'
import { Nft } from '@/api/luckyswap'

type Props = {
  nft: Nft
}

export const MoreItems = observer(({
  nft,
}: Props) => {
  const intl = useIntl()

  const [store] = useState(() => newMoreItemsStore())

  useEffect(() => {
    if (!nft) return
    if (store.$isLoading) return

    store.clear()

    store.load({
      params: {
        nft,
      },
    })
  }, [store, nft])

  const collectionUrl = generatePath(ROUTES.collectionPage, {
    [PARAMS.collectionId]: nft.collection.slug,
  })

  return (
    <div>
      <div className="flex flex-row flex-nowrap justify-between items-center gap-5 mb-14">
        <div className='font-bold text-[32px] font-["JetBrains_Mono"]'>
          {intl.formatMessage({
            id: 'MoreItems.More',
            defaultMessage: 'More from this collection',
          })}
        </div>

        <Link
          className="text-[color:var(--active)] whitespace-nowrap"
          to={collectionUrl}
        >
          {intl.formatMessage({
            id: 'MoreItems.See-all',
            defaultMessage: 'See all ->',
          })}
        </Link>
      </div>

      <CardsGrid
        data={{
          type: CardType.nft,
          value: store.$value,
        }}
      />
    </div>
  )
})
