/* eslint-disable no-restricted-syntax */

import { Nft, NftService } from '@/api/luckyswap'
import { Blockchain } from '@/api/stubs/blockchain'
import { NftModel } from '@/api/stubs/models'

import { ValueStore } from './util/ValueStore'

type MoreItemsStoreParams = {
  nft: Nft
}

// TODO. its a test architecture. 
export function newMoreItemsStore() {
  return new ValueStore<NftModel[], MoreItemsStoreParams>(
    [],
    async params => {
      if (!params) return []

      const { nft } = params

      const resp = await NftService.postNftFilter({
        skip: 0,
        take: 4,

        blockchain: Object.values(Blockchain),
        status: [],
        traits: [],
        collection: nft.collection.slug,
      })

      return resp.items
    },
  )
}
