import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { useStores } from '@/hooks/useStores'
import { DirectSellFlow } from '@/components/modules/flows/SellNFTFlow/DirectSellFlow'

import { ChooseSellTypeModal } from './ChooseSellTypeModal'
import { SellType } from './util'
import { PutForAuctionFlow } from './PutForAuctionFlow'

type Props = {
  onClose?: VoidFunction
}

export const SellNFTFlow = observer(
  ({
    onClose,
  }: Props) => {
    const { flowsStore } = useStores()

    const [sellType, setSellType] = useState<SellType | null>(null)

    function handleTypeChoose(type: SellType) {
      setSellType(type)

      if (type === SellType.auction) flowsStore.startPutOnAuctionFlow()
      if (type === SellType.fixedPrice) flowsStore.startDirectSellNftFlow()
    }

    if (!sellType) {
      return (
        <ChooseSellTypeModal
          onClick={handleTypeChoose}
          onClose={onClose}
        />
      )
    }

    if (sellType === SellType.fixedPrice) {
      return <DirectSellFlow onClose={onClose} />
    }

    if (sellType === SellType.auction) {
      return <PutForAuctionFlow onClose={onClose} />
    }

    return null
  },
)
