/* eslint-disable no-restricted-syntax */


import { CollectionsService, NftService } from '@/api/luckyswap'

import { FilterableStore } from './util/FilterableStore'

export class MarketplacePageStore {

  public readonly nfts = new FilterableStore(
    NftService.postNftFilter,
  )

  public readonly collections = new FilterableStore(
    CollectionsService.postCollectionFilter,
  )

  constructor() {
    // makeObservable(this)
  }
}
