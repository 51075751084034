import React from 'react'

import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'

import style from './style.module.scss'
import { Delim } from '../Delim'

type Item = {
  content: string | JSX.Element
}

type Row = Item[]

type Props = {
  header: (string | JSX.Element)[]

  rows: Row[]
}
& ClassProp

export function Table({
  header,
  rows,
  className,
}: Props) {


  const columnsTemplate: React.CSSProperties = {
    gridTemplateColumns: `repeat(${header.length}, 1fr)`,
  }

  return (
    <div
      style={{
        ...columnsTemplate,
      }}
      className={cn(
        style.table,
        className,
      )}
    >
      <div className={style.header}>
        {header.map((title, index) => (
          <span
            key={index}
            className={style.cell}
          >
            {title}
          </span>
        ))}
      </div>

      <Delim className="col-[1/-1]" variant="secondary" />

      {rows.map((row, rowIndex) => (
        <div
          key={rowIndex}
          className={cn(style.itemsRow)}
        >
          {row.map((item, itemIndex) => (
            <div
              key={itemIndex}
              className={style.cell}
            >
              {item.content}
            </div>
          ))}
        </div>
      ))}

    </div>
  )
}
