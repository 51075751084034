import { DateTime, Duration } from 'luxon'
import {
  useEffect, useMemo, useRef, useState,
} from 'react'

import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'

import style from './style.module.scss'

const TICK_INTERVAL_MS = 1000

type Props = {
  targetTimeISO: string

  days?: boolean
  hours?: boolean
  minutes?: boolean
  seconds?: boolean

  onTimeIsUp?: VoidFunction

  size?: 'l' | 's'
}
& ClassProp

export function Timer({
  targetTimeISO,

  days,
  hours,
  minutes,
  seconds,

  onTimeIsUp,

  size = 'l',

  className,
}: Props) {
  const timerRef = useRef<any>()

  const targetTime = useMemo(
    () => DateTime.fromISO(targetTimeISO),
    [targetTimeISO],
  )

  const [currentTime, setCurrentTime] = useState(
    () => DateTime.now(),
  )

  useEffect(() => {
    timerRef.current = setInterval(
      () => setCurrentTime(DateTime.now()),
      TICK_INTERVAL_MS,
    )

    return () => clearInterval(timerRef.current)
  }, [])

  const timeDiff = useMemo(
    () => {
      let diff = targetTime.diff(
        currentTime,
        ['days', 'hours', 'minutes', 'seconds'],
      )

      if (diff.toMillis() < 0) {
        diff = Duration.fromMillis(0)
        clearInterval(timerRef.current)
      }

      return {
        days: diff.days.toString().padStart(2, '0'),
        hours: diff.hours.toString().padStart(2, '0'),
        minutes: diff.minutes.toString().padStart(2, '0'),
        seconds: Math.floor(diff.seconds).toString().padStart(2, '0'),
      }
    },
    [currentTime],
  )

  useEffect(() => {
    if (currentTime > targetTime) {
      onTimeIsUp?.()
      clearInterval(timerRef.current)
    }
  }, [currentTime, targetTime])

  return (
    <div
      className={cn(
        'flex flex-row flex-nowrap',
        size === 's' && style.small,
        className,
      )}
    >
      {days && (
        <div>
          <div className={style.time}>{timeDiff.days}</div>
          <div className={style.text}>days</div>
        </div>
      )}


      {hours && (
        <>
          <div className={style.colon}>:</div>

          <div>
            <div className={style.time}>{timeDiff.hours}</div>
            <div className={style.text}>hrs</div>
          </div>
        </>
      )}

      {minutes && (
        <>
          <div className={style.colon}>:</div>

          <div>
            <div className={style.time}>{timeDiff.minutes}</div>
            <div className={style.text}>min</div>
          </div>
        </>
      )}

      {seconds && (
        <>
          <div className={style.colon}>:</div>

          <div>
            <div className={style.time}>{timeDiff.seconds}</div>
            <div className={style.text}>sec</div>
          </div>
        </>
      )}
    </div>
  )
}
