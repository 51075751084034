import { useIntl } from 'react-intl'

import { Nft } from '@/api/luckyswap'
import { Block } from '@/components/common/Block'
import { ellipsis } from '@/util/ellipsis'
import { ENV } from '@/util/env'
import { STUB } from '@/util/stub'

import style from './style.module.scss'

type Props = {
  nft?: Nft
}

export function Overview({
  nft,
}: Props) {
  const intl = useIntl()

  return (
    <Block size="m" className={style.box}>

      <div className={style.title}>
        {intl.formatMessage({ id: 'Overview.Chain', defaultMessage: 'Chain' })}
      </div>
      <div className={style.value}>
        {nft?.address.blockchain || STUB}
      </div>

      <div className={style.title}>
        {intl.formatMessage({ id: 'Overview.Contract-Address', defaultMessage: 'Contract Address' })}
      </div>
      <div className={style.value}>
        {ellipsis(ENV.MARKETPLACE_CONTRACT_ADR || STUB, 5, 3)}
      </div>

      <div className={style.title}>
        {intl.formatMessage({ id: 'Overview.Token-Standard', defaultMessage: 'Token Standard' })}
      </div>
      <div className={style.value}>
        {nft?.tokenStandard || STUB}
      </div>

      <div className={style.title}>
        {intl.formatMessage({ id: 'Overview.Metadata', defaultMessage: 'Metadata' })}
      </div>
      <div className={style.value}>
        {nft?.metadata || STUB}
      </div>

      <div className={style.title}>
        {intl.formatMessage({ id: 'Overview.Artist-Royalties', defaultMessage: 'Artist Royalties' })}
      </div>
      <div className={style.value}>
        {nft?.artistRoyalties || STUB}
      </div>

      <div className={style.title}>
        {intl.formatMessage({ id: 'Overview.Marketplace-Royalties', defaultMessage: 'Marketplace Royalties' })}
      </div>
      <div className={style.value}>
        {nft?.marketplaceRoyalties || STUB}
      </div>

    </Block>
  )
}
