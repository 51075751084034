import { useEffect, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useIntl } from 'react-intl'

import { Input } from '@/components/common/Input'
import { ClassProp } from '@/util/react-props'
import { CollectionsFilterFormStore } from '@/stores/CollectionsFilterForm.store'
import { Attribute } from '@/api/stubs/attribute'
import { Accordion } from '@/components/common/Accordion'

import { AttributesFormItem } from './AttributesFormItem'

export type AttributesFormShape = string[]

function getTraitId(attr: Attribute) {
  const trait = attr.trait_object
  return `${trait.trait_type}---${trait.trait_value}`
}

type Props = {
  store: CollectionsFilterFormStore

  selectedIds: string[]
  onSelect: (selectedIds: string[]) => void
}
& ClassProp

export const AttributesForm = observer(
  ({
    store,
    selectedIds,
    onSelect,
    className,
  }: Props) => {
    const intl = useIntl()

    const { $searchedAttrs, $selected } = store

    const [search, setSearch] = useState('')

    useEffect(() => store.searchAttributes(search), [store, search])

    const grouppedAttrs = useMemo(() => {
      const map: Record<string, Attribute[]> = {}

      $searchedAttrs.forEach(attr => {
        const trait = attr.trait_object

        map[trait.trait_type] = [
          ...map[trait.trait_type] || [],
          attr,
        ]
      })

      return Object.entries(map)
    }, [$searchedAttrs])

    function handleCheck(e: React.ChangeEvent<HTMLInputElement>) {
      const { id } = e.target.dataset
      if (!id) return

      const set = new Set(selectedIds)

      if (set.has(id)) set.delete(id)
      else set.add(id)

      onSelect?.([...set])
    }

    return (
      <div className={className}>
        <Input
          wide
          placeholder={intl.formatMessage({
            id: 'AttributesForm.Search-by-value',
            defaultMessage: 'Search by value',
          })}
          value={search}
          onChangeText={setSearch}
        />

        {grouppedAttrs.map(([group, attrs], index) => {
          const hasSelectedInGroup = attrs.some(
            attr => selectedIds.includes(getTraitId(attr)),
          )

          return (
            <Accordion
              key={group}
              title={group}
              initOpened={hasSelectedInGroup || index === 0}
            >
              {attrs.map(attr => {
                const trait = attr.trait_object

                const traitId = getTraitId(attr)
                return (
                  <div className="mb-2">
                    <AttributesFormItem
                      key={trait.trait_value}
                      data={attr}
                      totalSupply={$selected?.totalSupply || 0}
                      checkbox={{
                        'data-id': traitId,
                        checked: selectedIds.includes(traitId),
                        onChange: handleCheck,
                      }}
                    />
                  </div>
                )
              })}
            </Accordion>
          )
        })}

      </div>
    )
  },

)
