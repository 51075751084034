// https://github.com/ElForastero/use-click-outside/blob/master/src/useClickOutside.ts

import { useEffect, useRef } from 'react'

import { nil } from '@/util/nil'

const useClickOutside = (
  refs: (HTMLElement | nil)[],
  callback: (event: MouseEvent) => void,
  eventType: keyof DocumentEventMap = 'click',
) => {
  const handlerRef = useRef(callback)

  /**
   * Update callback if it changes
   */
  useEffect(() => {
    handlerRef.current = callback
  })

  /**
   * Add and remove event listeners
   */
  useEffect(() => {
    const listener = (event: DocumentEventMap[typeof eventType]) => {
      const target = event.target as HTMLElement

      const refsInShadow = refs.some(ref => ref && target.shadowRoot?.contains(ref))
      const targetInRefs = refs.some(ref => ref && ref.contains(target))

      if (target.shadowRoot) {
        if (!refsInShadow) {
          handlerRef.current(event as MouseEvent)
        }
      }
      else if (!targetInRefs) {
        handlerRef.current(event as MouseEvent)
      }
    }

    document.addEventListener(eventType, listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener(eventType, listener)
      document.removeEventListener('touchstart', listener)
    }
  })
}

export default useClickOutside
