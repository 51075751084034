import { generatePath, Link } from 'react-router-dom'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useIntl } from 'react-intl'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import useWindowSize from '@/hooks/useWindowSize'
import { Button } from '@/components/common/Button'
import { ROUTES } from '@/routing/routes'
import { useStores } from '@/hooks/useStores'
import { makeCollectionsRequest } from '@/util/filter-api'
import { PARAMS } from '@/routing/params'
import { Collection } from '@/api/luckyswap'
import { CollectionCard } from '@/components/modules/cards/CollectionCard'
import { CardsGrid, CardType } from '@/components/modules/CardsGrid'

import style from './style.module.scss'

SwiperCore.use([Navigation])

export const PromoCollectionsBlock = observer(() => {
  const intl = useIntl()
  const { width } = useWindowSize()
  const { marketplacePageStore: { collections }} = useStores()

  useEffect(
    () => {
      if (collections.$itemsTotal) return
      collections.load(makeCollectionsRequest('', undefined, undefined, 4))
    },
    [],
  )

  return (
    <section className="mb-32">
      <div className="flex justify-between flex-col md:flex-row mb-6 md:mb-9">
        <h2 className='font-bold text-4xl font-["JetBrains_Mono"]'>
          {
            intl.formatMessage(
              {
                id: 'PromoCollectionsBlock.Actual-collection',
                defaultMessage: 'Актуальные коллекции',
              },
            )
          }
        </h2>
      </div>

      {width && width < 500 ? (
        <>
          <Swiper
            spaceBetween={12}
            className={`max-w-[358px] ${style.customSwiperBullets}`}
            pagination
            navigation
          >
            {collections.$items.slice(0, 3).map(data => (
              <SwiperSlide key={data.slug}>
                <Link to={generatePath(ROUTES.collectionPage, { [PARAMS.collectionId]: data.slug })}>
                  <CollectionCard data={data as Collection} />
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
          {collections.$itemsTotal > 3 && (
            <Link to={`${ROUTES.marketplacePage}?tab=collections`}>
              <Button wide variant="regular" className="mt-8">
                {
                  intl.formatMessage(
                    {
                      id: 'PromoCollectionsBlock.See-all',
                      defaultMessage: 'Посмотреть все',
                    },
                  )
                }
              </Button>
            </Link>
          )}
        </>
      ) : (
        <CardsGrid
          className="promo-cards"
          isHorizontalLayout={width ? width > 950 : false}
          data={{ type: CardType.collection, value: collections.$items.slice(0, 3) }}
        />
      )}
    </section>
  )
})
