/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react'

export function useToggle(initial: boolean, ref?: React.MutableRefObject<HTMLElement | null>) {
  const [toggled, setToggled] = useState(() => initial)

  useEffect(() => {
    const el = ref?.current
    if (!el) return

    function handleClick() {
      setToggled(prev => !prev)
    }

    el.addEventListener('click', handleClick)

    return () => {
      el.removeEventListener('click', handleClick)
    }

  }, [ref])

  function toggle() {
    setToggled(prev => !prev)
  }

  return [toggled, toggle, setToggled] as [toggled: boolean, toggle: VoidFunction, setToggled: typeof setToggled]
}
