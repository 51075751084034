/* eslint-disable consistent-return */
import { observer } from 'mobx-react-lite'
import { generatePath, useParams } from 'react-router'
import { useQueryParam } from 'use-query-params'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { scrollIntoView } from 'seamless-scroll-polyfill'

import { PageLayout } from '@/components/layout/PageLayout'
import { Header } from '@/components/modules/Header'
import { Breadcrumbs } from '@/components/common/Breadcrumbs'
import { MediaPreview } from '@/components/common/MediaPreview'
import { RatioBox } from '@/components/common/RatioBox'
import { Tabs } from '@/components/common/Tabs'
import { TabItem } from '@/components/common/Tabs/TabItem'
import { PARAMS, PathParams } from '@/routing/params'
import { QUERIES } from '@/queries'
import { arrayToMap } from '@/util/array-to-map'
import { MARKETPLACE_TABS } from '@/routing/tabs'
import { ROUTES } from '@/routing/routes'
import { useStores } from '@/hooks/useStores'
import { LoadingBox } from '@/components/common/LoadingBox'
import { LOAD_STUB } from '@/util/stub'
import { Footer } from '@/components/modules/Footer'
import { BidsList } from '@/components/pages/NFTPage/components/BidsList'
import { OffersList } from '@/components/pages/NFTPage/components/OffersList'

import style from './style.module.scss'
import { Card } from './components/Card'
import { Overview } from './components/Overview'
import { ActivitiesTable } from './components/ActivitiesTable'
import { MoreItems } from './components/MoreItems'
import { Attributes } from './components/Attributes'

const TABS = arrayToMap([
  'overview',
  'attributes',
  'history',
  'bids',
  'offers',
] as const)

export const NFTPage = observer(() => {
  const intl = useIntl()

  const {
    routingStore,
    nftPageStore,
  } = useStores()

  const { nftData } = nftPageStore

  const [tab = TABS.overview, setTab] = useQueryParam<string>(QUERIES.tab)

  const {
    nftId,
    collectionId: collectionSlug,
  } = useParams<PathParams>()

  useEffect(() => {
    if (!collectionSlug) return
    if (!nftId) return
    if (nftData.$isLoading) return

    nftData.load({
      skipIfLoaded: false,
      params: [collectionSlug, nftId],
    })
  }, [nftData, collectionSlug, nftId])

  useEffect(() => () => {
    nftData.clear()
  }, [nftData])

  useEffect(() => () => {
    const section = document.querySelector('#top')
    if (section) scrollIntoView(section, { behavior: 'smooth', block: 'start' })
  }, [nftId])

  useEffect(() => {
    if ((tab === TABS.offers && !nftData.$value?.offers.length)
      || (tab === TABS.bids && !nftData.$value?.auction?.bids)) {
      setTab(TABS.overview)
    }
  }, [nftData.$value])

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageLayout.Body>
          <Header />
        </PageLayout.Body>
      </PageLayout.Header>

      <PageLayout.Body>

        <Breadcrumbs
          className="mt-7 mb-9"
          items={[
            {
              text: routingStore.$sourcePath.title === 'Overview' ? intl.formatMessage({ id: 'CollectionPage.Overview', defaultMessage: 'Overview' }) : intl.formatMessage({ id: 'CollectionPage.Marketplace', defaultMessage: 'Marketplace' }),
              link: routingStore.$sourcePath.path,
            },
            {
              text: intl.formatMessage({ id: 'CollectionPage.Collection', defaultMessage: 'Collection' }),
              link: `${ROUTES.marketplacePage}?${QUERIES.tab}=${MARKETPLACE_TABS.collections}`,
            },
            {
              text: nftData.$value?.collection.name || LOAD_STUB,
              link: generatePath(ROUTES.collectionPage, {
                [PARAMS.collectionId]: collectionSlug ?? '',
              }),
            },
            { text: nftData.$value?.name ?? LOAD_STUB },
          ]}
        />

        <div className={style.container} id="top">
          <div className={style.layout}>
            <RatioBox
              mode="h-by-w"
              ratio={1}
              className="overflow-hidden rounded w-full"
            >
              <MediaPreview
                url={nftData.$value?.image.url || ''}
              />
            </RatioBox>

            <div className="w-full">
              <LoadingBox loading={nftData.$isLoading}>
                <Card
                  className="mb-6"
                />
              </LoadingBox>

              <Tabs
                onSelect={setTab}
                selectId={tab}
                className="w-full mb-5"
              >
                <TabItem id={TABS.overview}>
                  {intl.formatMessage({
                    id: 'NFTPage.Overview',
                    defaultMessage: 'Overview',
                  })}
                </TabItem>
                <TabItem id={TABS.attributes}>
                  {intl.formatMessage({
                    id: 'NFTPage.Attributes',
                    defaultMessage: 'Attributes',
                  })}
                </TabItem>
                {/* <TabItem id={TABS.history}>Price&nbsp;history</TabItem> */}
                {nftData.$value?.auction && (
                  <TabItem id={TABS.bids}>
                    {intl.formatMessage({
                      id: 'NFTPage.Bids',
                      defaultMessage: 'Bids',
                    })}
                  </TabItem>
                )}
                {!!nftData.$value?.offers.length && (
                  <TabItem id={TABS.offers}>
                    {intl.formatMessage({
                      id: 'NFTPage.Offers',
                      defaultMessage: 'Offers',
                    })}
                  </TabItem>
                )}
              </Tabs>

              {tab === TABS.overview && (
                <Overview nft={nftData.$value} />
              )}

              {tab === TABS.attributes && nftData.$value && (
                <Attributes
                  collectionSlug={nftData.$value.collection.slug}
                  attrs={nftData.$value?.attributes}
                />
              )}

              {tab === TABS.bids && nftData.$value && nftData.$value?.auction && (
                <BidsList bids={nftData.$value?.auction?.bids} />
              )}

              {tab === TABS.offers && !!nftData.$value?.offers.length && (
                <OffersList offers={nftData.$value?.offers} />
              )}
            </div>

          </div>
        </div>

        <ActivitiesTable
          className="mb-12"
        />

        {nftData.$value && (
          <MoreItems nft={nftData.$value} />
        )}

      </PageLayout.Body>

      <PageLayout.Footer>
        <Footer />
      </PageLayout.Footer>
    </PageLayout>
  )
})
