import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { useStores } from '@/hooks/useStores'
import { PlaceBidStage } from '@/stores/flows/PlaceBidFlow.store'

import { FollowStepsModal } from '../DirectBuyNftFlow/FollowStepsModal'
import { FinalModal } from '../DirectBuyNftFlow/FinalModal'
import { PlaceBidModal } from './PlaceBidModal'
import { FollowStep, StepMode } from '../components/FollowStep'


type Screen =
  | 'placeBid'
  | 'followSteps'
  | 'final'

export const PlaceBidFlow = observer(
  () => {

    const intl = useIntl()

    const {
      flowsStore,
    } = useStores()

    const [screen, setScreen] = useState<Screen>('placeBid')

    const flow = flowsStore.$placeBidFlow
    const stage = flow?.$stage

    useEffect(() => {
      if (stage === PlaceBidStage.placeBid) setScreen('placeBid')

      if (stage === PlaceBidStage.confirmation) setScreen('followSteps')

      if (stage === PlaceBidStage.confirmed) {
        setScreen('followSteps')

        setTimeout(() => setScreen('final'), 800)
      }

      if (stage === PlaceBidStage.error) setScreen('followSteps')
    }, [stage])

    useEffect(() => () => flow?.dispose(), [flow])

    if (!flow) return null

    if (screen === 'placeBid') {
      const { nft } = flow

      if (!nft) return null

      return (
        <PlaceBidModal
          nft={nft}
          onClose={flow.dispose}
        />
      )
    }

    if (screen === 'followSteps') {
      let stepMode: StepMode|null = null

      if (stage === PlaceBidStage.confirmed) stepMode = 'ok'
      if (stage === PlaceBidStage.error) stepMode = 'error'

      return (
        <FollowStepsModal
          onClose={flow.dispose}
        >
          <FollowStep
            title={intl.formatMessage({
              id: 'PlaceBidFlow.title.Approve-asset',
              defaultMessage: 'Approve asset',
            })}
            text={intl.formatMessage({
              id: 'PlaceBidFlow.text.Approve-asset',
              defaultMessage: 'This transaction is conducted only once per asset type',
            })}
            mode={stepMode}
          />

          <FollowStep
            title={intl.formatMessage({
              id: 'PlaceBidFlow.title.Checking-payment',
              defaultMessage: 'Checking payment',
            })}
            text={intl.formatMessage({
              id: 'PlaceBidFlow.text.Checking-payment',
              defaultMessage: 'We are checking your payment. It may take some time.',
            })}
            mode={stepMode}
          />
        </FollowStepsModal>
      )
    }

    if (screen === 'final') {
      return (
        <FinalModal
          mode="placed-bid"
          flowStatus={flow.$txStatus}
          nft={flow.nft}
          txHash={flow.$txHash}
          onClose={flow.dispose}
        />
      )
    }

    return null
  },
)
