import { Delim } from '@/components/common/Delim'
import { Price } from '@/components/common/Price'
import { cn } from '@/util/exports'
import { CurrencyAndTotal } from '@/api/stubs/money'

import style from './style.module.scss'

type Props = {
  title: string | JSX.Element,
  variant?: 'bright' | 'default'
  price?: CurrencyAndTotal
}

export function FlowModalRow({ title, variant = 'default', price }: Props) {
  return (
    <div className={style.row}>
      <span className={cn((variant === 'bright') && style.bright)}>
        {title}
      </span>
      <span>
        $
        {!!price && <Price fromUnit={price} to="usdt" round={2} />}

        <Delim vertical className="inline-block mx-2 -mb-[2px]" />
        <span className={style.cryptoPrice}>
          {!!price && <Price fromUnit={price} round={3} showSymbol />}
        </span>
      </span>
    </div>
  )
}
