import {
  Route, Routes, useLocation,
} from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import BigNumber from 'bignumber.js'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { IntlProvider } from 'react-intl'

import * as enLocale from '@/assets/lang/en.json'
import * as ruLocale from '@/assets/lang/ru.json'
import { AppLocales } from '@/stores/Locale.store'
import { cn } from '@/util/exports'
import { ProfilePage } from '@/components/pages/ProfilePage'
import { useEvmWallet } from '@/stores/EvmWalletService'

import style from './App.module.scss'
import { UIPage } from './components/pages/UIPage'
import { MarketplacePage } from './components/pages/MarketplacePage'
import { ModalContainer } from './components/common/Modal/ModalContainer'
import { CollectionPage } from './components/pages/CollectionPage'
import { NFTPage } from './components/pages/NFTPage'
import Erro404Page from './components/pages/Error404Page'
import { ROUTES } from './routing/routes'
import { AuthenticationPage } from './components/pages/AuthenticationPage'
import { OverviewPage } from './components/pages/OverviewPage'
import { useStores } from './hooks/useStores'
import { RouteScrollTop } from './components/common/RouteScrollTop'
import { Flows } from './components/common/Flows'
import { CurrenciesService } from './stores/Currencies.store'

BigNumber.config({ EXPONENTIAL_AT: 257 })

function AppContent() {
  const {
    routingStore,
  } = useStores()

  useEffect(() => void CurrenciesService.load(), [])

  const loc = useLocation()

  routingStore.updatePathChange(loc.pathname)

  useEvmWallet()

  return (
    <div className={cn(style.app, style.dark)}>
      <ModalContainer />

      <RouteScrollTop />

      <Routes>
        {/* Its a page for debug */}
        <Route path="/ui" element={<UIPage />} />


        {typeof window !== 'undefined' && (
          <>
            <Route path={ROUTES.root} element={<OverviewPage />} />

            <Route path={ROUTES.collectionPage} element={<CollectionPage />} />

            <Route path={ROUTES.nftPage} element={<NFTPage />} />

            <Route path={ROUTES.marketplacePage} element={<MarketplacePage />} />

            <Route path={ROUTES.userMarketPage} element={<ProfilePage />} />

            <Route path={ROUTES.authentication} element={<AuthenticationPage />} />

            <Route path={ROUTES.error404} element={<Erro404Page />} />
          </>
        )}

      </Routes>

      <Flows />
    </div>
  )
}

export const App = observer(() => {
  const { localeStore } = useStores()

  return (
    <IntlProvider
      locale={localeStore.getCurrentLocale}
      messages={localeStore.getCurrentLocale === AppLocales.EN ? enLocale : ruLocale}
      defaultLocale="en"
    >
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        <AppContent />
      </QueryParamProvider>
    </IntlProvider>
  )
})
