/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlockchainRecord } from '../models/BlockchainRecord';
import type { Currency } from '../models/Currency';

import type { CancelablePromise } from '@/api/util/CancelablePromise'
import { OpenAPI } from '@/api/util/OpenAPI'
import { request as __request } from '@/api/util/request'

export class DictService {

    /**
     * @returns BlockchainRecord OK
     * @throws ApiError
     */
    public static getBlockchain(): CancelablePromise<Array<BlockchainRecord>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/blockchain',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @returns Currency OK
     * @throws ApiError
     */
    public static getCurrency(): CancelablePromise<Array<Currency>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/currency',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
