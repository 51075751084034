
import { ChangeEvent } from 'react'
import { useIntl } from 'react-intl'

import { Input } from '@/components/common/Input'

const FROM = 'from'
const TO = 'to'

export type PriceRangeFormShape = {
  [FROM]: string,
  [TO]: string,
}

type Props = {
  range: { from?: string, to?: string }
  onRange: (range: { from: string, to: string }) => void
}

export function PriceRangeForm({
  onRange,
  range,
}: Props) {
  const intl = useIntl()

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.name === FROM) {
      onRange({
        to: range.to || '',
        from: e.target.value || '',
      })
    }

    if (e.target.name === TO) {
      onRange({
        to: e.target.value || '',
        from: range.from || '',
      })
    }
  }

  return (
    <div
      className="flex flex-col flex-nowrap gap-[10px]"
    >
      <Input
        wide
        type="number"
        name={FROM}
        placeholder={intl.formatMessage({
          id: 'PriceRangeForm.From',
          defaultMessage: 'From',
        })}
        value={range.from}
        onChange={handleChange}
      />

      <Input
        wide
        type="number"
        name={TO}
        placeholder={intl.formatMessage({
          id: 'PriceRangeForm.To',
          defaultMessage: 'To',
        })}
        value={range.to}
        onChange={handleChange}
      />
    </div>
  )
}
