/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef, useState } from 'react'

import { cn } from '@/util/exports'
import { useResizeObserver } from '@/hooks/useResizeObzerver'
import { ClassProp } from '@/util/react-props'

import { LoadingBox } from '../LoadingBox'
import style from './style.module.scss'

const LOAD_TIMEOUT_MS = 1500

type Props = {
  url: string

  __throttleMS?: number
}
& ClassProp

export function MediaPreview({
  url,
  className,
  __throttleMS = 0,
}: Props) {
  const [containerRef, setContainerRef] = useState<HTMLDivElement|null>(null)
  const [imgRef, setImgRef] = useState<HTMLImageElement|null>(null)
  const loadTimeoutRef = useRef<any>()

  const [loading, setLoading] = useState(false)
  const [imgStyle, setImgStyle] = useState('')
  const [containerStyle, setContainerStyle] = useState('')

  const entry = useResizeObserver(containerRef)

  function reflowImgStick(containerEntry: typeof entry, imgElement: typeof imgRef) {
    if (!containerEntry) return
    if (!imgElement) return

    const imgNormalHeight = (imgElement.naturalHeight / imgElement.naturalWidth) * containerEntry.contentRect.width

    // By max height
    if (imgNormalHeight <= containerEntry.contentRect.height) {
      setImgStyle(style.maxHeight)
      setContainerStyle(style.maxHeight)
    }
    // By max width
    else {
      setImgStyle(style.maxWidth)
      setContainerStyle(style.maxWidth)
    }
  }

  function handleLoaded() {
    setTimeout(() => {
      setLoading(false)
    }, __throttleMS)

    reflowImgStick(entry, imgRef)
  }

  useEffect(() => {
    reflowImgStick(entry, imgRef)
  }, [entry, imgRef])

  // set loading true, when url changing
  useEffect(() => {
    setLoading(true)

    clearTimeout(loadTimeoutRef.current)

    loadTimeoutRef.current = setTimeout(() => {
      setLoading(false)
    }, LOAD_TIMEOUT_MS)
  }, [url])

  return (
    <LoadingBox loading={loading} className="w-full h-full">
      <div
        ref={setContainerRef}
        className={cn(
          'w-full h-full overflow-hidden',
          style.container,
          containerStyle,
          className,
        )}
      >
        <img
          ref={setImgRef}
          src={url}
          alt=" "
          onLoad={handleLoaded}
          className={cn(
            style.image,
            imgStyle,
          )}
        />
      </div>
    </LoadingBox>
  )
}
