import { PropsWithChildren } from 'react'

import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'

import style from './index.module.scss'


type Props = ClassProp & {}
export function PageLayoutFooter({
  className,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className={cn(style.footer, className)}>
      {children}
    </div>
  )
}
