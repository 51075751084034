import copy from 'copy-to-clipboard'
import { useIntl } from 'react-intl'

import { Block } from '@/components/common/Block'
import { ReactComponent as VerifiedSVG } from '@/assets/verified.svg'
import { CollectionsPaginationResponse } from '@/api/luckyswap'
import { ellipsis } from '@/util/ellipsis'
import { Avatar } from '@/components/common/Avatar'
import { ReactComponent as Copy } from '@/assets/copy.svg'
import { ExternalLink } from '@/components/common/ExternalLink'
import { useStores } from '@/hooks/useStores'


type CollectionProfileProps = {
  preview?: string;
  collectionName?: string;
  description?: string;
  isVerified: boolean;
  owner?: CollectionsPaginationResponse['items'][0]['owner'];
  socialLinks: Array<{ type: string; link: string }>;
  address?: string;
}

export function CollectionProfile({
  owner, collectionName, isVerified, description, preview, socialLinks, address,
}: CollectionProfileProps) {
  const intl = useIntl()
  const {
    profileStore,
  } = useStores()

  return (
    <div>
      <div className="flex flex-row items-center">
        <Avatar avatarImage={preview} className="min-w-[100px] min-h-[100px] mr-3.5 rounded" />
        <div className="flex flex-col">
          <span className='text-[18px] font-normal font-["JetBrains_Mono"]'>
            {collectionName}
          </span>
          <div className="flex flex-row gap-x-1 items-center">
            <span className="text-[16px] font-normal text-[color:var(--text-secondary)]">
              {intl.formatMessage(
                {
                  id: 'SignMessage.Created-by',
                  defaultMessage: 'Created{space}by',
                },
                {
                  space: <>&nbsp;</>,
                },
              )}
            </span>
            {isVerified && <VerifiedSVG />}
            <span className="text-[16px] font-normal">
              {owner?.name || ellipsis(owner?.address.address || '', 5, 4) || 'N/A'}
            </span>
          </div>
          <div className="flex items-center mt-2 cursor-pointer" onClick={() => copy(address!)}>
            <Copy />
            <span className="text-base text-[color:var(--text-secondary)] hover:text-[color:var(--active)] transition-colors">
              {ellipsis(address ?? '', 7, 4)}
            </span>
          </div>
        </div>
      </div>
      <p className="text-[16px] my-6 font-normal text-[color:var(--text-secondary)]">
        {description}
      </p>
      <div className="flex flex-row flex-wrap gap-1">
        {socialLinks.map(({ type, link }) => (
          <ExternalLink
            href={link} onClick={() => {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'goto',
                category: 'social',
                path: link, // path of external link
                s_network: type, // social network name [discord|instagram etc…]
                id: profileStore.profileData.$value?.address.address, // UserId
                timestamp: new Date().getTime(),
              })

            }}
          >
            <Block
              size="xs"
              className="text-[color:var(--text-secondary)] select-none"
            >
              {type}
            </Block>
          </ExternalLink>
        ))}
      </div>
    </div>
  )
}
