import { useContext } from 'react'

export function useCtx<T>(ctx: React.Context<T>, optionalProvided: true): T | undefined
export function useCtx<T>(ctx: React.Context<T>): NonNullable<T>

export function useCtx<T>(ctx: React.Context<T>, optionalProvided = false) {
  const result = useContext(ctx)

  if (!optionalProvided && !result) {
    throw new Error(`${ctx.displayName || 'Unknown'} context must be defined`)
  }

  return result
}
