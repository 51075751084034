export type ArrayToMap<T extends readonly string[]> = {
  [k in T[number]]: k
}

export function arrayToMap<T extends readonly string[]>(arr: T) {
  return arr.reduce(
    (acc, item) => ({ ...acc, [item]: item }),
    {} as ArrayToMap<typeof arr>,
  )
}
