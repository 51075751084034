/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Nft } from '../models/Nft';
import type { NftFilter } from '../models/NftFilter';
import type { NftPaginationResponse } from '../models/NftPaginationResponse';

import type { CancelablePromise } from '@/api/util/CancelablePromise'
import { OpenAPI } from '@/api/util/OpenAPI'
import { request as __request } from '@/api/util/request'

export class NftService {

    /**
     * @param collectionSlug
     * @param tokenId
     * @returns Nft OK
     * @throws ApiError
     */
    public static getCollection(
        collectionSlug: string,
        tokenId: string,
    ): CancelablePromise<Nft> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collection/{collectionSlug}/{tokenId}',
            path: {
                'collectionSlug': collectionSlug,
                'tokenId': tokenId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param body
     * @returns NftPaginationResponse OK
     * @throws ApiError
     */
    public static postNftFilter(
        body: NftFilter,
    ): CancelablePromise<NftPaginationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/nft/filter',
            body: body,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
