import { observer } from 'mobx-react-lite'
import { useIntl } from 'react-intl'

import { useStores } from '@/hooks/useStores'

import { MakeOfferModal } from './MakeOfferModal'
import { FinalModal } from '../DirectBuyNftFlow/FinalModal'
import { FollowStepsModal } from '../DirectBuyNftFlow/FollowStepsModal'
import { FollowStep } from '../components/FollowStep'


export const MakeOfferFlow = observer(() => {
  const intl = useIntl()
  const { flowsStore } = useStores()
  const flow = flowsStore.$makeOfferFlow
  if (!flow) return null

  const status = flow.$status

  if (status === 'configuration') {
    return <MakeOfferModal />
  }

  if (flow.$allStepsOk) {
    return (
      <FinalModal
        flowStatus={flow.$txStatus}
        mode="made-offer"
        nft={flow.nft}
        txHash={flow.$txHash}
        onClose={flow.dispose}
      />
    )
  }

  return (
    <FollowStepsModal onClose={flow.dispose}>
      <FollowStep
        title={intl.formatMessage({
          id: 'MakeOfferFlow.title.Approve-asset',
          defaultMessage: 'Approve asset',
        })}
        text={intl.formatMessage({
          id: 'MakeOfferFlow.text.Approve-asset',
          defaultMessage: 'This transaction is conducted only once per asset type',
        })}
        mode={flow.$stepsResults[0]}
      />

      <FollowStep
        title={intl.formatMessage({
          id: 'MakeOfferFlow.title.Checking-payment',
          defaultMessage: 'Checking payment',
        })}
        text={intl.formatMessage({
          id: 'MakeOfferFlow.text.Checking-payment',
          defaultMessage: 'We are checking your payment. It may take some times.',
        })}
        mode={flow.$stepsResults[1]}
      />
    </FollowStepsModal>
  )
})
