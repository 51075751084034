/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */


import { useEffect, useRef } from 'react'

import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'
import { useToggle } from '@/hooks/useToggle'

import style from './index.module.scss'

type Props = {
  toggled?: boolean,
  onToggle?: (toggled: boolean)=>void
}
& ClassProp

export function Burger({
  toggled: toggledProp = false,
  onToggle,
  ...restProps
}: Props) {
  const ref = useRef<HTMLDivElement>(null)

  const [toggled, , setToggle] = useToggle(toggledProp, ref)

  useEffect(() => {
    setToggle(toggledProp)
  }, [toggledProp])

  useEffect(() => {
    onToggle?.(toggled)
  }, [toggled])

  return (
    <div
      ref={ref}
      className={cn(style.burger, restProps.className)}
    >
      <div className={cn(style.stick, { [style.cross]: toggled })} />
      <div className={cn(style.stick, { [style.cross]: toggled })} />
      <div className={cn(style.stick, { [style.cross]: toggled })} />
    </div>
  )
}
