
import { CollectionFilter, NftFilter } from '@/api/luckyswap'
import { FormSaleType } from '@/components/modules/forms/SaleTypeForm'
import { SearchSort } from '@/components/pages/MarketplacePage/components/SearchBar'
import { NFTS_FILTER_INITIAL } from '@/components/pages/MarketplacePage/components/NFTsFilter/util'

function convertSortField(sort: SearchSort) {
  const sortBy: NftFilter['sortBy'] = {}

  switch (sort) {
    case SearchSort.price_asc:
      sortBy.price = NftFilter.price.ASC
      break
    case SearchSort.price_desc:
      sortBy.price = NftFilter.price.DESC
      break

    case SearchSort.published_asc:
      sortBy.published = NftFilter.published.ASC
      break
    case SearchSort.published_desc:
      sortBy.published = NftFilter.published.DESC
      break

    default:
      break
  }

  return Object.keys(sortBy).length ? sortBy : undefined
}

export function makeNFTsRequest(
  filter: typeof NFTS_FILTER_INITIAL,
  search: string,
  sort?: SearchSort,
  onlyWithActiveBids?: boolean,
  onlyWithActiveOffers?: boolean,
  owner?: string,
  offerer?: string,
  bidder?: string,

) {
  const status = filter.saleType !== FormSaleType.all ? [filter.saleType] : []

  const traits: NftFilter['traits'] = filter.attributes.map(attr => {
    const [traitType, traitValue] = attr.split('---')
    return { format: 'textual', traitType, traitValue }
  })

  const req: NftFilter = {
    blockchain: filter.blockchains,
    collection: filter.collection || undefined,
    status,
    owner:  owner === '' ? undefined : (owner?.toLowerCase() ?? undefined),
    offerer: offerer === '' ? undefined : (offerer?.toLowerCase() ?? undefined),
    bidder: bidder === '' ? undefined : (bidder?.toLowerCase() ?? undefined),
    onlyWithActiveBids,
    onlyWithActiveOffers,
    skip: 0,
    take: 0,
    traits,
    text: search,
    sortBy: sort ? convertSortField(sort) : undefined,
  }

  return req
}

export function makeCollectionsRequest(
  name?: string,
  slug?: string,
  skip = 0,
  take = 1,
) {

  const req: CollectionFilter = {
    skip,
    take,
    name,
    slug,
  }

  return req
}
