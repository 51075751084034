/* eslint-disable consistent-return */
/* eslint-disable func-call-spacing */
/* eslint-disable no-spaced-func */
import Multimap from 'multimap'
import { useEffect, useState } from 'react'

import { nil } from '@/util/nil'

const el_callbackMap = new Multimap<Element, (entry: ResizeObserverEntry) => void>()

const observer = typeof window !== 'undefined' ? new ResizeObserver(entries => {
  entries.forEach(entry => {
    const cbs = el_callbackMap.get(entry.target) || []

    cbs.forEach(cb => cb(entry))
  })
}) : null

export function useResizeObserverCB(
  el: Element | nil,
  cb: (entry: ResizeObserverEntry) => void,
) {
  useEffect(() => {
    if (!el || !observer) return

    el_callbackMap.set(el, cb)

    observer.observe(el)

    return () => {
      observer.unobserve(el)

      el_callbackMap.delete(el, cb)
    }
  }, [el, cb])
}


export function useResizeObserver(el: Element | nil) {
  const [entry, setEntry] = useState<ResizeObserverEntry>()

  useResizeObserverCB(el, setEntry)

  return entry
}
