/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Activity } from '../models/Activity';
import type { ActivityWithItem } from '../models/ActivityWithItem';
import type { GetActivityByUserPayload } from '../models/GetActivityByUserPayload';
import type { GetActivityPayload } from '../models/GetActivityPayload';

import type { CancelablePromise } from '@/api/util/CancelablePromise'
import { OpenAPI } from '@/api/util/OpenAPI'
import { request as __request } from '@/api/util/request'

export class ActivityService {

    /**
     * @param body
     * @returns Activity OK
     * @throws ApiError
     */
    public static postActivityFilter(
        body: GetActivityPayload,
    ): CancelablePromise<Array<Activity>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/activity/filter',
            body: body,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * @param body
     * @returns ActivityWithItem OK
     * @throws ApiError
     */
    public static postActivityListByUser(
        body: GetActivityByUserPayload,
    ): CancelablePromise<Array<ActivityWithItem>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/activity/list_by_user',
            body: body,
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
