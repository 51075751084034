import { AbiItem } from 'web3-utils'
import { action } from 'mobx'
import BigNumber from 'bignumber.js'

import { useEvmWallet } from '@/stores/EvmWalletService'
import BasicTokenAbi from '@/abi/BasicToken.json'
import MarketplaceAbi from '@/abi/Marketplace.json'
import { ENV } from '@/util/env'
import { BasicTokenTypo, MarketplaceTypo } from '@/abi/types'
import { CurrencyAndTotal, Price } from '@/api/stubs/money'
import { Nft } from '@/api/luckyswap'

export class NftWeb3Service {
  public static buyItem(
    collectionAdr: string,
    nftId: string,
    price?: Price,
  ) {
    const { web3, address } = useEvmWallet()

    if (!web3) return [undefined]

    const marketplaceContract = new web3.eth.Contract(
      MarketplaceAbi.abi as unknown as AbiItem,
      ENV.MARKETPLACE_CONTRACT_ADR,
    ) as unknown as MarketplaceTypo

    return [
      marketplaceContract.methods
        .buyItem(collectionAdr, nftId)
        .send({
          from: address,
          value: price?.total,
        })
        .once(
          'transactionHash',
          async transactionHash => console.log(`BuyItem transactionHash: ${transactionHash}`),
        ),
    ]
  }

  public static directSaleItem(
    collectionAdr: string,
    nftId: string,
    price: CurrencyAndTotal,
    transactionHashCallback?: (hash: string) => void,
  ) {
    const { web3, address } = useEvmWallet()

    if (!web3) return [undefined]

    const marketplaceContract = new web3.eth.Contract(
      MarketplaceAbi.abi as unknown as AbiItem,
      ENV.MARKETPLACE_CONTRACT_ADR,
    ) as unknown as MarketplaceTypo

    return [
      marketplaceContract.methods
        .listItemOnDirectSale(
          nftId,
          collectionAdr,
          [
            new BigNumber(price.total).shiftedBy(price.decimals).toString(),
            price.address,
          ],
        )
        .send({ from: address })
        .once(
          'transactionHash',
          async (transactionHash: string) => {
            console.log(`listItemOnDirectSale transactionHash: ${transactionHash}`)
            transactionHashCallback?.(transactionHash)
          },
        )]
  }

  public static async cancelDirectSaleItem(
    collectionAdr: string,
    nftId: string,
    nft?: Nft,
    transactionHashCallback?: (hash: string) => void,
  ) {
    try {
      const { web3, address } = useEvmWallet()
      if (!web3) return

      const marketplaceContract = new web3.eth.Contract(
        MarketplaceAbi.abi as unknown as AbiItem,
        ENV.MARKETPLACE_CONTRACT_ADR,
      ) as unknown as MarketplaceTypo

      await marketplaceContract.methods
        .removeItem(collectionAdr, nftId)
        .send({ from: address })
        .once(
          'transactionHash',
          async (transactionHash: string) => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'remove_from_sale_2',
              category: 'sale',
              nft: nft?.name, // name of nft
              collection: nft?.collection.name, // name of collection
              path: `/collections/${nft?.collection.slug}/nfts/${nft?.id}`, // path of nft
              id: address, // UserId
              timestamp: new Date().getTime(),
            })
            console.log(`cancelDirectSaleItem transactionHash: ${transactionHash}`)
            transactionHashCallback?.(transactionHash)
          },
        )
    }
    catch {
      // todo
    }
  }

  public static async makeBid(
    collectionAdr: string,
    nftId: string,
    price: string,
    isErc20: boolean,
  ) {
    const { web3, address } = useEvmWallet()

    if (!web3) return [undefined]

    const marketplace = new web3.eth.Contract(
      MarketplaceAbi.abi as any,
      ENV.MARKETPLACE_CONTRACT_ADR,
    ) as unknown as MarketplaceTypo

    return [
      marketplace.methods
        .makeBid(
          nftId,
          collectionAdr,
          price,
        )
        .send({
          from: address,
          value: isErc20 ? undefined : price,
        }),
    ]
  }

  /**
   * @param startTimeSec unix timestamp sec
   */
  public static async listItemOnAuction(
    collectionAdr: string,
    nftId: string,
    price: CurrencyAndTotal,
    startTimeSec: number,
    durationSec: number,
    minStep?: string,
  ) {
    const { web3, address } = useEvmWallet()

    if (!web3) return [undefined]

    const basicTokenCtt = new web3.eth.Contract(
      BasicTokenAbi.abi as unknown as AbiItem,
      collectionAdr,
    ) as unknown as BasicTokenTypo

    await basicTokenCtt.methods
      .approve(ENV.PROXY_CONTRACT_ADR, nftId)
      .send({ from: address })

    const marketCtt = new web3.eth.Contract(
      MarketplaceAbi.abi as any,
      ENV.MARKETPLACE_CONTRACT_ADR,
    ) as any as MarketplaceTypo

    const prome = marketCtt.methods
      .listItemOnAuction(
        nftId,
        collectionAdr,
        [price.total, price.address],
        startTimeSec,
        durationSec,
        minStep || 0,
      )
      .send({ from: address })

    return [prome]
  }

  @action.bound public static async endAuction(
    collectionAdr: string,
    nftId: string,
  ) {
    try {
      const { web3, address } = useEvmWallet()

      if (!web3) return false

      const marketCT = new web3.eth.Contract(
      MarketplaceAbi.abi as any,
      ENV.MARKETPLACE_CONTRACT_ADR,
      ) as any as MarketplaceTypo

      await marketCT.methods
        .finishAuction(
          collectionAdr,
          nftId,
        )
        .send({ from: address })

      return true
    }
    catch (err) {
      console.error(err)

      return false
    }
  }
}
