import {
  action, computed, makeObservable, observable,
} from 'mobx'

import { CancelablePromise } from '@/api/util/CancelablePromise'


type Loader<T, P> = (
  params: P | undefined,
  state: { ctx: ValueStore<T, P>, set: (value: T) => void }
) => Promise<T | void>

type LoadProps<P> = {
  params?: P
  skipIfLoaded?: boolean
}

export class ValueStore<T, P = void> {
  @observable public $value: T

  @observable public $isLoaded = false
  @observable public $isLoading = false
  @observable public $error = false

  @observable public $lastParams: P | undefined = undefined

  private currentLoader: CancelablePromise<void | T> | undefined = undefined

  constructor(
    private readonly initial: T,
    private readonly loader: Loader<T, P>,
  ) {
    this.$value = this.initial
    makeObservable(this)
  }

  @computed public get $valueExists() {
    if (!this.$isLoaded) return false
    return this.$isLoaded && !!this.$value
  }

  @action.bound public async load(props?: LoadProps<P>) {
    const { skipIfLoaded, params } = props || {}

    if (skipIfLoaded && this.$isLoaded) return

    this.currentLoader?.cancel()

    this.$isLoading = true
    this.$lastParams = params

    const setValue = (value: T) => {
      if (this.currentLoader?.isCancelled) return

      this.$value = value
    }

    try {
      this.currentLoader = CancelablePromise.fromPromise(
        this.loader(
          params,
          {
            set: setValue,
            ctx: this,
          },
        ),
      )

      const result = await this.currentLoader

      if (result !== undefined) this.$value = result

      this.currentLoader = undefined

      this.$error = false
      this.$isLoading = false
      this.$isLoaded = true
    }
    catch (e) {
      this.$error = true
      this.$isLoading = false
      this.$isLoaded = false
    }
  }

  @action public clear() {
    this.currentLoader?.cancel()

    this.$value = this.initial
    this.$error = false
    this.$isLoading = false
    this.$isLoaded = false
  }
}

export function readonly<T extends ValueStore<any, any>>(store: T) {
  return store as Omit<T, 'load'>
}
