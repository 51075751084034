import { PARAMS } from './params'
import { PATHS } from './paths'

export const ROUTES = {
  root: '/',
  marketplacePage: '/market',
  userMarketPage: `/market/user/:${PARAMS.userId}`,
  collectionPage: `${PATHS.collections}/:${PARAMS.collectionId}`,
  nftPage: `${PATHS.collections}/:${PARAMS.collectionId}${PATHS.nfts}/:${PARAMS.nftId}`,
  userPage: `${PATHS.users}/:${PARAMS.userId}`,
  authentication: PATHS.authentication,
  error404: '*',

} as const
