import { Block } from '@/components/common/Block'
import { cn } from '@/util/exports'

type Props = {
  title: string
  text: string | JSX.Element
  icon: JSX.Element
}

export function SellTypeItem({
  title,
  text,
  icon,
}: Props) {
  return (
    <Block
      size="s"
      className={cn(
        'mb-4 hover:bg-[color:var(--bg-3)] cursor-pointer',
        'flex flex-row flex-nowrap items-center gap-4',
      )}
    >
      <div className=" shrink-0">
        {icon}
      </div>

      <div>
        <div>
          {title}
        </div>

        <div className="text-sm text-[color:var(--text-secondary)]">
          {text}
        </div>
      </div>
    </Block>
  )
}
