/* eslint-disable react/function-component-definition */
import {
  forwardRef,
  PropsWithChildren,
} from 'react'

import { PageLayoutBody } from './body/PageLayoutBody'
import { PageLayoutFooter } from './footer'
import { PageLayoutHeader } from './header/PageLayoutHeader'
import style from './index.module.scss'

type Props = {
  onScroll?: React.UIEventHandler<HTMLDivElement>
}

interface PageLayout extends ReturnType<typeof forwardRef<HTMLDivElement, PropsWithChildren<Props>>> {
  Body: typeof PageLayoutBody
  Header: typeof PageLayoutHeader
  Footer: typeof PageLayoutFooter
}

export const PageLayout: PageLayout = forwardRef(({
  onScroll,
  children,
}, ref) => (
  <div
    id="layout"
    ref={ref}
    onScroll={onScroll}
    className={style.page_layout}
  >
    {children}
  </div>
)) as PageLayout

PageLayout.Body = PageLayoutBody
PageLayout.Header = PageLayoutHeader
PageLayout.Footer = PageLayoutFooter
