import { MarketplacePageStore } from '@/stores/MarketplacePage.store'
import { CollectionPageStore } from '@/stores/CollectionPage.store'
import { NFTPageStore } from '@/stores/NFTPage.store'
import { RoutingStore } from '@/stores/Routing.store'
import { ProfileStore } from '@/stores/Profile.store'
import { useEvmWallet } from '@/stores/EvmWalletService'
import { FlowsStore } from '@/stores/flows/FlowsStore'
import { LocaleStore } from '@/stores/Locale.store'

const wallet = useEvmWallet()
const nftPageStore = new NFTPageStore()
const marketplacePageStore = new MarketplacePageStore()
const collectionPageStore = new CollectionPageStore()

const stores = {
  marketplacePageStore,
  collectionPageStore,
  nftPageStore,
  routingStore: new RoutingStore(),
  profileStore: new ProfileStore(wallet),
  flowsStore: new FlowsStore(
    nftPageStore,
    marketplacePageStore,
    collectionPageStore,
  ),
  localeStore: new LocaleStore(),
}

export function useStores() {
  return stores
}
