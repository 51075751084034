import { MouseEvent, useState } from 'react'

import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'
import { nil } from '@/util/nil'
import { ReactComponent as CheckSVG } from '@/assets/check_small.svg'

import { DropdownList } from '../DropdownList'

export type SelectItem = {
  id: string
  content: JSX.Element | string
}

type Props = {
  items: SelectItem[]
  title?: string | JSX.Element | nil
  selectId: string
  onSelect?: (id: string) => void
}
& ClassProp

export function Select({
  items,
  title,
  selectId,
  onSelect,
  className,
}: Props) {
  const [opened, setOpened] = useState(false)

  function handleItemClick(e: MouseEvent) {
    e.stopPropagation()
    const target = e.currentTarget as HTMLElement
    const { id: dataId } = target.dataset

    if (!dataId) return

    setOpened(false)
    onSelect?.(dataId)
  }

  const selectedItem = items.find(item => item.id === selectId)

  return (
    <DropdownList
      title={title || (selectedItem ? selectedItem.content : '<no data>')}
      opened={opened}
      onChange={setOpened}
      className={cn(
        `bg-[color:var(--bg-1)]
        w-[230px] min-h-[42px]
        text-left
        shrink-0`,
        className,
      )}
      dropYOffset="20px"
    >
      {items.map(item => {
        const isSelected = item.id === selectedItem?.id

        return (
          <div
            key={item.id}
            className="contents"
            data-id={item.id}
            onClick={handleItemClick}
          >
            <DropdownList.Item className={cn(
              'w-[230px]',
              isSelected && 'brightness-125 flex items-center justify-between',
            )}
            >
              {item.content}
              {isSelected && <CheckSVG />}
            </DropdownList.Item>
          </div>
        )
      })}
    </DropdownList>
  )
}
