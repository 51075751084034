import { PropsWithChildren, useRef } from 'react'

import { ClassProp } from '@/util/react-props'
import { useToggle } from '@/hooks/useToggle'
import { cn } from '@/util/exports'

import style from './style.module.scss'

type Props = {
  title: string | JSX.Element

  initOpened?: boolean

  contentClassName?: string
  titleClassName?: string
}
& ClassProp

export function Accordion({
  title,

  initOpened,

  children,

  contentClassName,
  className,
  titleClassName,
}: PropsWithChildren<Props>) {
  const titleRef = useRef<HTMLDivElement>(null)

  const [opened] = useToggle(initOpened || false, titleRef)

  return (
    <div
      className={className}
    >
      <div
        ref={titleRef}
        className={cn(
          'flex flex-row flex-nowrap items-center justify-between',
          'cursor-pointer select-none',
          'py-2',
          titleClassName,
        )}
      >
        {title}

        <div className={cn(
          style.arrow,
          opened && style.open,
        )}
        />
      </div>

      {opened && (
        <div className={cn(
          'mt-3',
          contentClassName,
        )}
        >
          {children}
        </div>
      )}
    </div>
  )
}
