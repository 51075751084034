import { defineMessage } from 'react-intl'

import { ROUTES } from '@/routing/routes'
import { ReactComponent as OverviewSVG } from '@/assets/overview.svg'
import { ReactComponent as LabelSVG } from '@/assets/label.svg'
import { ReactComponent as ChangeSVG } from '@/assets/change_circle.svg'
import { QUERIES } from '@/queries'
import { SaleType } from '@/api/stubs/sale-type'


export const OVERVIEW_LINKS = {
  overview: {
    path: ROUTES.root,
    title: defineMessage({
      id: 'OVERVIEW_LINKS.Overview',
      defaultMessage: 'Overview',
    }),
    text: defineMessage({
      id: 'OVERVIEW_LINKS.explore',
      defaultMessage: 'Explore the latest important events on the marketplace and fresh selections',
    }),
    Icon: OverviewSVG,
  },

  marketplace: {
    path: ROUTES.marketplacePage,
    title: defineMessage({
      id: 'OVERVIEW_LINKS.Marketplace',
      defaultMessage: 'Marketplace',
    }),
    text: defineMessage({
      id: 'OVERVIEW_LINKS.Buy-sell',
      defaultMessage: 'Buy and sell various NFTs, find influencers',
    }),
    Icon: LabelSVG,
  },

  auction: {
    path: `${ROUTES.marketplacePage}?${QUERIES.saleType}=${SaleType.onAuction}`,
    title: defineMessage({
      id: 'OVERVIEW_LINKS.Auction',
      defaultMessage: 'Auction',
    }),
    text: defineMessage({
      id: 'OVERVIEW_LINKS.Compete',
      defaultMessage: 'Compete with other users for cool lots',
    }),
    Icon: ChangeSVG,
  },

  // TODO (dev stage 2, New Year)
  // analytics: {
  //   path: 'no analytics url',
  //   title: defineMessage({id: "OVERVIEW_LINKS.title.Analytics",defaultMessage: 'Analytics'}),
  //   text: defineMessage({id: "OVERVIEW_LINKS.text.Analytics",defaultMessage: 'Analyze collections, stay tuned'}),
  //   Icon: ChartSVG,
  // },
} as const
