import { Link } from 'react-router-dom'
import { Fragment } from 'react'

import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'

import styles from './index.module.scss'

type Item = {
    link?: string;
    text: string;
}

type Props = {
    items: Item[];
} & ClassProp

export function Breadcrumbs({
  items,
  className,
}: Props): JSX.Element {

  return (
    <nav className={cn(styles.breadcrumbs, className)}>
      {items.map((item, index) => {
        const isLast = index === items.length - 1

        return (
          <Fragment key={index}>
            {item.link ? (
              <Link
                to={item.link}
                className={cn({
                  [styles.current]: isLast,
                })}
              >
                {item.text}
              </Link>
            ) : (
              <span className={cn(isLast && styles.current)}>
                {item.text}
              </span>
            )}

            {!isLast && (
              <div className={styles.arrow} />
            )}
          </Fragment>
        )
      })}
    </nav>
  )
}
