import { generatePath, Link } from 'react-router-dom'
import { isMobile } from 'web3modal'
import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useIntl } from 'react-intl'
import { useEffect } from 'react'
import { useQueryParams } from 'use-query-params'
import { observer } from 'mobx-react-lite'

import { cn } from '@/util/exports'
import { ROUTES } from '@/routing/routes'
import { Button } from '@/components/common/Button'
import { makeNFTsRequest } from '@/util/filter-api'
import { queryToFilter } from '@/components/pages/MarketplacePage/components/NFTsFilter/util'
import { useStores } from '@/hooks/useStores'
import { NFTCard } from '@/components/modules/cards/NFTCard'
import { NFT_FILTER_QUERIES } from '@/components/modules/forms/form-query'
import { Nft } from '@/api/luckyswap'
import { PARAMS } from '@/routing/params'

import { BackgroundBlock } from '../BackgroundBlock'
import { ReactComponent as PiechartSVG } from './pie_chart.svg'
import { ReactComponent as GavelSVG } from './gavel.svg'
import { ReactComponent as CategorySVG } from './category.svg'
import style from './style.module.scss'

SwiperCore.use([Navigation])

export const PromoNftBlock = observer(() => {
  const intl = useIntl()
  const { marketplacePageStore: { nfts }} = useStores()
  const [filterQuery] = useQueryParams(NFT_FILTER_QUERIES)

  useEffect(() => {
    if (nfts.$itemsTotal) return
    nfts.load(makeNFTsRequest(queryToFilter(filterQuery), ''))
  }, [filterQuery, nfts.$itemsTotal])

  return (
    <BackgroundBlock className="flex flex-col md:flex-row py-10 px-6 mt-8 mb-16 md:mt-20 md:mb-24">
      <div className="md:w-1/2">
        <h1
          className={cn(
            'font-["JetBrains_Mono"] font-bold text-3xl md:text-5xl text-center md:text-left mb-8 md:mb-16',
            'text-transparent bg-clip-text bg-gradient-to-bl',
            'from-[color:#B3F74C] via-[color:#B3F74C] to-[color:#00F0FF]',
          )}
        >
          {
            intl.formatMessage(
              {
                id: 'PromoNFTBlock.onchain-mp',
                defaultMessage: 'Ончейн-NFT-маркеплейс c глубокой аналитикой рынка',
              },
            )
          }
        </h1>

        <div className="flex flex-nowrap flex-col md:flex-row items-center gap-2 md:gap-9 mb-6 md:mb-24">
          <div className={style.item}>
            <i className={style.icon}><PiechartSVG /></i>
            <span>
              {
                intl.formatMessage(
                  {
                    id: 'PromoNFTBlock.all-data-in-one-platform',
                    defaultMessage: 'Все данные и показатели NFT-мира на одной платформе',
                  },
                )
              }
            </span>
          </div>
          <div className={style.item}>
            <i className={style.icon}><GavelSVG /></i>
            <span>
              {
                intl.formatMessage(
                  {
                    id: 'PromoNFTBlock.sells-and-auctions',
                    defaultMessage: 'Прямые продажи и эксклюзивные аукционы',
                  },
                )
              }
            </span>
          </div>
          <div className={style.item}>
            <i className={style.icon}><CategorySVG /></i>
            <span>
              {
                intl.formatMessage(
                  {
                    id: 'PromoNFTBlock.you-own-collection-on-LuckySwap',
                    defaultMessage: 'Ваша собственная коллекция на LuckySwap',
                  },
                  {
                    bring: <br />,
                  },
                )
              }
            </span>
          </div>

        </div>

        <Link to={ROUTES.marketplacePage}>
          <Button wide={isMobile()} className='font-["JetBrains_Mono"]'>
            {
              intl.formatMessage(
                {
                  id: 'PromoNFTBlock.see-nft-catalog',
                  defaultMessage: 'Заглянуть в NFT-каталог',
                },
              )
            }
          </Button>
        </Link>
      </div>

      <div className={`md:w-1/2 mt-10 md:mt-0 ${style.customSwiperBullets}`}>
        <Swiper
          pagination spaceBetween={20}
          navigation
          className="max-w-[460px]"
        >
          {nfts.$items.slice(0, 3).map(data => (
            <SwiperSlide className="max-w-[460px] max-h-[460px]">
              <Link to={generatePath(ROUTES.nftPage, {
                [PARAMS.collectionId]: data.collection.slug,
                [PARAMS.nftId]: data.id,
              })}
              >
                <NFTCard data={data as Nft} />
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

    </BackgroundBlock>
  )
})
