import MetaMaskIcon from '@/assets/metamask.icon.svg'
import WalletConnectIcon from '@/assets/wallet-connect.icon.svg'
import PhantomIcon from '@/assets/phantom.icon.svg'
import CoinbaseIcon from '@/assets/coinbase.icon.svg'

export const WALLETS_LIST = [
  {
    icon: MetaMaskIcon,
    title: 'MetaMask',
    type: 'metamask',
  },
  {
    icon: WalletConnectIcon,
    title: 'WalletConnect',
    type: 'walletconnect',
  },
  {
    icon: PhantomIcon,
    title: 'Phantom',
    type: 'phantom',
    disabled: true,
  },
  {
    icon: CoinbaseIcon,
    title: 'Coinbase Wallet',
    type: 'coinbase',
    disabled: true,
  },
]
