/* eslint-disable react/destructuring-assignment */
import { ChangeEvent } from 'react'

import { DataProp } from '@/util/react-props'
import { cn } from '@/util/exports'

import style from './style.module.scss'

export type CheckboxProps = {
  onChecked?: (checked: boolean) => void
}
& DataProp
& React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function Checkbox({
  onChecked,
  children,
  className,
  ...props
}: CheckboxProps) {

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    props.onChange?.(e)

    onChecked?.(e.target.checked)
  }

  return (
    <label className={cn(style.checkbox, className)}>

      <input
        {...props}
        type="checkbox"
        onChange={handleChange}
      />

      <div className={style.box} />

      {children && (
        <span>{children}</span>
      )}
    </label>
  )
}
