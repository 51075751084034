import React from 'react'
import { useIntl } from 'react-intl'

import { Delim } from '@/components/common/Delim'
import { Button } from '@/components/common/Button'
import { ReactComponent as BoltSVG } from '@/assets/bolt.svg'
import { Nft } from '@/api/luckyswap'
import { useStores } from '@/hooks/useStores'
import { useNftStates } from '@/hooks/useNftStates'
import { Timer } from '@/components/common/Timer'
import { ClassProp } from '@/util/react-props'
import { cn } from '@/util/exports'
import { Price } from '@/components/common/Price'
import { getNFTPermissions } from '@/util/nft-permissions'

import { CardMetaBlock, CommonCard } from '../CommonCard'
import style from './style.module.scss'


type Props = {
  data: Nft
}
& ClassProp

export function NFTCard({
  data,
  className,
}:Props) {
  const { flowsStore, profileStore } = useStores()

  const { isNftOnAuction, isNftOnSale } = useNftStates(data)

  const { canDirectBuy } = getNFTPermissions(data)

  const blocks: CardMetaBlock[] = []

  const intl = useIntl()

  if (isNftOnSale) {
    blocks.push({
      title: intl.formatMessage({
        id: 'NFTCard.price',
        defaultMessage: 'Price',
      }),
      content: (
        <div className="flex flex-row flex-nowrap gap-2 items-center">
          <Price
            fromUnit={{ address: data.sell?.price.address, total: data.sell?.price.total }}
            showSymbol
          />

          <div className={style.fiatPrice}>
            <Delim vertical />

            <div className="text-[color:var(--text-secondary)]">
              $
              <Price
                fromUnit={{ address: data.sell?.price.address, total: data.sell?.price.total }}
                to="usdt"
                round={5}
              />
            </div>
          </div>

        </div>
      ),
    })
  }

  if (isNftOnAuction) {
    blocks.push({
      title: intl.formatMessage({
        id: 'NFTCard.time-left',
        defaultMessage: 'Time left',
      }),
      content: (
        <Timer
          size="s"
          targetTimeISO={data.auction?.endTime || ''}
          days hours minutes
          seconds
        />
      ),
    }, {
      title: intl.formatMessage({
        id: 'NFTCard.bids',
        defaultMessage: 'Bids',
      }),
      content: String(data.auction?.bidsCount),
    })
  }

  if (!isNftOnAuction && !isNftOnSale) {
    blocks.push({
      title: '',
      content: intl.formatMessage({
        id: 'NFTCard.not-for-sale',
        defaultMessage: 'Not for sale',
      }),
    })
  }

  function handleBuyNow(e: React.MouseEvent) {
    e.stopPropagation()
    e.preventDefault()

    flowsStore.startDirectBuyNftFlow(data)
  }


  return (
    <div className={cn(
      style.contr,
      className,
    )}
    >
      <CommonCard
        titleOrder="title"
        mediaClassName="!h-[290px]"
        title={data.name}
        isCollectionVerified={data.collection.isVerified}
        collectionName={data.collection.name}
        text={data.description}
        previewUrl={data.preview?.url || ''}
        blocks={blocks}
        className="h-full"
      />

      {canDirectBuy && (
        <div className={style.buyNow}>
          <Button
            wide onClick={e => {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'step_1',
                category: 'buy_nft',
                nft: data.name, // name of nft
                collection: data.collection.name, // name of collection
                path: `/collections/${data.collection.name}/nfts/${data.name}`, // path of nft
                id: profileStore.profileData.$value?.address.address, // UserId
                timestamp: new Date().getTime(),
              })
              handleBuyNow(e)
            }}
          >
            <div className="relative w-fit m-auto">
              <BoltSVG className="absolute -left-[25px]" />
              {intl.formatMessage({
                id: 'NFTCard.buy-now',
                defaultMessage: 'Buy now',
              })}
            </div>
          </Button>
        </div>
      )}

    </div>
  )
}
