import { generatePath, Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

import { ClassProp } from '@/util/react-props'
import { InteractionListItem } from '@/components/pages/NFTPage/components/Card/Content/InteractionListItem'
import { Nft } from '@/api/luckyswap'
import { Block } from '@/components/common/Block'
import { NotNil } from '@/util/nil'
import { Avatar } from '@/components/common/Avatar'
import { ellipsis } from '@/util/ellipsis'
import { ROUTES } from '@/routing/routes'
import { PARAMS } from '@/routing/params'
import { formatDateToDayAndTime } from '@/util/format'


type BidsListProps = {
  bids: NotNil<Nft['auction']>['bids']
} & ClassProp

export function BidsList({ bids } : BidsListProps) {
  const getProfileUrl = (userAddress: string) => generatePath(
    ROUTES.userMarketPage,
    { [PARAMS.userId]: userAddress },
  )
  const intl = useIntl()

  return (
    <Block className="grid grid-cols-1 gap-3 max-h-[454px] overflow-auto">
      <div id="bidsList">
        {
          bids.map(({ maker, price, created }) => (
            <InteractionListItem
              key={created.toString()}
              icon={<Avatar className="!min-w-[40px] !min-h-[40px] !max-w-[40px] !max-h-[40px]" />}
              title={(
                <>
                  { created === bids[0].created
                    ? intl.formatMessage(
                      {
                        id: 'BidsList.Highest-Bid-by',
                        defaultMessage: 'Highest{space}bid{space}by{space}',
                      },
                      {
                        space: <>&nbsp;</>,
                      },
                    )
                    : intl.formatMessage(
                      {
                        id: 'BidsList.Bid-by',
                        defaultMessage: 'Bid{space}by{space}',
                      },
                      {
                        space: <>&nbsp;</>,
                      },
                    )}
                  <Link to={getProfileUrl(maker)} className="text-[color:var(--active)]">
                    {ellipsis(maker, 5, 5)}
                  </Link>
                </>
              )}
              text={formatDateToDayAndTime(new Date(created))}
              price={{ address: price.address, total: price?.total || '0' }}
              data-created={created}
            />
          ))
        }
      </div>
    </Block>
  )
}
