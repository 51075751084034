import { useIntl } from 'react-intl'

import { Attribute } from '@/api/stubs/attribute'
import { Checkbox, CheckboxProps } from '@/components/common/Checkbox'
import { cn } from '@/util/exports'
import { STUB } from '@/util/stub'
import { Price } from '@/components/common/Price'

import style from './style.module.scss'


export type Props = {
  data: Attribute
  totalSupply?: number
  checkbox?: CheckboxProps
}

export function AttributesFormItem({
  data,
  totalSupply,
  checkbox,
}: Props) {
  const intl = useIntl()

  const trait = data.trait_object

  return (
    <label
      className={cn(
        'flex flex-row flex-nowrap gap-3 items-center',
        'p-3',
        'border-[1px] border-[color:var(--bg-4)] rounded-md',
        'cursor-pointer',
      )}
    >
      <Checkbox {...checkbox} />

      <div
        className={style.main}
      >
        <span className={cn(style.coll, 'items-left, text-left')}>
          <span
            className="text-ellipsis overflow-hidden whitespace-nowrap"
          >
            {trait.trait_value || STUB}

          </span>
          {data.floor && (
            <span className="font-normal text-[14px] text-[color:var(--text-secondary)]">
              <Price
                fromUnit={{ address: data.floor.address, total: data.floor.total || '0' }}
                showSymbol
              />
              {intl.formatMessage({
                id: 'AttributesFormItem.floor',
                defaultMessage: ' floor',
              })}
            </span>
          )}
        </span>

        <span className={cn(style.coll, 'items-right, text-right')}>
          <span className="justify-self-end">
            <span className="text-[16px]">
              {data.total}
            </span>
            <span
              className="font-normal text-[14px] text-[color:var(--text-secondary)]"
            >
              /
              {totalSupply}
            </span>
          </span>

          <span
            className="font-normal text-[14px] text-[color:var(--active)] justify-self-end"
          >
            {data.percent}
            %
          </span>
        </span>
      </div>

    </label>
  )
}
