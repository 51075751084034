import { Link } from 'react-router-dom'
import { isMobile } from 'web3modal'
import { useIntl } from 'react-intl'

import { cn } from '@/util/exports'
import { Button } from '@/components/common/Button'

import { BackgroundBlock } from '../BackgroundBlock'
import style from './style.module.scss'


export function PromoCreateCollectionBlock() {

  const intl = useIntl()

  const handleRedirect = () => {
    if (intl.locale === 'ru') {
      return window.open('https://docs.google.com/forms/d/e/1FAIpQLSccAtYOg2GJR0nxkfciqU4BTi76d9OHGiq0k6PT3B0XWyA8jw/viewform')
    }

    return window.open('https://docs.google.com/forms/d/e/1FAIpQLScOOCsZ0X88Ic4hyDwU-PmpoNJVqk7s2_Lcoim3u7uBemtrZA/viewform')
  }

  return (
    <BackgroundBlock className="flex items-center flex-col py-10 px-6 md:px-14 md:pt-14 md:pb-20 md:mb-32" linesLayer>
      <h1
        className={cn(
          'text-center mb-28 md:mb-16',
          'font-["JetBrains_Mono"] font-bold text-3xl md:text-5xl',
          'text-transparent bg-clip-text bg-gradient-to-bl',
          'from-[color:#B3F74C] via-[color:#B3F74C] to-[color:#00F0FF]',
        )}
      >
        {
          intl.formatMessage(
            {
              id: 'PromoCreateCollectionBlock.do-you-want-create-your-own',
              defaultMessage: 'FAKE Хотите создать свою',
            },
          )
        }
        {' '}
        <br />
        {' '}
        {
          intl.formatMessage(
            {
              id: 'PromoCreateCollectionBlock.nft-collection',
              defaultMessage: ' NFT-коллекцию?',
            },
          )
        }
      </h1>

      <div className="flex flex-nowrap flex-col md:flex-row items-center gap-24 md:gap-5 justify-center mb-16 relative">
        <div
          className={cn(
            'absolute w-[1px] border-l-[1px] border-dashed border-[color:var(--active)] h-full top-[-20px]',
            'md:w-2/3 md:h-[1px] md:top-[20px] md:border-t-[1px]',
          )}
        />
        <div className={style.item}>
          <i className={style.icon}>1</i>
          <span>
            {
              intl.formatMessage(
                {
                  id: 'PromoCreateCollectionBlock.message1',
                  defaultMessage: 'FAKE Разработаем уникальную коллекцию для индивидуального автора или бизнеса',
                },
              )
            }
          </span>
        </div>
        <div className={style.item}>
          <i className={style.icon}>2</i>
          <span>
            {
              intl.formatMessage(
                {
                  id: 'PromoCreateCollectionBlock.message2',
                  defaultMessage: 'FAKE Учтём нюансы при минте и{space}обеспечим техническую поддержку',
                },
                {
                  space: <>&nbsp;</>,
                },
              )
            }

          </span>
        </div>
        <div className={style.item}>
          <i className={style.icon}>3</i>
          <span>
            {
              intl.formatMessage(
                {
                  id: 'PromoCreateCollectionBlock.message3',
                  defaultMessage: 'FAKE Запустим готовую NFT-коллекцию за 3 дня',
                },
              )
            }
          </span>
        </div>
      </div>

      <Link to="/" style={{ width: isMobile() ? '100%' : 'inherit' }}>
        <Button
          onClick={handleRedirect}
          wide={isMobile()}
          className='font-["JetBrains_Mono"]'
        >
          {
            intl.formatMessage(
              {
                id: 'PromoCreateCollectionBlock.send-order',
                defaultMessage: 'FAKE Оставить заявку',
              },
            )
          }
        </Button>
      </Link>
    </BackgroundBlock>

  )
}
