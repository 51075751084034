/* eslint-disable react/function-component-definition */
import { PropsWithChildren } from 'react'

import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'

import { Dropdown, DropdownProps } from '../Dropdown'
import style from './index.module.scss'

type ItemProps = {
  styleless?: boolean
} & ClassProp

interface DropdownItem {
  (props: PropsWithChildren<ItemProps>): JSX.Element
  className: string
}

type Props = {
  listClassName?: string
}
& DropdownProps
export interface DropdownList {
  (props: PropsWithChildren<Props>): JSX.Element
  Item: DropdownItem
}

export const DropdownList: DropdownList = ({
  listClassName,
  children,
  ...props
}) => (
  <Dropdown
    action="click"
    className="border-none"
    contentClassName="z-[2]"
    {...props}
  >
    <ul className={cn(style.box, listClassName)}>
      {children}
    </ul>
  </Dropdown>
)

// -- TODO replace into file
// eslint-disable-next-line func-names, react/no-multi-comp 
const Item: DropdownItem = ({
  children,
  styleless = false,
  className,
}) => (
  <li
    className={cn(
      style.item,
      !styleless && style.itemExtraStyle,
      className,
    )}
  >
    {children}
  </li>
)
Item.className = style.itemExtraStyle

DropdownList.Item = Item
