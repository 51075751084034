import { PropsWithChildren } from 'react'
import cn from 'classnames'

import { ClassProp } from '@/util/react-props'

import style from './style.module.scss'
import GridBG from './grid_background.svg'
import LinesBG from './lines_layer.svg'

type BackgroundBlockProps = { linesLayer?: boolean } & ClassProp

export function BackgroundBlock({ linesLayer, children, className }: PropsWithChildren<BackgroundBlockProps>) {
  const backgroundComposition = linesLayer
    ? `url("${GridBG}"), url("${LinesBG}")`
    : `url("${GridBG}")`

  return (
    <section
      className={cn(style.container, 'md:py-20 md:px-16', className)}
      style={{ backgroundImage: backgroundComposition }}
    >
      <div className={style.glow} />
      {linesLayer && <div className={style.lines} />}
      {children}
    </section>
  )
}
