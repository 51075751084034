/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EventStatus } from '../models/EventStatus';

import type { CancelablePromise } from '@/api/util/CancelablePromise'
import { OpenAPI } from '@/api/util/OpenAPI'
import { request as __request } from '@/api/util/request'

export class EventService {

    /**
     * @param txHash
     * @returns EventStatus OK
     * @throws ApiError
     */
    public static getEventStatus(
        txHash: string,
    ): CancelablePromise<EventStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/event/status',
            query: {
                'txHash': txHash,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }

}
