import { arrayToMap } from '@/util/array-to-map'

export const MARKETPLACE_TABS = arrayToMap([
  'nfts',
  'collections',
  'users',
] as const)

export const PROFILE_TABS = arrayToMap([
  'myItems',
  'bidsMade',
  'bidsReceive',
  'offerMade',
  'offerReceive',
] as const)
