/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type LoginOrRegisterPayload = {
    address: {
        address: string;
        blockchain: LoginOrRegisterPayload.blockchain;
    };
    message: string;
    pubKey: string;
    signature: string;
};

export namespace LoginOrRegisterPayload {

    export enum blockchain {
        ETHEREUM = 'ethereum',
        SEPOLIA = 'sepolia',
        GOERLI = 'goerli',
    }


}

