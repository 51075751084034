export const SOCIAL_LINKS = {
  discord:  'https://discord.com/invite/luckyswap',
  telegram_channel:  'https://t.me/luckyswapcom',
  telegram_chat:  'https://t.me/luckyswapcom_chat',
  twitter:  'https://twitter.com/Luckyswap_com',
  medium: 'https://medium.com/@luckyswapcom',
}

export const INSTALL_WALLET_LINKS = {
  metamask: 'https://metamask.io/download/',
}
