import { observer } from 'mobx-react-lite'

import { useStores } from '@/hooks/useStores'
import { DirectBuyNftFlow } from '@/components/modules/flows/DirectBuyNftFlow'
import { PlaceBidFlow } from '@/components/modules/flows/PlaceBidFlow'
import { MakeOfferFlow } from '@/components/modules/flows/MakeOfferFlow'
import { AcceptOfferFlow } from '@/components/modules/flows/AcceptOfferFlow'
import { DeleteOfferFlow } from '@/components/modules/flows/DeleteOfferFlow'

export const Flows = observer(
  () => {
    const { flowsStore } = useStores()

    return (
      <>
        {flowsStore.$directBuyNftFlow && <DirectBuyNftFlow />}

        {flowsStore.$placeBidFlow && <PlaceBidFlow />}

        {flowsStore.$makeOfferFlow && <MakeOfferFlow />}

        {flowsStore.$acceptOfferFlow && <AcceptOfferFlow />}

        {flowsStore.$deleteOfferFlow && <DeleteOfferFlow />}
      </>
    )
  },
)
