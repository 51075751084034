import {
  useEffect, useMemo, useRef, useState,
} from 'react'
import { useDebounce } from 'use-debounce'
import { StringParam, useQueryParams } from 'use-query-params'
import { useIntl } from 'react-intl'

import { Block } from '@/components/common/Block'
import { Button } from '@/components/common/Button'
import { Input } from '@/components/common/Input'
import { ReactComponent as ReloadSVG } from '@/assets/reload.svg'
import { Select } from '@/components/common/Select/Select'
import { cn } from '@/util/exports'
import { ReactComponent as TuneSVG } from '@/assets/tune.svg'
import { ReactComponent as SortSVG } from '@/assets/sort.svg'
import { useResizeObserver } from '@/hooks/useResizeObzerver'
import { QUERIES } from '@/queries'
import { useToggle } from '@/hooks/useToggle'
import { Modal } from '@/components/common/Modal'
import { CollectionFilter, NftFilter } from '@/api/luckyswap'
import { arrayToMap } from '@/util/array-to-map'

import style from './style.module.scss'
import { FilterParts, NFTsFilter } from '../NFTsFilter'

export type SearchSort = keyof typeof SearchSort
export const SearchSort = arrayToMap([
  `price_${NftFilter.price.ASC}`,
  `price_${NftFilter.price.DESC}`,
  `published_${NftFilter.published.ASC}`,
  `published_${NftFilter.published.DESC}`,
  `createdAt_${CollectionFilter.createdAt.ASC}`,
  `createdAt_${CollectionFilter.createdAt.DESC}`,
] as const)

const SEARCH_DEBOUNCE_MS = 400

export type Props = {
  filterParts: FilterParts

  onReloadClick?: VoidFunction

  sort: SearchSort,
  onSortChange?: (sort: SearchSort) => void

  excludeSort?: SearchSort[]
}

export function SearchBar({
  filterParts,
  onReloadClick,
  sort,
  onSortChange,
  excludeSort = [],
}:Props) {
  const intl = useIntl()

  const SELECT_ITEMS = [
    {
      id: SearchSort.price_asc,
      content: intl.formatMessage({
        id: 'SearchBar.Price-to-high',
        defaultMessage: 'Price: low to high',
      }),
    },
    {
      id: SearchSort.price_desc,
      content: intl.formatMessage({
        id: 'SearchBar.Price-to-low',
        defaultMessage: 'Price: high to low',
      }),
    },

    {
      id: SearchSort.published_asc,
      content: intl.formatMessage({
        id: 'SearchBar.Published-to-new',
        defaultMessage: 'Published: old to new',
      }),
    },
    {
      id: SearchSort.published_desc,
      content: intl.formatMessage({
        id: 'SearchBar.Published-to-old',
        defaultMessage: 'Published: new to old',
      }),
    },

    {
      id: SearchSort.createdAt_asc,
      content: intl.formatMessage({
        id: 'SearchBar.Created-to-new',
        defaultMessage: 'Created: old to new',
      }),
    },
    {
      id: SearchSort.createdAt_desc,
      content: intl.formatMessage({
        id: 'SearchBar.Created-to-old',
        defaultMessage: 'Created: new to old',
      }),
    },
  ]

  const ref = useRef<HTMLDivElement>(null)

  const contrEntry = useResizeObserver(ref.current)

  const [query, setQuery] = useQueryParams(
    { [QUERIES.search]: StringParam },
    { updateType: 'replaceIn' },
  )
  const [search, setSearch] = useState(() => query.search || '')
  const [debouncedSearch] = useDebounce(search, SEARCH_DEBOUNCE_MS)

  const items = useMemo(
    () => SELECT_ITEMS.filter(
      item => !excludeSort.includes(item.id),
    ),
    [excludeSort],
  )

  useEffect(
    () => setQuery({ search: debouncedSearch }),
    [debouncedSearch, setQuery],
  )

  useEffect(
    () => setSearch(query.search || ''),
    [query.search],
  )

  const [isFilterVisible, togFiltersVisible] = useToggle(false)

  const isSmall = (contrEntry?.contentRect.width || 0) < 550

  return (
    <div ref={ref}>
      <div className={cn(style.box)}>
        <Input
          wide
          placeholder={intl.formatMessage({
            id: 'SearchBar.Name-or-description',
            defaultMessage: 'Name or description',
          })}
          showSearchIcon
          containerClassName="w-full"
          value={search}
          onChangeText={setSearch}
        />

        <div className="flex flex-row flex-nowrap gap-3">
          <div className="contents" onClick={togFiltersVisible}>
            <Block size="s" className={style.filtersBtn}>
              <TuneSVG className="inline" />
              {intl.formatMessage({
                id: 'SearchBar.Filters',
                defaultMessage: 'Filters',
              })}
            </Block>
          </div>

          {isFilterVisible && (
            <Modal>
              <div className="flex justify-center items-center">
                <div className={style.filtersModal}>
                  <NFTsFilter
                    parts={filterParts}
                    onCloseClick={togFiltersVisible}
                  />
                </div>
              </div>
            </Modal>
          )}

          <Button variant="flat" className="!p-0 !w-10 shrink-0" onClick={onReloadClick}>
            <Block size="xs" className="h-full flex justify-center items-center">
              <ReloadSVG />
            </Block>
          </Button>

          <Select
            title={isSmall ? <SortSVG /> : null}
            className={isSmall ? 'w-max' : undefined}
            items={items}
            selectId={sort}
            onSelect={id => onSortChange?.(id as SearchSort)}
          />
        </div>
      </div>
    </div>
  )
}
