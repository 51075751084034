/* eslint-disable consistent-return */
import {
  PropsWithChildren, useEffect, useRef, useState,
} from 'react'
import { Config, usePopperTooltip } from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'

type Props = {
  config?: Config
  content: JSX.Element | string
  ttl?: number
}

export function Tooltip({
  config,
  content,
  children,
  ttl,
}: PropsWithChildren<Props>) {
  const [isVisible, setVisible] = useState(false)
  const ref = useRef<HTMLDivElement>(null)

  const {
    getTooltipProps,
    setTriggerRef,
    setTooltipRef,
  } = usePopperTooltip({
    trigger: 'click',
    placement: 'top',
    visible: isVisible,
    onVisibleChange: setVisible,
    closeOnOutsideClick: false,
    ...config,
  })


  useEffect(() => {
    const box = ref.current
    if (!box) return

    setTriggerRef(box.children.item(0) as HTMLElement)
  }, [ref.current])

  useEffect(() => {
    if (ttl === undefined) return
    if (!isVisible) return

    const timer = setTimeout(() => {
      setVisible(false)
    }, ttl)

    return () => clearTimeout(timer)
  }, [ttl, isVisible])

  return (
    <>
      <div className="contents relative" ref={ref}>
        {children}
      </div>

      {isVisible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: '' })}
        >
          {content}
        </div>
      )}
    </>
  )
}
