export function isEmail(email?: string) {
  if (!email) return false

  const dog = email.search(/[@]/)
  const dot = email.search(/[.]/)
  const letters = email.search(/[a-z]/)
  const lettersCapital = email.search(/[A-Z]/)

  if (dog > 0 && dot > 0 && (letters >= 0 || lettersCapital >= 0)) {

    return (letters < dog || lettersCapital < dog) && dog < dot && dot !== email.length - 1
  }

  return false
}
