import { cn } from '@/util/exports'

import { MODAL_CONTAINER_ID } from './util'

export function ModalContainer() {
  return (
    <div
      id={MODAL_CONTAINER_ID}
      className={cn(
        'fixed inset-0 pointer-events-none select-none z-10',
      )}
    />
  )
}
