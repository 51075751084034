import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'

import { Button } from '@/components/common/Button'
import { useStores } from '@/hooks/useStores'
import { ReactComponent as BoltSVG } from '@/assets/bolt.svg'
import { LoadingBox } from '@/components/common/LoadingBox'
import { getNFTPermissions } from '@/util/nft-permissions'
import { NftWeb3Service } from '@/api/web3/services/NftWeb3Service'
import { delay } from '@/util/promise'
import { SellNFTFlow } from '@/components/modules/flows/SellNFTFlow'
import { useToggle } from '@/hooks/useToggle'
import { useEvmWallet } from '@/stores/EvmWalletService'
import { useCurrentNetwork } from '@/hooks/useCurrentNetwork'
import { PARAMS } from '@/routing/params'
import { ROUTES } from '@/routing/routes'

// Refac this shit completely

export const Buttons = observer(
  () => {
    const intl = useIntl()
    const { isConnected, network, address } = useEvmWallet()

    const {
      nftPageStore,
      flowsStore,
      profileStore,
    } = useStores()

    const [isLoading, setIsLoading] = useState(false)

    const [
      isSellFlowOpened,
      toggleSellFlowOpened,
      setSellFlowOpened,
    ] = useToggle(false)

    const {
      $currentNft,
      $nftId,
      $nftCollectionAddress,
      nftData: { load: loadNft, $lastParams },
    } = nftPageStore

    const nft = nftPageStore.nftData.$value
    const { isCurrentNetwork } = useCurrentNetwork(nft?.address.blockchain, network)

    const { $isTimeUp } = nftPageStore

    const {
      canCancelAuction,
      canPlaceBid,
      canPutOnAuction,

      canDirectSell,
      canDirectBuy,
      canCancelDirectSell,

      canMakeOffer,
    } = getNFTPermissions(nft)

    async function handleSellFlowClose() {
      setSellFlowOpened(false)

      await delay(3000)
      loadNft({ params: $lastParams })
    }

    function handleDirectBuy() {
      if (!nft) return
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'step_1',
        category: 'buy_nft',
        nft: nft.name, // name of nft
        collection: nft.collection.name, // name of collection
        path: `/collections/${nft.collection.name}/nfts/${nft.name}`, // path of nft
        id: profileStore.profileData.$value?.address.address, // UserId
        timestamp: new Date().getTime(),
      })

      flowsStore.startDirectBuyNftFlow(nft)
    }

    const handleCancelSell = async () => {
      if (!$currentNft || !$nftCollectionAddress || !$nftId) return

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'remove_from_sale_1',
        category: 'sale',
        nft: nft?.name, // name of nft
        collection: nft?.collection.name, // name of collection
        path: generatePath(ROUTES.nftPage, {
          [PARAMS.collectionId]: nft?.collection.slug,
          [PARAMS.nftId]: nft?.id,
        }), // path of nft
        id: address, // UserId
        timestamp: new Date().getTime(),
      })

      setIsLoading(true)

      await NftWeb3Service.cancelDirectSaleItem($nftCollectionAddress, $nftId, nft)

      setIsLoading(false)

      await delay(4000)
      loadNft({ params: $lastParams })
    }

    async function handleCancelAuction() {
      if (!$nftCollectionAddress || !$nftId) return

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'end_auction_1',
        category: 'sale',
        nft: nft?.name, // name of nft
        collection: nft?.collection.name, // name of collection
        path: generatePath(ROUTES.nftPage, {
          [PARAMS.collectionId]: nft?.collection.slug,
          [PARAMS.nftId]: nft?.id,
        }), // path of nft
        id: address, // UserId
        timestamp: new Date().getTime(),
      })

      setIsLoading(true)

      const result = await NftWeb3Service.endAuction($nftCollectionAddress, $nftId)

      setIsLoading(false)

      if (!result) return

      await delay(4000)
      loadNft({ params: $lastParams })

      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'end_auction_2',
        category: 'sale',
        nft: nft?.name, // name of nft
        collection: nft?.collection.name, // name of collection
        path: generatePath(ROUTES.nftPage, {
          [PARAMS.collectionId]: nft?.collection.slug,
          [PARAMS.nftId]: nft?.id,
        }), // path of nft
        id: address, // UserId
        owner: nft?.owner.address.address, // new owner id
        timestamp: new Date().getTime(),
      })
    }

    if (!isConnected) return null

    return (
      <div className="flex flex-col flex-nowrap gap-2">
        {/* PRIMARY BUTTONS */}

        {canPlaceBid && (
          <Button
            wide
            disabled={!nft}
            onClick={() => {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'step_1',
                category: 'auction_bid',
                nft: nft?.name, // name of nft
                collection: nft?.collection.name, // name of collection
                path: generatePath(ROUTES.nftPage, {
                  [PARAMS.collectionId]: nft?.collection.slug,
                  [PARAMS.nftId]: nft?.id,
                }), // path of nft
                id: address, // UserId
                timestamp: new Date().getTime(),
              })
              flowsStore.startPlaceBidFlow()
            }}
            isWalletRequired
            nftBlockchains={nft?.address.blockchain}
          >
            {intl.formatMessage({
              id: 'Buttons.Place-bid',
              defaultMessage: 'Place a bid',
            })}
          </Button>
        )}

        {canDirectBuy && (
          <Button
            wide
            disabled={!nft}
            onClick={handleDirectBuy}
            isWalletRequired
            nftBlockchains={nft?.address.blockchain}
          >
            <BoltSVG className="inline" />
            {intl.formatMessage({
              id: 'Buttons.Buy-Now',
              defaultMessage: 'Buy Now',
            })}
          </Button>
        )}

        {(canDirectSell && canPutOnAuction) && (
          <Button
            wide
            onClick={() => {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'sale_nft',
                category: 'sale',
                nft: nft?.name, // name of nft
                collection: nft?.collection.name, // name of collection
                path: generatePath(ROUTES.nftPage, {
                  [PARAMS.collectionId]: nft?.collection.slug,
                  [PARAMS.nftId]: nft?.id,
                }), // path of nft
                id: address, // UserId
                timestamp: new Date().getTime(),
              })
              toggleSellFlowOpened()
            }}
            isWalletRequired
            nftBlockchains={nft?.address.blockchain}
          >
            {intl.formatMessage({
              id: 'Buttons.Put-on-sale',
              defaultMessage: 'Put on sale',
            })}
          </Button>
        )}

        {/* VARIADIC BUTTONS */}

        {canCancelAuction && (
          <Button
            wide
            onClick={handleCancelAuction}
            disabled={isLoading}
            variant={$isTimeUp ? 'regular' : 'secondary'}
            isWalletRequired
            nftBlockchains={nft?.address.blockchain}
          >
            <LoadingBox loading={isLoading}>
              {intl.formatMessage({
                id: 'Buttons.End-auction',
                defaultMessage: 'End auction',
              })}
            </LoadingBox>
          </Button>
        )}

        {/* SECONDARY BUTTONS */}

        {canMakeOffer && isCurrentNetwork && (
          <Button
            wide
            onClick={() => {
              window.dataLayer = window.dataLayer || []
              window.dataLayer.push({
                event: 'step_1',
                category: 'make_offer_nft',
                nft: nft?.name, // name of nft
                collection: nft?.collection.name, // name of collection
                path: generatePath(ROUTES.nftPage, {
                  [PARAMS.collectionId]: nft?.collection.slug,
                  [PARAMS.nftId]: nft?.id,
                }), // path of nft
                id: address, // UserId
                timestamp: new Date().getTime(),
              })
              flowsStore.startMakeOfferFlow()
            }}
            variant={!canDirectBuy ? 'regular' : 'secondary'}
          >
            {intl.formatMessage({
              id: 'Buttons.Make-offer',
              defaultMessage: 'Make offer',
            })}
          </Button>
        )}

        {canCancelDirectSell && isCurrentNetwork && (
          <Button
            wide
            onClick={handleCancelSell}
            disabled={isLoading}
            variant="secondary"
          >
            <LoadingBox loading={isLoading}>
              {intl.formatMessage({
                id: 'Buttons.Remove',
                defaultMessage: 'Remove From Sale',
              })}
            </LoadingBox>
          </Button>
        )}

        {/* TODO Move this flow to Flows.tsx later */}
        {$currentNft && isSellFlowOpened && (
          <SellNFTFlow onClose={handleSellFlowClose} />
        )}
      </div>
    )
  },
)
