/* eslint-disable react/jsx-no-useless-fragment */
import { PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

import { MODAL_CONTAINER_ID } from './util'
import style from './style.module.scss'

type Props = {}

export function Modal({
  children,
}: PropsWithChildren<Props>) {

  const contr = document.querySelector(`#${MODAL_CONTAINER_ID}`)

  if (!contr) return null

  return createPortal(
    <div className={style.modalWrapper}>
      {children}
    </div>,
    contr,
  )
}
