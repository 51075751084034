import { cn } from '@/util/exports'
import { ClassProp } from '@/util/react-props'

import style from './style.module.scss'

type Props = {
  vertical?: boolean

  variant?: 'primary' | 'secondary'
} & ClassProp

export function Delim({
  className,
  vertical = false,
  variant = 'primary',
}: Props) {

  // TODO move this colors to scheme
  const color = variant === 'secondary' ? 'bg-[#2c3f51]' : 'bg-[#8694AA]'

  if (vertical) {
    return (
      <span className="inline-block mx-1">
        <div className={cn(
          style.line,
          color,
          'h-auto w-[1px]',
          'list-item',
          'scale-y-75',
          className,
        )}
        />
      </span>
    )
  }

  return (
    <div className={cn(
      style.line,
      color,
      'w-full h-[1px]',
      className,
    )}
    />
  )
}
