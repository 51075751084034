import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

export function RouteScrollTop() {
  const [prevPath, setPrevPath] = useState('')

  const loc = useLocation()

  useEffect(() => {
    const isPathChanged = loc.pathname !== prevPath

    if (!isPathChanged) return

    setPrevPath(loc.pathname)

    document.documentElement.scrollTo({
      top: 0,
      behavior: 'auto',
    })
  }, [loc, prevPath])

  return null
}
