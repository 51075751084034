import {
  PropsWithChildren, useLayoutEffect, useState,
} from 'react'

import { ClassProp } from '@/util/react-props'
import { useResizeObserver } from '@/hooks/useResizeObzerver'
import { nil } from '@/util/nil'

type Props = {
  mode: 'w-by-h' | 'h-by-w',
  ratio?: number
}
& ClassProp

export function RatioBox({
  mode,
  ratio = 1,
  className,
  children,
}: PropsWithChildren<Props>) {
  const [ref, setRef] = useState<HTMLDivElement|nil>(null)

  const entry = useResizeObserver(ref)

  useLayoutEffect(() => {
    if (!entry) return
    if (!ref) return

    if (mode === 'h-by-w') {
      ref.style.height = `${entry.contentRect.width * ratio}px`
    }
    else {
      ref.style.width = `${entry.contentRect.height * ratio}px`
    }
  }, [entry, mode, ref, ratio])

  return (
    <div ref={setRef} className={className}>
      {children}
    </div>
  )
}
