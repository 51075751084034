import { useMemo, useRef } from 'react'
import {
  Link, matchPath, useLocation,
} from 'react-router-dom'
import { useIntl } from 'react-intl'

import { Burger } from '@/components/common/Burger'
import { useToggle } from '@/hooks/useToggle'
import { cn } from '@/util/exports'
import { DropdownList } from '@/components/common/DropdownList'
import { OVERVIEW_LINKS } from '@/util/overview-links'
import { ROUTES } from '@/routing/routes'

import style from './style.module.scss'

type exploreProps = {
  id?: string
}

export function Explore({ id }: exploreProps) {
  const intl = useIntl()

  const loc = useLocation()
  const exploreRef = useRef<HTMLDivElement>(null)

  const [opened, toggleOpened, setOpened] = useToggle(false, exploreRef)

  const links = useMemo(
    () => Object.entries(OVERVIEW_LINKS).map(([, value]) => value),
    [loc.pathname],
  )

  const sendReport = (linkPath: string) => {
    if (linkPath === `${ROUTES.marketplacePage}?saleType=onAuction`) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'auctions',
        category: 'page_view',
        id, // UserId
        timestamp: new Date().getTime(),
      })
    }
    if (linkPath === ROUTES.marketplacePage) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'marketplace',
        category: 'page_view',
        id, // UserId
        timestamp: new Date().getTime(),
      })
    }
  }

  return (
    <DropdownList
      isDropdownRow={false}
      action="click"
      opened={opened}
      onChange={setOpened}
      className="!bg-transparent"
      listClassName="!rounded-none"
      contentClassName={cn(
        'bg-[color:var(--bg-1)] rounded-md',
        'p-[10px]',
        'max-w-[320px]',
      )}
      placement="bottom-start"
      title={(
        <div
          ref={exploreRef}
          className={cn(
            'select-none cursor-pointer',
            'flex items-center',
            '!text-[color:var(--text-primary)]',
          )}
        >
          <Burger toggled={opened} className="!pl-0" />
          <span className={cn(style.explore, 'font-["JetBrains_Mono"] text-[16px]')}>
            { opened ? intl.formatMessage({
              id: 'Explore.Menu',
              defaultMessage: 'Menu',
            }) : intl.formatMessage({
              id: 'Explore.Explore',
              defaultMessage: 'Explore',
            })}
          </span>
        </div>
      )}
    >
      {links.map(link => {

        const isActive = !!matchPath(loc.pathname, link.path)

        return (
          <Link
            key={link.path}
            to={link.path}
            onClick={() => {
              sendReport(link.path)
              toggleOpened()
            }}
          >
            <DropdownList.Item
              styleless
              className={cn(
                style.item,
                isActive && style.active,
              )}
            >
              <link.Icon className={style.icon} />

              <span className={style.title}>{intl.formatMessage(link.title)}</span>

              <div className={style.text}>{intl.formatMessage(link.text)}</div>
            </DropdownList.Item>
          </Link>
        )
      })}
    </DropdownList>
  )
}
