import { Block } from '@/components/common/Block'

export function CollectionInfo({
  collectionInfo,
}: { collectionInfo: any[] }) {
  return (
    <Block
      size="b"
      className="w-inh flex flex-row justify-between mb-10 py-4 px-5 -mt-7"
    >
      {collectionInfo.map(({ title, text }, idx) => (
        <>
          <div className="flex flex-col gap-y-2 max-w-fit">
            <span className="text-[16px] font-normal text-[color:var(--text-secondary)]">
              {title}
            </span>
            <span className='text-[18px] font-normal font-["JetBrains_Mono"]'>
              {text}
            </span>
          </div>
          {idx + 1 !== collectionInfo.length && (
            <div className="w-px bg-[color:var(--text-secondary)]" />
          )}
        </>
      ))}
    </Block>
  )
}
