import { arrayToMap } from '@/util/array-to-map'

export const PARAMS = arrayToMap([
  'collectionId',
  'nftId',
  'userId',
] as const)

export type PathParams = {
  [k in keyof typeof PARAMS]: string
}
